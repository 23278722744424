import _ from 'lodash'
import React from 'react'
import { queryFilters } from '../../Queries/queryFilters'
import CostCentreCollection from '../../State/Collections/CostCentreCollection'
import StaffCollection from '../../State/Collections/StaffCollection'
import ContactCollection from '../../State/Collections/ContactCollection'
import {
    canViewFlexiRemote,
    canViewPrimaryContact,
    canViewProjectExpenseBudgets,
    canViewProjectFees,
    canViewRevenueTargets,
    canViewStaffChargeOutRate,
    canViewStaffCostRate,
    canViewStaffPayRate,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

export const TimeReportColumns = (report) => ({
    project: {
        id: 'project',
        label: 'Project',
        type: 'project',
        width: 20,
        value: (row) => row.project,
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('projectId', value)
        },
        queries: (report) => [
            { collection: 'timeEntries', fields: ['projectId'] },
        ],
    },
    projectPhase: {
        id: 'projectPhase',
        label: 'Phase',
        type: 'phase',
        width: 20,
        value: (row) => {
            return row.phase
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('phaseName', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['phaseId', ['phaseName', 'phase.name']],
                chain: [
                    {
                        collection: 'phases',
                        join: { phases: 'id', timeEntries: 'phaseId' },
                        fields: ['jobNumber', 'name'],
                    },
                ],
            },
        ],
    },
    staffMember: {
        id: 'staffMember',
        label: 'Staff',
        type: 'staffMember',
        width: 20,
        value: (row) => {
            return row.staff
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('staffId', value)
        },
        queries: (report) => [
            { collection: 'timeEntries', fields: ['staffId'] },
        ],
    },
    task: {
        id: 'task',
        label: 'Task',
        type: 'task',
        width: 20,
        value: (row) => {
            return row.task
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('taskName', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['taskId', ['taskName', 'task.name']],
                chain: [
                    {
                        collection: 'tasks',
                        join: { tasks: 'id', timeEntries: 'taskId' },
                        fields: ['name'],
                    },
                ],
            },
        ],
    },
    date: {
        id: 'date',
        label: 'Date',
        type: 'date',
        width: 10,
        value: (row) => {
            return row.date
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.date[operator]('date', value)
        },
        queries: (report) => [{ collection: 'timeEntries', fields: ['date'] }],
    },
    numMinutes: {
        id: 'numMinutes',
        label: 'Hours',
        type: 'number',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.hours
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('numMinutes', value)
        },
        queries: (report) => [
            { collection: 'timeEntries', fields: ['numMinutes'] },
        ],
    },
    notes: {
        id: 'notes',
        label: 'Notes',
        type: 'text',
        width: 30,
        value: (row) => {
            return row.notes
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('notes', value)
        },
        queries: (report) => [{ collection: 'timeEntries', fields: ['notes'] }],
    },
    costCentre: {
        id: 'costCentre',
        label: 'Cost Centre',
        type: 'costCentre',
        width: 15,
        value: (row) => {
            return CostCentreCollection.costCentresById[row.projectCostCentreId]
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('projectCostCentreId', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['projectCostCentreId', 'project.costCentreId']],
            },
        ],
    },
    staffCostCentre: {
        id: 'staffCostCentre',
        label: 'Staff Cost Centre',
        type: 'costCentre',
        width: 15,
        value: (row) => {
            return CostCentreCollection.costCentresById[row.staffCostCentreId]
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('staffCostCentreId', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['staffCostCentreId', 'project.costCentreId']],
            },
        ],
    },
    projectOwner: {
        id: 'projectOwner',
        label: 'Project Owner',
        type: 'staff',
        width: 20,
        value: (row) => {
            return StaffCollection.staffById[row.projectOwnerId]
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('projectOwnerId', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['projectOwnerId', 'project.ownerId']],
            },
        ],
    },
    projectContact: {
        id: 'projectContact',
        label: 'Project Client',
        type: 'contact',
        width: 20,
        value: (row) => {
            return ContactCollection.contactsById[row.projectContactId]
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('projectContactId', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['projectContactId', 'project.contactId']],
            },
        ],
    },
    projectInvoiceContact: {
        id: 'projectInvoiceContact',
        label: 'Project Primary Contact',
        type: 'contact',
        width: 20,
        value: (row) => {
            return ContactCollection.contactsById[row.projectPrimaryContactId]
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator](
                'projectPrimaryContactId',
                value
            )
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [
                    ['projectPrimaryContactId', 'project.primaryContactId'],
                ],
            },
        ],
        permissions: (row) => canViewPrimaryContact(),
    },
    projectCode: {
        id: 'projectCode',
        label: 'Project Code',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.projectCode
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('projectCode', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['projectCode', 'project.jobNumber']],
            },
        ],
    },
    projectPhaseCode: {
        id: 'projectPhaseCode',
        label: 'Phase Code',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.projectPhaseCode
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('projectPhaseCode', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['projectPhaseCode', 'phase.jobNumber']],
            },
        ],
    },
    staffMemberFirstName: {
        id: 'staffMemberFirstName',
        label: 'Staff First Name',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.staffMemberFirstName
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('staffMemberFirstName', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['staffMemberFirstName', 'staff.firstName']],
            },
        ],
    },
    staffMemberLastName: {
        id: 'staffMemberLastName',
        label: 'Staff Last Name',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.staffMemberLastName
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('staffMemberLastName', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['staffMemberLastName', 'staff.lastName']],
            },
        ],
    },
    staffRole: {
        id: 'staffRole',
        label: 'Staff Role',
        type: 'role',
        width: 20,
        value: (row) => {
            return row.staff?.role
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('staffRoleId', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['staffRoleId', 'staff.roleId']],
            },
        ],
    },
    monthIndex: {
        id: 'monthIndex',
        label: 'Month',
        type: 'text',
        width: 20,
        value: (row) => {
            return row.month
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('month', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['month'],
            },
        ],
    },
    isBillable: {
        id: 'isBillable',
        label: 'Billable',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.isBillable
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('isBillable', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['isBillable'],
            },
        ],
    },
    isVariation: {
        id: 'isVariation',
        label: 'Variation',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.isVariation
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('isVariation', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['isVariation'],
            },
        ],
    },
    isOvertime: {
        id: 'isOvertime',
        label: 'Overtime',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.isOvertime
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('isOvertime', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['isOvertime'],
            },
        ],
    },
    isLocked: {
        id: 'isLocked',
        label: 'Locked',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.isLocked
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('isLocked', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['isLocked'],
            },
        ],
    },
    beenInvoiced: {
        id: 'beenInvoiced',
        label: 'Invoiced',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.beenInvoiced
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('beenInvoiced', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['beenInvoiced'],
            },
        ],
    },
    flexi: {
        id: 'flexi',
        label: 'Flexi',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.flexi
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('flexi', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['flexi'],
            },
        ],
        permissions: (row) => canViewFlexiRemote(),
    },
    remote: {
        id: 'remote',
        label: 'Remote',
        type: 'boolean',
        width: 20,
        value: (row) => {
            return row.remote
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('remote', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['remote'],
            },
        ],
        permissions: (row) => canViewFlexiRemote(),
    },
    fee: {
        id: 'fee',
        label: 'Fee',
        type: 'currency',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.phase?.fee || row.project?.fee
        },
        permissions: (r) => canViewProjectFees(SessionStore.user, r?.project),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('projectFee', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['projectFee', 'projectPhases.fee']],
                subQueries: [
                    {
                        label: 'projectPhases',
                        collection: 'phases',
                        join: 'projectId == projectPhases.projectId',
                        groupBy: ['projectId'],
                        fields: [['fee', 'sum(fee)']],
                    },
                ],
                chain: [
                    {
                        collection: 'phases',
                        join: { phases: 'projectId', timeEntries: 'projectId' },
                        fields: ['fee', 'projectId'],
                    },
                ],
            },
        ],
    },
    remainingFee: {
        id: 'remainingFee',
        label: 'Remaining Fee',
        type: 'currency',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.phase?.fee
                ? row.phase?.fee - row.phase?.revenue
                : row.project?.fee -
                      _.sum(row.project?.phases.map((phase) => phase.revenue))
        },
        permissions: (r) =>
            canViewProjectFees(SessionStore.user, r?.project) &&
            canViewRevenueTargets(SessionStore.user, r?.project),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('projectRemainingFee', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [
                    [
                        'projectRemainingFee',
                        'projectPhases.fee - invoiceLineItems.revenue',
                    ],
                ],
                subQueries: [
                    {
                        label: 'projectPhases',
                        collection: 'phases',
                        join: 'projectId == projectPhases.projectId',
                        groupBy: ['projectId'],
                        fields: [['fee', 'sum(fee)']],
                    },
                    {
                        collection: 'invoiceLineItems',
                        join: {
                            timeEntries: 'projectId',
                            invoiceLineItems: 'projectId',
                        },
                        fields: [
                            [
                                'revenue',
                                'sum(billingType == "agreedFee" ? amount : 0)',
                            ],
                        ],
                    },
                ],
                chain: [
                    {
                        collection: 'phases',
                        join: { phases: 'projectId', timeEntries: 'projectId' },
                        fields: [
                            'fee',
                            'projectId',
                            ['revenue', 'invoiceLineItems.revenue'],
                        ],
                        subQueries: [
                            {
                                collection: 'invoiceLineItems',
                                join: 'id == invoiceLineItems.phaseId',
                                groupBy: ['phaseId'],
                                fields: [
                                    [
                                        'revenue',
                                        'sum(billingType == "agreedFee" ? amount : 0)',
                                    ],
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    budget: {
        id: 'budget',
        label: 'Expense Budget',
        type: 'currency',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.phase?.expenseBudget || row.project?.expenseBudget
        },
        permissions: (r) =>
            canViewProjectExpenseBudgets(SessionStore.user, r?.project),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('projectExpenseBudget', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [
                    ['projectExpenseBudget', 'projectPhases.expenseBudget'],
                ],
                subQueries: [
                    {
                        label: 'projectPhases',
                        collection: 'phases',
                        join: 'projectId == projectPhases.projectId',
                        groupBy: ['projectId'],
                        fields: [['expenseBudget', 'sum(expenseBudget)']],
                    },
                ],
                chain: [
                    {
                        collection: 'phases',
                        join: { phases: 'projectId', timeEntries: 'projectId' },
                        fields: ['expenseBudget', 'projectId'],
                    },
                ],
            },
        ],
    },
    hoursBudget: {
        id: 'hoursBudget',
        label: 'Hours Budget',
        type: 'number',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.phase?.hoursBudget || row.project?.hoursBudget
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('projectHoursBudget', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['projectHoursBudget', 'projectPhases.hoursBudget']],
                subQueries: [
                    {
                        label: 'projectPhases',
                        collection: 'phases',
                        join: 'projectId == projectPhases.projectId',
                        groupBy: ['projectId'],
                        fields: [['hoursBudget', 'sum(hoursBudget)']],
                    },
                ],
                chain: [
                    {
                        collection: 'phases',
                        join: { phases: 'projectId', timeEntries: 'projectId' },
                        fields: ['hoursBudget', 'projectId'],
                    },
                ],
            },
        ],
    },
    startMinutes: {
        id: 'startMinutes',
        label: 'Start Time',
        type: 'time',
        width: 20,
        value: (row) => {
            return row.startMinutes
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.time[operator]('startMinutes', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: ['startMinutes'],
            },
        ],
    },
    endMinutes: {
        id: 'endMinutes',
        label: 'End Time',
        type: 'time',
        width: 20,
        value: (row) => {
            return row.endMinutes
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.time[operator]('endMinutes', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['endMinutes', 'startMinutes + numMinutes']],
            },
        ],
    },
    labourExpense: {
        id: 'labourExpense',
        label: 'Labour Expense',
        type: 'currency',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.pay
        },
        permissions: (r) => canViewStaffPayRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('pay', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['pay', 'pay::numeric / 100']],
            },
        ],
    },
    cost: {
        id: 'cost',
        label: 'Expense (cost)',
        type: 'currency',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.cost
        },
        permissions: (r) => canViewStaffCostRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('cost', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['cost', 'cost::numeric / 100']],
            },
        ],
    },
    chargeOut: {
        id: 'chargeOut',
        label: 'Charge Out',
        type: 'currency',
        width: 10,
        aggregate: 'sum',
        value: (row) => {
            return row.chargeOut
        },
        permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('chargeOut', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['chargeOut', 'chargeOut::numeric / 100']],
            },
        ],
    },
    chargeOutRate: {
        id: 'chargeOutRate',
        label: 'Charge Out Rate',
        type: 'currency',
        width: 20,
        aggregate: 'sum',
        value: (row) => {
            return row.chargeOut / row.hours
        },
        permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('chargeOutRate', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [
                    [
                        'chargeOutRate',
                        'numMinutes > 0 ? (chargeOut::numeric / 100) / (numMinutes::numeric / 60) : 0',
                    ],
                ],
            },
        ],
    },
    hasNote: {
        id: 'hasNote',
        label: 'Has Note',
        type: 'boolean',
        width: 10,
        value: (row) => {
            return row.note && row.note.length > 0
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('hasNote', value)
        },
        queries: (report) => [
            {
                collection: 'timeEntries',
                fields: [['hasNote', 'notes != null and notes != ""']],
            },
        ],
    },
})
