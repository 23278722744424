import { format } from 'date-fns'
import pluralize from 'pluralize'
import { FormatCurrency } from '../Utils/Localisation/CurrencyFormatter'
import { FormatNumber } from '../Utils/Localisation/NumberFormatter'
import { FormatPercent } from '../Utils/Localisation/PercentFormatter'
import _ from 'lodash'
import { FormatDate } from '../Utils/Localisation/DateFormatter'
import { FormatMinutes } from './Formatter'

export default {
    boolean: (values, { fallbackValue } = {}) => {
        return (
            values.map?.((value) => String(value)).join(', ') ??
            values ??
            fallbackValue
        )
    },
    text: (values, { fallbackValue } = {}) => {
        return (
            values.map?.((value) => String(value)).join(', ') ??
            values ??
            fallbackValue
        )
    },
    number: (value, { decimals = 2, fallbackValue } = {}) =>
        isFinite(value)
            ? FormatNumber(_.sum(value), { decimals }) ?? fallbackValue
            : fallbackValue,
    durationMinutes: (value, { fallbackValue } = {}) =>
        isFinite(value)
            ? FormatMinutes(_.sum(value), { fallbackValue }) ?? fallbackValue
            : fallbackValue,
    currency: (value, { decimals = 2, fallbackValue } = {}) =>
        isFinite(value)
            ? FormatCurrency(_.sum(value), { decimals }) ?? fallbackValue
            : fallbackValue,
    // TODO - useful?
    percent: (value, { decimals = 2, fallbackValue } = {}) =>
        isFinite(value)
            ? FormatPercent(_.sum(value), { decimals }) ?? fallbackValue
            : fallbackValue,
    date: (value, { fallbackValue } = {}) =>
        value instanceof Date && !isNaN(value)
            ? FormatDate(_.sum(value))
            : fallbackValue,
    duration: (value, { decimals = 2, fallbackValue } = {}) =>
        value?.value && value?.unit
            ? `${FormatNumber(value.value, { decimals })} ${pluralize(
                  value.unit,
                  FormatNumber(value.value, { decimals })
              )}`
            : fallbackValue,
    progress: (value, { decimals = 0, fallbackValue } = {}) =>
        isFinite(value)
            ? FormatNumber(_.sum(value), { decimals }) ?? fallbackValue
            : fallbackValue,
    progressBar: (value, { decimals = 0, fallbackValue } = {}) =>
        isFinite(value)
            ? FormatNumber(_.sum(value), { decimals }) ?? fallbackValue
            : fallbackValue,
    project: (values, { fallbackValue } = {}) =>
        values.map((value) => value?.title).join(', ') ?? fallbackValue,
    phase: (values, { fallbackValue } = {}) =>
        values.map((value) => value?.title).join(', ') ?? fallbackValue,
    costCentre: (values, { fallbackValue } = {}) =>
        values.map((value) => value?.name).join(', ') ?? fallbackValue,
    contact: (values, { fallbackValue } = {}) =>
        values.map((value) => value?.label).join(', ') ?? fallbackValue,
    staff: (values, { fallbackValue } = {}) =>
        values.map((value) => value?.fullName).join(', ') ?? fallbackValue,
    staffMember: (values, { fallbackValue } = {}) =>
        values.map((value) => value?.fullName).join(', ') ?? fallbackValue,
    role: (values, { fallbackValue } = {}) =>
        values.map((value) => value?.name).join(', ') ?? fallbackValue,
    status: (values, { fallbackValue } = {}) => {
        return (
            values.map?.((value) => String(value)).join(', ') ??
            values ??
            fallbackValue
        )
    },
}
