import { observer } from 'mobx-react'
import React, { useState } from 'react'
import TimesheetAppStore from './TimesheetAppStore'

export default observer(
    ({ title, label, items, onChange, backRoute, sortBy }) => {
        const [search, setSearch] = useState('')
        return (
            <div>
                <div
                    className="top-nav"
                    style={{ borderBottom: '1px solid #ccc' }}
                >
                    <div className="nav-btn right" />
                    <div className="nav-title">Select {title} Below</div>
                    <div
                        className="nav-btn right"
                        onClick={() => {
                            TimesheetAppStore.setRoute('listEntries')
                            TimesheetAppStore.resetSelection()
                        }}
                    >
                        <i className="fa fa-times" />
                    </div>
                </div>
                <div style={{ marginTop: '-4.8em' }}>
                    <div
                        className="project-input"
                        style={{
                            padding: '1rem',
                            backgroundColor: '#f2f2f2',
                        }}
                    >
                        <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search..."
                            style={{
                                fontSize: '1.5em',
                                letterSpacing: '0.1em',
                                fontStyle: 'italic',
                            }}
                        />
                    </div>
                    {[...items]
                        .sort((a, b) => a[label]?.localeCompare?.(b[label]))
                        .sort((a, b) => {
                            if (sortBy && a[sortBy] && b[sortBy]) {
                                return a[sortBy] > b[sortBy] ? 1 : -1
                            }
                            return a[label]?.localeCompare?.(b[label])
                        })
                        .filter((item) =>
                            item[label]
                                .toLowerCase()
                                .includes(search.toLowerCase())
                        )
                        .map(function (item, i) {
                            return (
                                <div
                                    key={i}
                                    className="project"
                                    onClick={() => onChange(item)}
                                >
                                    <div className="project-sel">
                                        <div className="project-text">
                                            <span className="add-entry-form__item">
                                                {item[label]}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    <div style={{ borderBottom: 'solid 1px #ccc' }}>
                        <div
                            className="project-btn"
                            onClick={() =>
                                TimesheetAppStore.setRoute(backRoute)
                            }
                        >
                            {'<< Back'}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
)
