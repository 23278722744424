import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns'
import _ from 'lodash'
import RevenueTargetAggregateCollection from '../State/Aggregates/RevenueTargetAggregateCollection'
import InvoiceLineItemAggregateCollection from '../State/Aggregates/InvoiceLineItemAggregateCollection'
import ProjectExpenseAggregateCollection from '../State/Aggregates/ProjectExpenseAggregateCollection'
import TimeEntryAggregateCollection from '../State/Aggregates/TimeEntryAggregateCollection'
import AllocationAggregateCollection from '../State/Aggregates/AllocationAggregateCollection'
import DateRangeDataStore from '../State/DateRangeDataStore'
import { qf } from '../Queries/queryFormatter'
import RevenueTargetCollection from '../State/Collections/RevenueTargetCollection'
import AllocationCollection from '../State/Collections/AllocationCollection'
import BudgetCollection from '../State/Collections/BudgetCollection'
import { get } from 'underscore'

export const formatMonth = (month) => {
    return typeof month === 'string' ? month : format(month, 'yyyy-MM')
}
export const getIsPastMonth = (month) => {
    return formatMonth(month) < format(new Date(), 'yyyy-MM')
}
export const getIsFutureMonth = (month) => {
    return formatMonth(month) > format(new Date(), 'yyyy-MM')
}
export const getIsCurrentMonth = (month) => {
    return formatMonth(month) === format(new Date(), 'yyyy-MM')
}
const forecastRevenueGroups = ['month', 'projectId', 'phaseId', 'status']
const forecastExpenseGroups = ['endMonth', 'projectId', 'phaseId', 'status']
const forecastTimeGroups = [
    'month',
    'projectId',
    'phaseId',
    'staffId',
    'roleId',
    'status',
]
export const forecastRevenueTargets =
    RevenueTargetAggregateCollection.getGroupCollection(forecastRevenueGroups)
export const forecastInvoiceLineItems =
    InvoiceLineItemAggregateCollection.getGroupCollection(forecastRevenueGroups)
export const forecastExpenses =
    ProjectExpenseAggregateCollection.getGroupCollection(forecastExpenseGroups)
export const forecastTime =
    TimeEntryAggregateCollection.getGroupCollection(forecastTimeGroups)
export const forecastAllocations =
    AllocationAggregateCollection.getGroupCollection(forecastTimeGroups)

export const getForecastRevenueTargets = ({
    month,
    project,
    phase,
    status,
}) => {
    return (
        (phase && forecastRevenueTargets.revenueTargetsByPhaseId[phase.id]) ||
        (project &&
            forecastRevenueTargets.revenueTargetsByProjectId[project.id]) ||
        (status && forecastRevenueTargets.revenueTargetsByStatus[status]) ||
        []
    )?.filter((rt) => {
        return (
            (!month || formatMonth(rt.month) === formatMonth(month)) &&
            (!project || rt.projectId === project.id) &&
            (!phase || rt.phaseId === phase.id) &&
            (!status || rt.status === status)
        )
    })
}

export const getForecastInvoiceLineItems = ({
    month,
    project,
    phase,
    status,
}) => {
    return (
        (phase && forecastInvoiceLineItems.lineItemsByPhaseId[phase.id]) ||
        (project &&
            forecastInvoiceLineItems.lineItemsByProjectId[project.id]) ||
        (status && forecastInvoiceLineItems.lineItemsByStatus[status]) ||
        []
    )?.filter((ili) => {
        return (
            (!month || formatMonth(ili.month) === formatMonth(month)) &&
            (!project || ili.projectId === project.id) &&
            (!phase || ili.phaseId === phase.id) &&
            (!status || ili.status === status)
        )
    })
}

export const getForecastExpenses = ({ endMonth, project, phase, status }) => {
    return (
        (phase && forecastExpenses.expensesByPhaseId[phase.id]) ||
        (project && forecastExpenses.expensesByProjectId[project.id]) ||
        (status && forecastExpenses.expensesByStatus[status]) ||
        []
    )?.filter((e) => {
        return (
            (!endMonth || formatMonth(e.endMonth) === formatMonth(endMonth)) &&
            (!project || e.projectId === project.id) &&
            (!phase || e.phaseId === phase.id) &&
            (!status || e.status === status)
        )
    })
}

export const getForecastTime = ({
    month,
    project,
    phase,
    staff,
    role,
    status,
}) => {
    return (
        (staff && forecastTime.timeEntriesByStaffId[staff.id]) ||
        (phase && forecastTime.timeEntriesByPhaseId[phase.id]) ||
        (project && forecastTime.timeEntriesByProjectId[project.id]) ||
        (role && forecastTime.timeEntriesByRoleId[role.id]) ||
        (status && forecastTime.timeEntriesByStatus[status]) ||
        []
    ).filter((te) => {
        return (
            (!month || formatMonth(te.month) === formatMonth(month)) &&
            (!project || te.projectId === project?.id) &&
            (!phase || te.phaseId === phase?.id) &&
            (!staff || te.staffId === staff?.id) &&
            (!role ||
                te.roleId === role.id ||
                te.staff?.role?.id === role?.id) &&
            (!status || te.status === status)
        )
    })
}

export const getForecastAllocations = ({
    month,
    project,
    phase,
    staff,
    role,
    status,
}) => {
    return (
        (staff && forecastAllocations.allocationsByStaffId[staff.id]) ||
        (phase && forecastAllocations.allocationsByPhaseId[phase.id]) ||
        (project && forecastAllocations.allocationsByProjectId[project.id]) ||
        (role && forecastAllocations.allocationsByRoleId[role.id]) ||
        (status && forecastAllocations.allocationsByStatus[status]) ||
        []
    ).filter((da) => {
        return (
            (!month || formatMonth(da.month) === formatMonth(month)) &&
            (!project || da.projectId === project.id) &&
            (!phase || da.phaseId === phase.id) &&
            (!staff || da.staffId === staff.id) &&
            (!role ||
                da.roleId === role.id ||
                da.staff?.role?.id === role.id) &&
            (!status || da.status === status)
        )
    })
}

export const getForecastBudgets = ({ project, phase, staff, role }) => {
    return (
        (staff && BudgetCollection.budgetsByStaffId[staff.id]) ||
        (phase && BudgetCollection.budgetsByPhaseId[phase.id]) ||
        (project && BudgetCollection.budgetsByProjectId[project.id]) ||
        (role && BudgetCollection.budgetsByRoleId[role.id]) ||
        []
    ).filter((da) => {
        return (
            (!project || da.projectId === project.id) &&
            (!phase || da.phaseId === phase.id) &&
            (!staff || da.staffId === staff.id) &&
            (!role || (da.roleId === role.id && !da.staffId))
        )
    })
}

export const getRevenueInMonth = ({
    month,
    project,
    phase,
    status,
    dataType = 'actualProjected',
}) => {
    const isPastMonth = getIsPastMonth(month)
    const isFutureMonth = getIsFutureMonth(month)
    const revenueTargets = getForecastRevenueTargets({
        month,
        project,
        phase,
        status,
    })
    const invoiceLineItems = getForecastInvoiceLineItems({
        month,
        project,
        phase,
        status,
    })
    if (isPastMonth || dataType === 'actual') {
        return _.sum(invoiceLineItems.map((rt) => rt.revenue))
    } else if (isFutureMonth || dataType === 'projected') {
        return _.sum(revenueTargets.map((rt) => rt.revenue))
    } else {
        return Math.max(
            _.sum(invoiceLineItems.map((rt) => rt.revenue)),
            _.sum(revenueTargets.map((rt) => rt.revenue))
        )
    }
}

export const getRevenueBeforeMonth = ({
    month,
    project,
    phase,
    status,
    dataType = 'actualProjected',
}) => {
    const monthsWithRevenue = _.uniq(
        [
            ...getForecastRevenueTargets({
                project,
                phase,
                status,
            }).map((rt) => rt.month),
            ...getForecastInvoiceLineItems({
                project,
                phase,
                status,
            }).map((rt) => rt.month),
        ].filter((m) => m < formatMonth(month))
    )
    return _.sum(
        monthsWithRevenue.map((m) =>
            getRevenueInMonth({
                month: m,
                project,
                phase,
                status,
                dataType,
            })
        )
    )
}

export const getRevenueAfterMonth = ({
    month,
    project,
    phase,
    status,
    dataType = 'actualProjected',
}) => {
    const monthsWithRevenue = _.uniq(
        [
            ...getForecastRevenueTargets({
                project,
                phase,
                status,
            }).map((rt) => rt.month),
            ...getForecastInvoiceLineItems({
                project,
                phase,
                status,
            }).map((rt) => rt.month),
        ].filter((m) => m > formatMonth(month))
    )
    return _.sum(
        monthsWithRevenue.map((m) =>
            getRevenueInMonth({
                month: m,
                project,
                phase,
                status,
                dataType,
            })
        )
    )
}

export const getExpenseInMonth = ({
    endMonth,
    projectId,
    phaseId,
    status,
    dataType = 'actualProjected',
}) => {
    // const isPastMonth = getIsPastMonth(endMonth)
    // const isFutureMonth = getIsFutureMonth(endMonth)
    const expenses = getForecastExpenses({
        endMonth,
        projectId,
        phaseId,
        status,
    })
    // if (isPastMonth || dataType === 'actual') {
    return _.sum(expenses.map((e) => e.amount))
    // } else if (isFutureMonth || dataType === 'projected') {
    //     return _.sum(projectExpenses.map((e) => e.amount))
    // } else {
    //     return Math.max(
    //         _.sum(projectExpenses.map((e) => e.amount)),
    //         _.sum(projectExpenses.map((e) => e.amount))
    //     )
    // }
}

export const getExpenseBeforeMonth = ({
    endMonth,
    project,
    phase,
    status,
    dataType = 'actualProjected',
}) => {
    const monthsWithExpenses = _.uniq(
        getForecastExpenses({
            project,
            phase,
            status,
        })
            .map((e) => e.endMonth)
            .filter((m) => m < formatMonth(endMonth))
    )
    return _.sum(
        monthsWithExpenses.map((m) =>
            getExpenseInMonth({
                endMonth: m,
                project,
                phase,
                status,
                dataType,
            })
        )
    )
}

export const getExpenseAfterMonth = ({
    endMonth,
    project,
    phase,
    status,
    dataType = 'actualProjected',
}) => {
    const monthsWithExpenses = _.uniq(
        getForecastExpenses({
            project,
            phase,
            status,
        })
            .map((e) => e.endMonth)
            .filter((m) => m > formatMonth(endMonth))
    )
    return _.sum(
        monthsWithExpenses.map((m) =>
            getExpenseInMonth({
                endMonth: m,
                project,
                phase,
                status,
                dataType,
            })
        )
    )
}

export const getTimeInMonth = ({
    month,
    project,
    phase,
    staff,
    role,
    status,
    dataType = 'actualProjected',
}) => {
    const isPastMonth = getIsPastMonth(month)
    const isFutureMonth = getIsFutureMonth(month)
    const time = getForecastTime({
        month,
        project,
        phase,
        staff,
        role,
        status,
    })
    const allocations = getForecastAllocations({
        month,
        project,
        phase,
        staff,
        role,
        status,
    })
    if (isPastMonth || dataType === 'actual') {
        return _.sum(time.map((te) => te.hours))
    } else if (isFutureMonth || dataType === 'projected') {
        return _.sum(allocations.map((te) => te.hours))
    } else {
        return Math.max(
            _.sum(time.map((te) => te.hours)),
            _.sum(allocations.map((te) => te.hours))
        )
    }
}

export const getTimeBeforeMonth = ({
    month,
    project,
    phase,
    staff,
    role,
    status,
    dataType = 'actualProjected',
}) => {
    const monthsWithTime = _.uniq(
        [
            ...getForecastTime({
                project,
                phase,
                staff,
                role,
                status,
            }).map((te) => te.month),
            ...getForecastAllocations({
                project,
                phase,
                staff,
                role,
                status,
            }).map((te) => te.month),
        ].filter((m) => m < formatMonth(month))
    )
    return _.sum(
        monthsWithTime.map((m) =>
            getTimeInMonth({
                month: m,
                project,
                phase,
                staff,
                role,
                status,
                dataType,
            })
        )
    )
}

export const getTimeAfterMonth = ({
    month,
    project,
    phase,
    staff,
    role,
    status,
    dataType = 'actualProjected',
}) => {
    const monthsWithTime = _.uniq(
        [
            ...getForecastTime({
                project,
                phase,
                staff,
                role,
                status,
            }).map((te) => te.month),
            ...getForecastAllocations({
                project,
                phase,
                staff,
                role,
                status,
            }).map((te) => te.month),
        ].filter((m) => m > formatMonth(month))
    )
    return _.sum(
        monthsWithTime.map((m) =>
            getTimeInMonth({
                month: m,
                project,
                phase,
                staff,
                role,
                status,
                dataType,
            })
        )
    )
}

export const getTimeCostInMonth = ({
    month,
    project,
    phase,
    staff,
    role,
    status,
    dataType = 'actualProjected',
}) => {
    const isPastMonth = getIsPastMonth(month)
    const isFutureMonth = getIsFutureMonth(month)
    const time = getForecastTime({
        month,
        project,
        phase,
        staff,
        role,
        status,
    })
    const allocations = getForecastAllocations({
        month,
        project,
        phase,
        staff,
        role,
        status,
    })
    if (isPastMonth || dataType === 'actual') {
        return _.sum(time.map((te) => te.cost))
    } else if (isFutureMonth || dataType === 'projected') {
        return _.sum(allocations.map((te) => te.cost))
    } else {
        return Math.max(
            _.sum(time.map((te) => te.cost)),
            _.sum(allocations.map((te) => te.cost))
        )
    }
}

export const getTimeCostBeforeMonth = ({
    month,
    project,
    phase,
    staff,
    role,
    status,
    dataType = 'actualProjected',
}) => {
    const monthsWithTime = _.uniq(
        [
            ...getForecastTime({
                project,
                phase,
                staff,
                role,
                status,
            }).map((te) => te.month),
            ...getForecastAllocations({
                project,
                phase,
                staff,
                role,
                status,
            }).map((te) => te.month),
        ].filter((m) => m < formatMonth(month))
    )
    return _.sum(
        monthsWithTime.map((m) =>
            getTimeCostInMonth({
                month: m,
                project,
                phase,
                staff,
                role,
                status,
                dataType,
            })
        )
    )
}

export const getTimeCostAfterMonth = ({
    month,
    project,
    phase,
    staff,
    role,
    status,
    dataType = 'actualProjected',
}) => {
    const monthsWithTime = _.uniq(
        [
            ...getForecastTime({
                project,
                phase,
                staff,
                role,
                status,
            }).map((te) => te.month),
            ...getForecastAllocations({
                project,
                phase,
                staff,
                role,
                status,
            }).map((te) => te.month),
        ].filter((m) => m > formatMonth(month))
    )
    return _.sum(
        monthsWithTime.map((m) =>
            getTimeCostInMonth({
                month: m,
                project,
                phase,
                staff,
                role,
                status,
                dataType,
            })
        )
    )
}

const getProjectItemsWithBudgetAndDates = (project, itemName, budgetName) => {
    return project[itemName].filter(
        (item) => item[budgetName] && item.startDate && item.endDate
    )
}

const getKeyDatesFromItems = (items) => {
    return _.uniq([
        ...items.map((item) => item.startDate),
        ...items.map((item) => item.endDate),
    ]).sort((a, b) => a - b)
}

const makeItemBudgetLookup = ({
    items,
    budgetName,
    budgetUseBefore,
    currentBudgetUse,
}) => {
    const itemBudgetLookup = {}
    items.forEach((item) => {
        const budget = Math.max(
            item[budgetName],
            budgetUseBefore(item) + currentBudgetUse(item)
        )
        itemBudgetLookup[item.id] = {
            budget,
            budgetUseBefore: budgetUseBefore(item),
            currentBudgetUse: currentBudgetUse(item),
            budgetAvailable: budget - budgetUseBefore(item),
        }
    })
    return itemBudgetLookup
}

const getItemsInDateRange = (items, dateRange) => {
    return items.filter(
        (item) =>
            item.startDate &&
            item.endDate &&
            item.startDate <= dateRange[0] &&
            item.endDate >= dateRange[1]
    )
}

const dateRangeIntersection = (dateRange1, dateRange2) => {
    return [
        Math.max(dateRange1[0], dateRange2[0]),
        Math.min(dateRange1[1], dateRange2[1]),
    ]
}

const getItemBudgetAtDate = (item, date, val) => {
    if (date >= item.endDate) return val
    if (date <= item.startDate) return 0
    return val * ((date - item.startDate) / (item.endDate - item.startDate))
}

const getBudgetInDateRange = (item, dateRange, val) => {
    return (
        getItemBudgetAtDate(item, dateRange[1], val) -
        getItemBudgetAtDate(item, dateRange[0], val)
    )
}

const getItemOrderOfDistribution = ({
    items,
    keyDates,
    itemBudgetLookup,
    budgetName,
    reverse = false,
}) => {
    let itemOrder = []
    keyDates.forEach((date, i) => {
        const nextDate = keyDates[i + 1]
        if (date && nextDate) {
            // phases in this chunk of key dates
            const itemsInDateRange = getItemsInDateRange(items, [
                date,
                nextDate,
            ])
            itemOrder.push(
                itemsInDateRange.map((item) => {
                    const budgetLookup = itemBudgetLookup[item.id]
                    const dateRange = dateRangeIntersection(
                        [item.startDate, item.endDate],
                        [date, nextDate]
                    )
                    const totalBudgetAtRangeEnd = getItemBudgetAtDate(
                        item,
                        dateRange[1],
                        budgetLookup.budget
                    )
                    const budgetInRange = getBudgetInDateRange(
                        item,
                        dateRange,
                        budgetLookup.budget
                    )
                    // some or all of this budget in range may be consumed by previously allocated use
                    // best way to check is to compare to total budget at end of range with the total budget
                    // additionally, we don't want this number to be less than 0
                    const availableBudgetInRange = Math.max(
                        Math.min(
                            totalBudgetAtRangeEnd -
                                budgetLookup.budgetUseBefore,
                            budgetInRange
                        ),
                        0
                    )

                    // some or all of this budget may already be allocated prior to this update
                    // we can compare current budget use to the available budget
                    // if the current use is higher, then we've already allocated this chunk of budget
                    const committedBudget = Math.min(
                        budgetLookup.currentBudgetUse,
                        availableBudgetInRange
                    )
                    budgetLookup.currentBudgetUse -= committedBudget

                    return {
                        id: item.id,
                        itemBudget: item[budgetName],
                        item,
                        budgetInRange: availableBudgetInRange,
                        committedBudget,
                    }
                })
            )
        }
    })
    // add items without dates
    const itemsWithoutDates = items.filter(
        (item) => !item.startDate || !item.endDate
    )
    itemOrder.push(
        itemsWithoutDates.map((item) => {
            const budgetLookup = itemBudgetLookup[item.id]
            const availableBudgetInRange =
                budgetLookup.budget - budgetLookup.budgetUseBefore
            const committedBudget = Math.min(
                budgetLookup.currentBudgetUse,
                availableBudgetInRange
            )
            return {
                id: item.id,
                itemBudget: item[budgetName],
                item,
                budgetInRange: availableBudgetInRange,
                committedBudget,
            }
        })
    )
    if (reverse) {
        itemOrder = itemOrder.reverse().map((phases) => phases.reverse())
    }
    return itemOrder
}

const assignBudgetsByItemId = (orderedItems, amount) => {
    const amountByPhaseId = {}
    let remainingAmount = amount
    orderedItems.forEach((orderedItemGroup) => {
        const totalBudgetInRange = _.sum(
            orderedItemGroup.map((pf) => pf.budgetInRange - pf.committedBudget)
        )
        const totalCommittedBudget = _.sum(
            orderedItemGroup.map((pf) => pf.committedBudget)
        )
        const totalItemBudget = _.sum(
            orderedItemGroup.map((pf) => pf.itemBudget)
        )
        const remaining = remainingAmount // cache to avoid ruining ratios below
        orderedItemGroup.forEach((item) => {
            amountByPhaseId[item.id] ??= 0
            amountByPhaseId[item.id] += item.committedBudget

            // use committedBudget if negative otherwise we may never get to 0
            const budgetRatio =
                (remaining > 0
                    ? (item.budgetInRange - item.committedBudget) /
                      totalBudgetInRange
                    : item.committedBudget / totalCommittedBudget) ||
                item.itemBudget / totalItemBudget

            const amount =
                remaining >= 0
                    ? Math.min(
                          remaining * budgetRatio,
                          item.budgetInRange - item.committedBudget
                      )
                    : Math.max(
                          remaining * budgetRatio,
                          // is this right?
                          amountByPhaseId[item.id] * -1
                      )

            amountByPhaseId[item.id] += amount
            remainingAmount -= amount // be careful with ratios
        })
    })
    return amountByPhaseId
}

export const setRevenueInMonth = ({ month, project, phase, amount }) => {
    const diffAmount = amount - getRevenueInMonth({ month, project, phase })
    if (!diffAmount) return
    if (!phase) {
        if (project.fee && project.startDate && project.endDate) {
            const phases = project.phases.filter((ph) => ph.fee)
            const keyDates = getKeyDatesFromItems(phases)
            const phaseLookup = makeItemBudgetLookup({
                items: phases,
                budgetName: 'fee',
                budgetUseBefore: (phase) =>
                    getRevenueBeforeMonth({
                        month,
                        project,
                        phase,
                    }),
                currentBudgetUse: (phase) =>
                    getRevenueInMonth({
                        month,
                        project,
                        phase,
                    }),
            })
            const phaseOrder = getItemOrderOfDistribution({
                items: phases,
                keyDates,
                itemBudgetLookup: phaseLookup,
                budgetName: 'fee',
                reverse: diffAmount < 0,
            })
            const amountByPhaseId = assignBudgetsByItemId(
                phaseOrder,
                diffAmount
            )
            phases.forEach((phase) => {
                setRevenueInMonth({
                    month,
                    project,
                    phase,
                    amount: amountByPhaseId[phase.id] || 0,
                })
            })
        } else {
            project.phases.forEach((phase) => {
                setRevenueInMonth({
                    month,
                    project,
                    phase,
                    amount:
                        getRevenueInMonth({
                            month,
                            project,
                            phase: phase,
                        }) +
                        diffAmount / project.phases.length,
                })
            })
        }
    } else {
        const previousAmount = getRevenueInMonth({ month, project, phase })
        if (previousAmount === amount) return
        if (previousAmount) {
            const ratio = amount / previousAmount
            const phaseRevenueTargets = getForecastRevenueTargets({
                month,
                project,
                phase,
            })
            phaseRevenueTargets.forEach((rt) => {
                rt.update({
                    revenue: Math.round(rt.revenue * ratio * 100) / 100,
                })
                DateRangeDataStore.addData(rt.id, {
                    collection: 'revenueTargets',
                    dateRange: [qf(startOfMonth(month)), qf(endOfMonth(month))],
                    field: 'amount',
                    value: rt.revenue,
                    filters: {
                        phaseId: rt.phaseId,
                        projectId: rt.projectId,
                    },
                })
            })
        } else {
            const newRevenueTarget = {
                month: formatMonth(month),
                projectId: project?.id,
                phaseId: phase?.id,
                status: phase?.status,
                revenue: Math.round(amount * 100) / 100,
            }
            forecastRevenueTargets.addRevenueTarget(newRevenueTarget)
            RevenueTargetCollection.addRevenueTarget(
                {
                    ...newRevenueTarget,
                    date: endOfMonth(month),
                },
                { trackUpdates: true }
            )
        }
    }
}

export const setTimeInMonth = ({
    month,
    project,
    phase,
    role,
    staff,
    budget,
    amount,
    budgetType = 'time',
}) => {
    const budgetData =
        budgetType === 'time'
            ? {
                  budgetName: 'hours',
                  budgetUseBefore: getTimeBeforeMonth,
                  budgetUseCurrent: getTimeInMonth,
              }
            : {
                  budgetName: 'cost',
                  budgetUseBefore: getTimeCostBeforeMonth,
                  budgetUseCurrent: getTimeCostInMonth,
              }
    const diffAmount =
        amount -
        budgetData.budgetUseCurrent({
            month,
            project,
            phase,
            role,
            staff,
        })
    if (!diffAmount) return
    if (!budget) {
        const budgets = getForecastBudgets({
            project,
            phase,
            staff,
            role,
        }).filter((b) => b.hours)
        if (budgets.length) {
            const keyDates = getKeyDatesFromItems(budgets)
            const budgetLookup = makeItemBudgetLookup({
                items: budgets,
                budgetName: budgetData.budgetName,
                budgetUseBefore: (budget) =>
                    budgetData.budgetUseBefore({
                        month,
                        project: budget?.project,
                        phase: budget?.phase,
                        staff: budget?.staff,
                        role: budget?.role,
                    }),
                currentBudgetUse: (budget) =>
                    budgetData.budgetUseCurrent({
                        month,
                        project: budget?.project,
                        phase: budget?.phase,
                        staff: budget?.staff,
                        role: budget?.role,
                    }),
            })
            const budgetOrder = getItemOrderOfDistribution({
                items: budgets,
                keyDates,
                itemBudgetLookup: budgetLookup,
                budgetName: budgetData.budgetName,
                reverse: diffAmount < 0,
            })
            const amountByBudgetId = assignBudgetsByItemId(
                budgetOrder,
                diffAmount
            )
            budgets.forEach((budget) => {
                setTimeInMonth({
                    month,
                    project: budget?.project,
                    phase: budget?.phase,
                    staff: budget?.staff,
                    role: budget?.role,
                    budget,
                    amount: amountByBudgetId[budget.id] || 0,
                    budgetType,
                })
            })
        } else {
            return
        }
    } else {
        const previousAmount = budgetData.budgetUseCurrent({
            month,
            project: budget?.project,
            phase: budget?.phase,
            staff: budget?.staff,
            role: budget?.role,
        })
        if (previousAmount === amount) return
        if (previousAmount) {
            const ratio = amount / previousAmount
            const budgetAllocations = getForecastAllocations({
                month,
                project: budget?.project,
                phase: budget?.phase,
                staff: budget?.staff,
                role: budget?.role,
            })
            budgetAllocations.forEach((al) => {
                al.update({
                    numMinutes: Math.round(al.numMinutes * ratio * 100) / 100,
                    pay: Math.round(al.pay * ratio * 100) / 100,
                    cost: Math.round(al.cost * ratio * 100) / 100,
                    chargeOut: Math.round(al.chargeOut * ratio * 100) / 100,
                })
                DateRangeDataStore.addData(al.id, {
                    collection: 'allocations',
                    dateRange: [qf(startOfMonth(month)), qf(endOfMonth(month))],
                    field: 'numMinutes',
                    value: al.numMinutes,
                    filters: {
                        phaseId: al.phaseId,
                        projectId: al.projectId,
                        staffId: al.staffId,
                        roleId: al.roleId,
                    },
                })
            })
        } else {
            amount = budgetType === 'time' ? amount : amount / budget.costRate
            const newAllocation = {
                month: formatMonth(month),
                projectId: budget?.project?.id,
                phaseId: budget?.phase?.id,
                status: budget?.phase?.status,
                staffId: budget?.staff?.id,
                roleId: budget?.role?.id,
                numMinutes: Math.round(amount * 60 * 100) / 100,
                pay: (Math.round(amount * 100) / 100) * budget.payRate,
                cost: (Math.round(amount * 100) / 100) * budget.costRate,
                chargeOut:
                    (Math.round(amount * 100) / 100) * budget.chargeOutRate,
            }
            forecastAllocations.addAllocation(newAllocation)
            AllocationCollection.addAllocation(
                {
                    ...newAllocation,
                    date: endOfMonth(month),
                },
                { trackUpdates: true }
            )
        }
    }
}

export const getItemFee = (item) => {
    return item.fee
}

export const getMaxPhaseFee = (phase, month) => {
    return Math.max(
        getItemFee(phase),
        getRevenueBeforeMonth({
            month: addMonths(month, 1),
            project: phase.project,
            phase,
        })
    )
}

export const getMaxProjectFee = (project, month) => {
    return _.sum(project.phases.map((phase) => getMaxPhaseFee(phase, month)))
}

export const getItemExpenseBudget = (item) => {
    return item.costFromBudgets
}

export const getMaxPhaseExpenseBudget = (phase, month) => {
    return Math.max(
        getItemExpenseBudget(phase),
        getTimeCostBeforeMonth({
            month: addMonths(month, 1),
            project: phase.project,
            phase,
        })
    )
}

export const getMaxProjectExpenseBudget = (project, month) => {
    return _.sum(
        project.phases.map((phase) => getMaxPhaseExpenseBudget(phase, month))
    )
}

export const getPhaseRevenueProgressToDate = (phase, month) => {
    return (
        getRevenueBeforeMonth({
            month: addMonths(month, 1),
            project: phase.project,
            phase,
        }) / getItemFee(phase)
    )
}

export const setPhaseRevenueProgressForMonth = (phase, month, progress) => {
    if (!isFinite(progress)) return
    progress = Math.min(progress, 1)
    const project = phase.project
    const prevRevenue = getRevenueBeforeMonth({
        month,
        project,
        phase,
    })
    const value = progress * getItemFee(phase) - prevRevenue
    if (!getItemFee(phase) || value <= 0) return
    setRevenueInMonth({
        month,
        project,
        phase,
        amount: value,
    })
}

export const getPhaseExpenseProgressToDate = (phase, month) => {
    return (
        getTimeCostBeforeMonth({
            month: addMonths(month, 1),
            project: phase.project,
            phase,
        }) / getItemExpenseBudget(phase)
    )
}

export const setPhaseExpenseProgressForMonth = (phase, month, progress) => {
    if (!isFinite(progress)) return
    progress = Math.min(progress, 1)
    const project = phase.project
    const prevExpense = getTimeCostBeforeMonth({
        month,
        project,
        phase,
    })
    const value = progress * phase.costFromBudgets - prevExpense
    if (!getItemExpenseBudget(phase) || value <= 0) return
    setTimeInMonth({
        month,
        project,
        phase,
        amount: value,
        budgetType: 'cost',
    })
}
