import { capitalCase } from 'change-case'
import { format } from 'date-fns'
import pluralize from 'pluralize'
import { FormatCurrency } from '../Utils/Localisation/CurrencyFormatter'
import { FormatNumber } from '../Utils/Localisation/NumberFormatter'
import { FormatPercent } from '../Utils/Localisation/PercentFormatter'
import { FormatDate } from '../Utils/Localisation/DateFormatter'

export default {
    boolean: (value, { fallbackValue } = {}) =>
        value != null ? capitalCase(String(Boolean(value))) : fallbackValue,
    text: (value, { fallbackValue } = {}) =>
        value ? String(value) : fallbackValue,
    number: (value, { decimals = 0, fallbackValue } = {}) =>
        isFinite(value)
            ? FormatNumber(value, { decimals }) ?? fallbackValue
            : fallbackValue,
    currency: (value, { decimals = 0, fallbackValue } = {}) =>
        isFinite(value)
            ? FormatCurrency(value, { decimals }) ?? fallbackValue
            : fallbackValue,
    percent: (value, { decimals = 0, fallbackValue } = {}) =>
        isFinite(value)
            ? FormatPercent(value, { decimals }) ?? fallbackValue
            : fallbackValue,
    date: (value, { fallbackValue } = {}) =>
        value instanceof Date && !isNaN(value)
            ? FormatDate(value)
            : fallbackValue,
    duration: (value, { decimals = 2, fallbackValue } = {}) =>
        value?.value && value?.unit
            ? `${FormatNumber(value.value, { decimals })} ${pluralize(
                  value.unit,
                  FormatNumber(value.value, { decimals })
              )}`
            : fallbackValue,
    progress: (value, { decimals = 0, fallbackValue } = {}) =>
        isFinite(value)
            ? FormatNumber(value, { decimals: 0 }) ?? fallbackValue
            : fallbackValue,
    progressBar: (value, { decimals = 0, fallbackValue } = {}) =>
        isFinite(value)
            ? FormatNumber(value, { decimals: 0 }) ?? fallbackValue
            : fallbackValue,
    project: (value, { fallbackValue } = {}) =>
        value?.title ?? fallbackValue ?? '',
    phase: (value, { fallbackValue } = {}) =>
        value?.title ?? fallbackValue ?? '',
    task: (value, { fallbackValue } = {}) => value?.name ?? fallbackValue,
    costCentre: (value, { fallbackValue } = {}) =>
        value?.name ?? fallbackValue ?? '',
    contact: (value, { fallbackValue } = {}) =>
        value?.label ?? fallbackValue ?? '',
    staff: (value, { fallbackValue } = {}) =>
        value?.fullName ?? fallbackValue ?? '',
    staffMember: (value, { fallbackValue } = {}) =>
        value?.fullName ?? fallbackValue ?? '',
    role: (value, { fallbackValue } = {}) => value?.name ?? fallbackValue ?? '',
    status: (value, { fallbackValue } = {}) =>
        (value && capitalCase(value)) ?? fallbackValue ?? '',
    custom: (value, { fallbackValue } = {}) => value ?? fallbackValue,
    durationMinutes: (value, { fallbackValue } = {}) =>
        isFinite(value) ? FormatMinutes(value) ?? fallbackValue : fallbackValue,
}

export function FormatMinutes(totalMinutes) {
    let hours = Math.floor(totalMinutes / 60)
    let minutes = totalMinutes % 60
    return hours + ':' + (minutes < 10 ? '0' : '') + minutes
}
