import { observer } from 'mobx-react'
import React from 'react'
import { FormatCurrency } from '../Utils/Localisation/CurrencyFormatter'
import { ParseCurrency } from '../Utils/Localisation/CurrencyParser'
import { FormatNumber } from '../Utils/Localisation/NumberFormatter'
import { ParseNumber } from '../Utils/Localisation/NumberParser'
import { FormatPercent } from '../Utils/Localisation/PercentFormatter'
import { ParsePercent } from '../Utils/Localisation/PercentParser'
import { NumberInputContainer } from './Widgets/NumberInputContainer'
import {
    ContactMultiselect,
    CostCentreMultiselect,
    PhaseMultiselect,
    ProjectMultiselect,
    RoleMultiselect,
    StaffMultiselect,
    StatusMultiselect,
    TaskMultiselect,
} from './MultiSelect'
import {
    ContactSelector,
    CostCentreSelector,
    ProjectSelector,
    PhaseSelector,
    RoleSelector,
    Selector,
    StaffOrRoleSelector,
    StatusSelector,
    StaffSelector,
    TaskSelector,
    BooleanSelector,
} from './Selector'
import Checkbox from './Widgets/Checkbox'
import DateValue from './Widgets/DateValue'
import TextValue from './Widgets/TextValue'
import { HoursInput } from './Widgets/HoursInput'

export default {
    text: ({ value, editable, onChange, validator, ...props }) => (
        <TextValue
            value={value}
            isEditable={editable}
            className="coincraft-table-cell__input coincraft-table-cell__text"
            onChange={(v) => onChange(v)}
            isvalid={validator}
            {...props}
        />
    ),
    textarea: ({ value, editable, onChange, validator, ...props }) => (
        <textarea
            rows={2}
            value={value}
            disabled={!editable}
            className="coincraft-table-cell__textarea"
            onChange={(e) => onChange(e.target.value)}
            style={{ padding: '1em' }}
            // isvalid={validator}
            {...props}
        />
    ),
    number: ({ value, editable, onChange, validator, ...props }) => (
        <TextValue
            className="coincraft-table-cell__input coincraft-table-cell__currency"
            value={value}
            formatter={FormatNumber}
            parser={ParseNumber}
            isEditable={editable}
            onChange={onChange}
            isValid={validator}
            style={{ textAlign: 'right' }}
            {...props}
        />
    ),
    currency: ({ value, editable, onChange, validator, ...props }) => (
        <TextValue
            className="coincraft-table-cell__input coincraft-table-cell__currency"
            value={value}
            formatter={FormatCurrency}
            parser={ParseCurrency}
            isEditable={editable}
            onChange={onChange}
            isValid={validator}
            style={{ textAlign: 'right' }}
            {...props}
        />
    ),
    percent: ({ value, editable, onChange, validator, ...props }) => (
        <TextValue
            className="coincraft-table-cell__input coincraft-table-cell__currency"
            value={value}
            formatter={FormatPercent}
            parser={ParsePercent}
            isEditable={editable}
            onChange={onChange}
            isValid={validator}
            style={{ textAlign: 'right' }}
            {...props}
        />
    ),
    progress: ({ value, editable, onChange, validator, ...props }) => (
        <TextValue
            className="coincraft-table-cell__input coincraft-table-cell__currency"
            value={value}
            formatter={FormatPercent}
            parser={ParsePercent}
            isEditable={editable}
            onChange={onChange}
            isValid={validator}
            style={{ textAlign: 'right' }}
            {...props}
        />
    ),
    progressBar: ({ value, editable, onChange, validator, ...props }) => (
        <TextValue
            className="coincraft-table-cell__input coincraft-table-cell__currency"
            value={value}
            formatter={FormatPercent}
            parser={ParsePercent}
            isEditable={editable}
            onChange={onChange}
            isValid={validator}
            style={{ textAlign: 'right' }}
            {...props}
        />
    ),
    date: ({ value, editable, onChange, validator, ...props }) => (
        <DateValue
            isvalid={validator}
            value={value}
            isEditable={editable}
            onChange={onChange}
            {...props}
        />
    ),
    duration: ({ value, editable, onChange, validator, ...props }) => (
        <DurationInput
            value={value}
            isEditable={editable}
            className="coincraft-table-cell__input coincraft-table-cell__duration"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    checkbox: ({ value, editable, onChange, validator, ...props }) => (
        <Checkbox
            value={value}
            isEditable={editable}
            className="coincraft-table-cell__checkbox"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    button: ({ value, editable, onChange, onClick, validator, ...props }) => (
        <button
            disabled={editable === false}
            className="coincraft-table-cell__button"
            onClick={onClick}
            style={{
                ...props.style,
                ...(editable === false ? { opacity: 0.75 } : {}),
            }}
            {...props}
        >
            {value}
        </button>
    ),
    boolean: ({ value, editable, onChange, validator, ...props }) => (
        <BooleanSelector
            selectedOption={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__bool"
            onChange={onChange}
            isvalid={validator}
        />
    ),
    project: ({ value, editable, onChange, validator, ...props }) => (
        <ProjectSelector
            selectedProject={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__phase"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    phase: ({ value, editable, onChange, validator, ...props }) => (
        <PhaseSelector
            selectedPhase={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__phase"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    task: ({ value, editable, onChange, validator, ...props }) => (
        <TaskSelector
            selectedTask={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__task"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    contact: ({ value, editable, onChange, validator, ...props }) => (
        <ContactSelector
            selectedContact={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__contact"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    costCentre: ({ value, editable, onChange, validator, ...props }) => {
        return (
            <CostCentreSelector
                selectedCostCentre={value}
                isEditable={editable}
                className="coincraft-table-cell__dropdown coincraft-table-cell__contact"
                onChange={onChange}
                isvalid={validator}
                {...props}
            />
        )
    },
    staffMember: ({ value, editable, onChange, validator, ...props }) => (
        <StaffSelector
            selectedStaffOrRole={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__staff"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    staff: ({ value, editable, onChange, validator, ...props }) => (
        <StaffSelector
            selectedStaffOrRole={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__staff"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    role: ({ value, editable, onChange, validator, ...props }) => (
        <RoleSelector
            selectedStaffOrRole={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__staff"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    staffOrRole: ({ value, editable, onChange, validator, ...props }) => (
        <StaffOrRoleSelector
            selectedStaffOrRole={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__staffOrRole"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    status: ({ value, editable, onChange, validator, ...props }) => (
        <StatusSelector
            selectedStatus={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__status"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    statuses: ({ value, editable, onChange, validator, ...props }) => (
        <StatusMultiselect
            selectedStatuses={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__status"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    projects: ({ value, editable, onChange, validator, ...props }) => (
        <ProjectMultiselect
            selectedProjects={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__phase"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    phases: ({ value, editable, onChange, validator, ...props }) => (
        <PhaseMultiselect
            selectedPhases={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__phase"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    tasks: ({ value, editable, onChange, validator, ...props }) => (
        <TaskMultiselect
            selectedTasks={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__task"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    contacts: ({ value, editable, onChange, validator, ...props }) => (
        <ContactMultiselect
            selectedContacts={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__contact"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    costCentres: ({
        value,
        editable,
        onChange,
        onClick,
        validator,
        ...props
    }) => {
        return (
            <CostCentreMultiselect
                selectedCostCentres={value}
                isEditable={editable}
                className="coincraft-table-cell__dropdown coincraft-table-cell__contact"
                onChange={onChange}
                isvalid={validator}
                {...props}
            />
        )
    },
    staffs: ({ value, editable, onChange, validator, ...props }) => (
        <StaffMultiselect
            selectedStaffMembers={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__staff"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    staffMembers: ({
        value,
        editable,
        onChange,
        onClick,
        validator,
        ...props
    }) => (
        <StaffMultiselect
            selectedStaffMembers={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__staff"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    roles: ({ value, editable, onChange, validator, ...props }) => (
        <RoleMultiselect
            selectedRoles={value}
            isEditable={editable}
            className="coincraft-table-cell__dropdown coincraft-table-cell__staff"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
    durationMinutes: ({
        value,
        editable,
        onChange,
        onClick,
        validator,
        ...props
    }) => (
        <HoursInput
            value={value}
            isEditable={editable}
            className="coincraft-table-cell__input coincraft-table-cell__currency"
            onChange={onChange}
            isvalid={validator}
            {...props}
        />
    ),
}

const DurationInput = ({
    value,
    isEditable,
    onChange,
    isvalid,
    className,
    ...props
}) => {
    return (
        <div
            {...props}
            style={{ width: '100%' }}
            className={`flex ${className}`}
        >
            <div
                className="coincraft-table-cell coincraft-table-cell--text"
                style={{ width: '40%' }}
            >
                <NumberInputContainer
                    className="coincraft-table-cell__text"
                    value={FormatNumber(value.value, { decimals: 2 })}
                    isEditable={isEditable}
                    onChange={(value) => onChange({ value })}
                />
            </div>
            <div
                className="coincraft-table-cell coincraft-table-cell--dropdown"
                style={{ width: '60%' }}
            >
                <Selector
                    selectedOption={value.unit || 'days'}
                    onChange={(unit) => onChange({ unit })}
                    options={['days', 'weeks', 'months']}
                    optionLabel={(o) => o}
                />
            </div>
        </div>
    )
}
