import { observer } from 'mobx-react'
import React, { useState } from 'react'
import SelectionList from './SelectionList'
import CostCentreCollection from '../../State/Collections/CostCentreCollection'
import TimesheetAppStore from './TimesheetAppStore'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import { canEnterTimeAgainstProject } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

export default observer(() => {
    return (
        <SelectionList
            title={'Cost Centre'}
            label={'name'}
            items={CostCentreCollection.costCentres.filter(
                (cc) =>
                    (
                        ProjectCollection.projectsByCostCentreId[cc.id] || []
                    ).filter((p) =>
                        canEnterTimeAgainstProject(SessionStore.user, p)
                    ).length
            )}
            backRoute={'listEntries'}
            onChange={(costCentre) => {
                TimesheetAppStore.selectCostCentre(costCentre)
                TimesheetAppStore.setRoute('selectProject')
            }}
        />
    )
})
