import { observer } from 'mobx-react'
import React, { useState } from 'react'
import RenderOnQueries from '../RenderOnQueries'
import SelectionList from './SelectionList'
import TimesheetAppStore from './TimesheetAppStore'
import { canEnterTimeAgainstPhase } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

export default observer(() => {
    return (
        <RenderOnQueries
            queryIds={[
                {
                    collection: 'phases',
                    fields: [
                        'jobNumber',
                        'name',
                        'projectId',
                        'status',
                        'startDate',
                        'isRootPhase',
                    ],
                    filters: [
                        `projectId == "${TimesheetAppStore.selectedProject.id}"`,
                    ],
                },
            ]}
        >
            <SelectionList
                title={'Phase'}
                label={'title'}
                sortBy={'startDate'}
                items={
                    TimesheetAppStore.selectedProject.phases.filter((p) =>
                        canEnterTimeAgainstPhase(SessionStore.user, p)
                    ) || []
                }
                backRoute={'selectProject'}
                onChange={(phase) => {
                    TimesheetAppStore.selectPhase(phase)
                    TimesheetAppStore.setRoute('selectTask')
                }}
            />
        </RenderOnQueries>
    )
})
