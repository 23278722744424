import { observer } from 'mobx-react'
import React, { useState } from 'react'
import RenderOnQueries from '../RenderOnQueries'
import SelectionList from './SelectionList'
import TimesheetAppStore from './TimesheetAppStore'

export default observer(() => {
    return (
        <RenderOnQueries
            queryIds={[
                {
                    collection: 'tasks',
                    fields: ['name', 'projectId', 'phaseId'],
                    filters: [
                        `phaseId == "${TimesheetAppStore.selectedPhase.id}"`,
                    ],
                },
            ]}
        >
            <SelectionList
                title={'Task'}
                label={'name'}
                items={TimesheetAppStore.selectedPhase.tasks || []}
                backRoute={'selectPhase'}
                onChange={(task) => {
                    TimesheetAppStore.selectTask(task)
                    TimesheetAppStore.createTimeEntry()
                    TimesheetAppStore.setRoute('listEntries')
                }}
            />
        </RenderOnQueries>
    )
})
