import localforage from 'localforage'
import { observable, computed, action, makeObservable, reaction } from 'mobx'
import { makeRequest } from '../Queries/makeRequest'
import {
    CollectionLookup,
    collections,
    autoSaveCollections,
} from './CollectionLookup'
import PhaseCollection from './Collections/PhaseCollection'
import SessionStore from './SessionStore'
import React from 'react'
import BudgetCollection from './Collections/BudgetCollection'
import cuid from 'cuid'
import ProjectExpenseCollection from './Collections/ProjectExpenseCollection'
import DateRangeDataStore from './DateRangeDataStore'
import BatchDataStore from './BatchDataStore'

class DataStore {
    @observable state = 'uninitialized' // fetching, success, error
    @observable rawData = {}
    @observable saveState = 'idle'
    @observable autosave = false
    @observable circularSaveAttempts = 0
    @observable idsToSave = new Set()
    constructor(props) {
        makeObservable(this)
        reaction(() => this.needsSaving, this.whenNeedsSaving)
        // autosave
        reaction(() => this.needsSaving, this.startAutosave)
    }
    @action.bound
    setAutosave(autosave) {
        this.autosave = autosave
    }
    @action.bound
    async startAutosave() {
        if (this.autosave) {
            this.startSave()
        }
    }
    @action.bound
    whenNeedsSaving() {
        if (this.needsSaving) {
            this.saveState = 'needsSaving'
        }
    }
    @computed
    get needsSaving() {
        return (
            autoSaveCollections.some((c) => c.needsSaving) ||
            DateRangeDataStore.needsSaving ||
            BatchDataStore.needsSaving
        )
    }
    @action.bound
    async retrySave() {
        this.saveState = 'idle'
        await this.startSave()
    }
    @action.bound
    async startSave() {
        if (
            this.needsSaving &&
            this.saveState !== 'saving' &&
            this.saveState !== 'error'
        ) {
            this.saveState = 'saving'
            const savedAt = Date.now()
            const saveData = {}
            const modals = {}
            try {
                const idsToSave = new Set(
                    autoSaveCollections
                        .map((c) => {
                            return c.modelsToSave.map((m) => m.id)
                        })
                        .flat()
                )

                if (
                    idsToSave.size === this.idsToSave.size &&
                    [...idsToSave].every((item) => this.idsToSave.has(item))
                ) {
                    if (this.circularSaveAttempts > 10) {
                        this.idsToSave = new Set()
                        throw new Error('Circular Save')
                        this.circularSaveAttempts = 0
                    } else {
                        this.circularSaveAttempts += 1
                        this.idsToSave = idsToSave
                    }
                } else {
                    this.idsToSave = idsToSave
                }

                autoSaveCollections.forEach((c) => {
                    if (c.needsSaving) {
                        saveData[c.collection] = c.modelsToSave
                            .map((m) => this.serializeModel(m))
                            .filter((d) => d)
                    }
                })

                const { data: newIds } = await makeRequest({
                    path: `api/v1.5/save`,
                    method: 'post',
                    data: {
                        ...saveData,
                    },
                })

                let replaceUrl = null
                Object.entries(newIds).forEach(([collection, idLookup]) => {
                    const c = CollectionLookup[collection]
                    Object.values(idLookup).forEach((id) => {
                        c.update(
                            id,
                            {},
                            {
                                trackUpdates: false,
                                savedAt: savedAt,
                            }
                        )
                    })
                })
                this.saveState = 'success'
                this.idsToSave = new Set()
                this.circularSaveAttempts = 0
                if (this.needsSaving) {
                    this.startSave()
                } else {
                    setTimeout(() => {
                        if (this.saveState === 'success')
                            this.saveState = 'idle'
                    }, 1000)
                }
                await BatchDataStore.startSave()
                await DateRangeDataStore.startSave()
                if (replaceUrl) {
                    //TODO - do a nicer version of this in the future
                    window.location.navigate(replaceUrl)
                }
            } catch (e) {
                console.error(e.message)
                this.saveState = 'error'
            }
        }
    }
    @action.bound
    serializeModel(model) {
        // if (!SessionStore.canEditModel(model))
        //     throw new Error('Cannot Edit Model')
        let data = model.serializeUpdates()
        // if (
        //     data.createdAt &&
        //     !SessionStore.canCreateInCollection(model.collection.collection)
        // )
        //     throw new Error('Cannot Create Model')
        // if (
        //     data.deletedAt &&
        //     !SessionStore.canDeleteInCollection(model.collection.collection)
        // )
        //     throw new Error('Cannot Delete Model')
        Object.keys(data).forEach((field) => {
            if (
                ['id', 'id', 'updatedAt', 'deletedAt', 'createdAt'].includes(
                    field
                )
            )
                return
            // if (!SessionStore.canEditField(model, field)) {
            //     delete data[field]
            // }
        })
        return data
    }
    @action.bound
    async saveModel(model) {
        this.saveState = 'saving'
        const savedAt = Date.now()
        const saveData = {}
        try {
            saveData[model.collection.collection] = [this.serializeModel(model)]
            const { data: newIds } = await makeRequest({
                path: `api/v1.5/save`,
                method: 'post',
                data: saveData,
            })
            !model.detached &&
                Object.entries(newIds).forEach(([collection, idLookup]) => {
                    const c = CollectionLookup[collection]
                    Object.values(idLookup).forEach((id) => {
                        c.update(
                            id,
                            {},
                            {
                                trackUpdates: false,
                                savedAt: savedAt,
                            }
                        )
                    })
                })
            this.saveState = 'success'
            if (this.needsSaving) {
                this.startSave()
            } else {
                setTimeout(() => {
                    if (this.saveState === 'success') this.saveState = 'idle'
                }, 1000)
            }
        } catch (e) {
            console.error(e.message)
            this.saveState = 'error'
        }
    }
}

export default new DataStore()

function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
