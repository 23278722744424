export default {
    name: 'New Report',
    type: 'project',
    columns: [
        'name',
        'fee',
        'revenue',
        'expenses',
        'actualVsBudgetedHours',
        'actualVsBudgetedExpenses',
        'revenueVsFee',
        'profit',
        'profitMargin',
        'latestRevenueDate',
        'recordedHours',
        // 'percentOfTotalHours',
        // 'status',
        // 'startDate',
        // 'endDate',
        // 'fee',
        // 'costCentre',
        // 'staffMembers',
        // 'revenue',
    ],
    filters: [
        {
            column: 'status',
            operator: 'in',
            value: ['active'],
            group: 'project',
        },
    ],
    // defaultGroup: 'phase',
    // groupBy: ['project'],
    sortBy: [['name', 'asc']],
    options: {
        dateRange: 'allTime',
    },
}
