import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { formatMinutes, parseTime, isNumber } from '../../utils.js'
import PropTypes from 'prop-types'

export const HoursInput = ({
    value,
    className,
    onChange,
    isEditable = true,
    style,
}) => {
    const [val, setVal] = useState(formatFunc(value))
    const inputRef = useRef(null)

    useEffect(() => {
        if (document.activeElement !== inputRef.current) {
            setVal(formatFunc(value))
        }
    }, [value])

    const handleChange = (event) => {
        let text = event.target.value
        setVal(text)

        let time = parseTime(text)
        onChange(time != null ? time : text)
    }

    const handleBlur = () => {
        let [time, isValid] = parseFunc(val)
        if (isValid) {
            setVal(formatFunc(time))
        }
    }

    const handleFocus = () => {
        // Select the text box's contents on focus. For some reason if we don't
        // do this in a $timeout the contents are momentarily selected and then
        // deselected.
        setTimeout(() => {
            let node = inputRef.current
            if (node === document.activeElement) {
                node.select()
            }
        })
    }

    const handleKeyUp = (event) => {
        let [currentVal, isValid] = parseFunc(val)

        if (isValid) {
            let newTime = null
            if (event.keyCode === 38) {
                // Up arrow: + 15 minutes
                newTime = currentVal + 15
            } else if (event.keyCode === 40) {
                // Down arrow: - 15 minutes
                if (currentVal >= 15) {
                    newTime = currentVal - 15
                }
            }
            if (newTime != null) {
                setVal(formatFunc(newTime))
                onChange(newTime)
            }
        }
    }

    let [_, isValid] = parseFunc(val)
    if (isEditable) {
        return (
            <input
                ref={inputRef}
                type="text"
                value={val}
                onKeyUp={handleKeyUp}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                className={classNames(className, {
                    classNameInvalid: !isValid,
                })}
                disabled={!isEditable}
                style={{ textAlign: 'right', ...style }}
            />
        )
    } else {
        return <span>{val}</span>
    }

    function formatFunc(n) {
        return isNumber(n) ? formatMinutes(n) : n
    }

    function parseFunc(text) {
        let time = parseTime(text)
        return [time, time != null]
    }
}

HoursInput.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number, // Valid state
        PropTypes.string, // Invalid state
    ]).isRequired,
    className: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    isEditable: PropTypes.bool,
    style: PropTypes.object,
}
