import { toJS } from 'mobx'
import { permissionLevels } from './PermissionStructure'
import { qf } from '../../Queries/queryFormatter'
import SessionStore from '../SessionStore'
import ProjectCollection from '../Collections/ProjectCollection'
import { permissionPresets } from './PermissionPresets'
import cuid from 'cuid'
export const hasPermission = (
    staff,
    permissions = {},
    options = { anyProject: false }
) => {
    const staffPermissions = staff?.permissions || {}
    for (const key in permissions) {
        const value = permissions[key]
        if (key.includes('project')) {
            const projectPermissions = staffPermissions.projects || [
                { filter: { type: 'all' }, permissions: {} },
            ]
            const projectIdPermissions = projectPermissions.filter(
                (p) => p.filter.type === 'project'
            )
            const costCentreIdPermissions = projectPermissions.filter(
                (p) => p.filter.type === 'costCentre'
            )
            const allProjectsPermissions = projectPermissions.filter(
                (p) => p.filter.type === 'all'
            )
            let projectPermissionFound = null

            // First check if we have a matching permission for a specific project
            for (const projPermission of projectIdPermissions) {
                if (
                    options.anyProject ||
                    projPermission.filter.values.includes(options.projectId)
                ) {
                    if (
                        typeof value === 'boolean'
                            ? (projPermission.permissions[key] || false) ===
                              value
                            : permissionLevels.indexOf(
                                  projPermission.permissions[key] || 'hidden'
                              ) >= permissionLevels.indexOf(value)
                    ) {
                        projectPermissionFound = true
                        break
                    } else if (!options.anyProject) {
                        projectPermissionFound = false
                        break
                    }
                }
            }

            // If we didn't find a matching permission for a specific project, check if we have a matching permission for a cost centre
            if (projectPermissionFound === null) {
                for (const projPermission of costCentreIdPermissions) {
                    if (
                        options.anyProject ||
                        projPermission.filter.values.includes(
                            options.costCentreId
                        )
                    ) {
                        if (
                            typeof value === 'boolean'
                                ? (projPermission.permissions[key] || false) ===
                                  value
                                : permissionLevels.indexOf(
                                      projPermission.permissions[key] ||
                                          'hidden'
                                  ) >= permissionLevels.indexOf(value)
                        ) {
                            projectPermissionFound = true
                            break
                        } else if (!options.anyProject) {
                            projectPermissionFound = false
                            break
                        }
                    }
                }
            }

            // If we didn't find a matching permission for a cost centre, check if we have a matching permission for any project
            if (projectPermissionFound === null) {
                for (const projPermission of allProjectsPermissions) {
                    if (
                        typeof value === 'boolean'
                            ? (projPermission.permissions[key] || false) ===
                              value
                            : permissionLevels.indexOf(
                                  projPermission.permissions[key] || 'hidden'
                              ) >= permissionLevels.indexOf(value)
                    ) {
                        projectPermissionFound = true
                        break
                    }
                }
            }

            // If we didn't find a matching permission for a project, return false
            if (!projectPermissionFound) {
                return false
            }
        } else if (typeof value === 'boolean') {
            if ((staffPermissions[key] || false) !== value) {
                return false
            }
        } else if (typeof value === 'string') {
            if (
                permissionLevels.indexOf(staffPermissions[key] || 'hidden') <
                permissionLevels.indexOf(value)
            ) {
                return false
            }
        } else {
            return false
        }
    }
    return true
}

export const hasProjectPermission = (staff, project, permissions) => {
    if (
        !permissions.projectPermissions &&
        Object.keys(permissions).some((key) => key !== 'projectTimesheets')
    ) {
        permissions.projectPermissions = 'view'
    }
    if (project) {
        return hasPermission(staff, permissions, {
            projectId: project.id,
            costCentreId: project.costCentreId,
        })
    } else {
        return hasAnyProjectPermission(staff, permissions)
    }
}

export const hasAnyProjectPermission = (staff, permissions) => {
    return hasPermission(staff, permissions, {
        anyProject: true,
    })
}

export const hasImpersonationPermission = (staff) => {
    return staff?.email === 'ryan@coincraft.co'
}

export const canViewRoute = (staff, routeId, params = {}) => {
    let project
    switch (routeId) {
        case 'revenueForecast':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    canViewRevenueForecast: true,
                })
            )
        case 'resourceSchedule':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    canViewResourceSchedule: true,
                })
            )
        case 'weeklyPlanning':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    canViewWeeklyPlanner: true,
                }) &&
                canEditStaffAllocations(staff)
            )
        case 'projectList':
            return (
                !isAccountLocked() &&
                hasAnyProjectPermission(staff, {
                    projectPermissions: 'view',
                })
            )
        case 'createProject':
            return (
                !isAccountLocked() &&
                hasAnyProjectPermission(staff, {
                    projectPermissions: 'admin',
                })
            )
        case 'project':
            project = ProjectCollection.projectsById[params.id]
            if (project) {
                return (
                    !isAccountLocked() && canViewProjectDetails(staff, project)
                )
            } else {
                return (
                    !isAccountLocked() &&
                    hasAnyProjectPermission(staff, {
                        projectPermissions: 'view',
                    })
                )
            }
        case 'project-details':
            project = ProjectCollection.projectsById[params.id]
            return !isAccountLocked() && canViewProjectDetails(staff, project)
        case 'project-forecasts':
            project = ProjectCollection.projectsById[params.id]
            return (
                !isAccountLocked() &&
                (canViewStaffAllocations(staff, project) ||
                    canViewRevenueTargets(staff, project))
            )
        case 'project-tasks':
            project = ProjectCollection.projectsById[params.id]
            return !isAccountLocked() && canViewPhasesAndTasks(staff, project)
        case 'project-changelog':
            project = ProjectCollection.projectsById[params.id]
            return (
                !isAccountLocked() &&
                (canViewProjectInvoices(staff, project) ||
                    canViewProjectExpenses(staff, project))
            )
        case 'project-rates':
            project = ProjectCollection.projectsById[params.id]
            return !isAccountLocked() && canViewProjectRates(staff, project)
        case 'project-notes':
            project = ProjectCollection.projectsById[params.id]
            return !isAccountLocked() && canViewProjectNotes(staff, project)
        case 'project-history':
            project = ProjectCollection.projectsById[params.id]
            return !isAccountLocked() && canEditProject(staff, project)
        case 'project-status':
            project = ProjectCollection.projectsById[params.id]
            return !isAccountLocked() && canViewProjectDates(staff, project)
        case 'staffList':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    staffPermissions: 'view',
                })
            )
        case 'staff':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    staffPermissions: 'view',
                })
            )
        case 'staff-details':
            return !isAccountLocked() && canViewStaff(staff)
        case 'staff-rates':
            return (
                !isAccountLocked() &&
                (canViewStaffPayRate(staff) ||
                    canViewStaffOvertimeRate(staff) ||
                    canViewStaffCostRate(staff) ||
                    canViewStaffChargeOutRate(staff))
            )
        case 'staff-permissions':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    staffPermissions: 'admin',
                })
            )
        case 'staffRoleList':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    rolePermissions: 'view',
                })
            )
        case 'staffRole':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    rolePermissions: 'view',
                })
            )
        case 'createStaff':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    staffPermissions: 'admin',
                })
            )
        case 'invoiceList':
            return (
                !isAccountLocked() &&
                hasAnyProjectPermission(staff, {
                    projectInvoices: 'view',
                })
            )
        case 'invoicePdf':
            return (
                !isAccountLocked() &&
                hasAnyProjectPermission(staff, {
                    projectInvoices: 'view',
                })
            )
        case 'invoice':
            return (
                !isAccountLocked() &&
                hasAnyProjectPermission(staff, {
                    projectInvoices: 'view',
                })
            )
        case 'invoiceTemplates':
            return (
                !isAccountLocked() &&
                hasAnyProjectPermission(staff, {
                    projectInvoices: 'admin',
                })
            )
        case 'invoiceSettings':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    invoiceSettings: 'edit',
                })
            )
        case 'createInvoice':
            return (
                !isAccountLocked() &&
                hasAnyProjectPermission(staff, {
                    projectInvoices: 'admin',
                })
            )
        case 'timesheets':
            return (
                !isAccountLocked() &&
                hasAnyProjectPermission(staff, {
                    projectTimesheets: true,
                })
            )
        case 'timesheets-daily':
            return !isAccountLocked()
        case 'timesheets-weekly':
            return !isAccountLocked()
        case 'timesheetCalendar':
            return (
                !isAccountLocked() &&
                hasAnyProjectPermission(staff, {
                    projectTimesheets: true,
                })
            )
        case 'timesheetReports':
            return (
                !isAccountLocked() &&
                hasAnyProjectPermission(staff, {
                    projectTimesheets: true,
                })
            )
        case 'overheadExpenses':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    operationalExpenses: 'edit',
                })
            )
        case 'costCentres':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    costCentres: 'edit',
                })
            )
        case 'organisationHolidays':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    holidays: 'edit',
                })
            )
        case 'contacts':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    contacts: 'view',
                })
            )
        case 'contact':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    contacts: 'view',
                })
            )
        case 'settings':
            return (
                !isAccountLocked() &&
                hasPermission(staff, {
                    organisationSettings: 'edit',
                })
            )
        case 'trackExpenses':
            return canViewExpenseTracking()
        case 'expenseReports':
            return canViewExpenseTracking()
        case 'suppliers':
            return canViewExpenseTracking()
        case 'login':
            return true
        case 'register':
            return true
        case 'registerDetails':
            return true
        case 'invite':
            return true
        case 'activateAccount':
            return true
        case 'forgottenPassword':
            return true
        case 'resetPassword':
            return true
        case 'accountingAuth':
            return true
        case 'billing':
            return hasPermission(staff, {
                billing: 'edit',
            })
        case 'impersonate':
            return hasImpersonationPermission(staff)
        case 'migrate':
            return true
        case 'feedbackAuth':
            return true
        default:
            return false
    }
}

export const canViewRevenueTargets = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectForecastedRevenue: 'view',
    })
}

export const canEditRevenueTargets = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectForecastedRevenue: 'edit',
    })
}

export const canViewStaffAllocations = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectForecastedHours: 'view',
    })
}
export const canEditStaffAllocations = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectForecastedHours: 'edit',
    })
}

export const canViewProjectDetails = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectPermissions: 'view',
    })
}

export const canCreateProjects = (staff) => {
    return hasAnyProjectPermission(staff, {
        projectPermissions: 'admin',
    })
}

export const canDeleteProject = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectPermissions: 'admin',
    })
}

export const canEditProject = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectPermissions: 'edit',
    })
}

export const canViewProjectDates = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectDates: 'view',
    })
}

export const canEditProjectDates = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectDates: 'edit',
    })
}

export const canViewProjectFees = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectFees: 'view',
    })
}

export const canEditProjectFees = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectFees: 'edit',
    })
}

export const canViewProjectExpenseBudgets = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectExpenseBudgets: 'view',
    })
}

export const canEditProjectExpenseBudgets = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectExpenseBudgets: 'edit',
    })
}

export const canEditProjectHoursBudgets = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectHoursBudgets: 'edit',
    })
}

export const canViewProjectInvoices = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectInvoices: 'view',
    })
}

export const canEditProjectInvoices = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectInvoices: 'edit',
    })
}

export const canCreateInvoices = (staff) => {
    return hasAnyProjectPermission(staff, {
        projectInvoices: 'admin',
    })
}

export const canCreateInvoiceForProject = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectInvoices: 'admin',
    })
}

export const canEditPhasesAndTasks = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectPhasesTasks: 'admin',
    })
}
export const canViewPhasesAndTasks = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectPhasesTasks: 'view',
    })
}

export const canViewProjectStaffCost = (staff, project) => {
    return (
        hasProjectPermission(staff, project, {
            projectExpenses: 'view',
        }) && canViewStaffCostRate(staff)
    )
}

export const canViewProjectStaffPay = (staff, project) => {
    return (
        hasProjectPermission(staff, project, {
            projectExpenses: 'view',
        }) && canViewStaffPayRate(staff)
    )
}

export const canViewProjectExpenses = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectExpenses: 'view',
    })
}

export const canEditProjectExpenses = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectExpenses: 'edit',
    })
}

export const canViewProjectStaffChargeOut = (staff, project) => {
    return (
        hasProjectPermission(staff, project, {
            projectFees: 'view',
        }) && canViewStaffChargeOutRate(staff)
    )
}

export const canEditStaffTime = (staff) => {
    return hasPermission(staff, {
        editStaffTime: true,
    })
}

export const canViewCostCentres = (staff) => {
    return hasPermission(staff, {
        costCentres: 'view',
    })
}

export const canEditCostCentres = (staff) => {
    return hasPermission(staff, {
        costCentres: 'edit',
    })
}

export const canViewContacts = (staff) => {
    return hasPermission(staff, {
        contacts: 'view',
    })
}

export const canEditContacts = (staff) => {
    return hasPermission(staff, {
        contacts: 'edit',
    })
}

export const canViewProjectNotes = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectNotes: 'view',
    })
}

export const canEditProjectNotes = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectNotes: 'edit',
    })
}

export const canViewProjectStaffBudgets = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectStaffBudgets: 'view',
    })
}

export const canEditProjectStaffBudgets = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectStaffBudgets: 'edit',
    })
}

export const canViewStaffPayRate = (staff) => {
    return hasPermission(staff, {
        payRate: 'view',
    })
}

export const canViewStaffOvertimeRate = (staff) => {
    return hasPermission(staff, {
        overtimeRate: 'view',
    })
}

export const canViewStaffCostRate = (staff) => {
    return hasPermission(staff, {
        costRate: 'view',
    })
}

export const canViewStaffChargeOutRate = (staff) => {
    return hasPermission(staff, {
        chargeOutRate: 'view',
    })
}

export const canEditStaffPayRate = (staff) => {
    return hasPermission(staff, {
        payRate: 'edit',
    })
}

export const canEditStaffOvertimeRate = (staff) => {
    return hasPermission(staff, {
        overtimeRate: 'edit',
    })
}

export const canEditStaffCostRate = (staff) => {
    return hasPermission(staff, {
        costRate: 'edit',
    })
}

export const canEditStaffChargeOutRate = (staff) => {
    return hasPermission(staff, {
        chargeOutRate: 'edit',
    })
}

export const canViewProjectRates = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectRates: 'view',
    })
}

export const canEditProjectRates = (staff, project) => {
    return hasProjectPermission(staff, project, {
        projectRates: 'edit',
    })
}

export const canViewBilling = (staff) => {
    return hasPermission(staff, {
        billing: 'edit',
    })
}

export const canViewStaff = (staff) => {
    return hasPermission(staff, {
        staffPermissions: 'view',
    })
}

export const canEditStaff = (staff) => {
    return hasPermission(staff, {
        staffPermissions: 'edit',
    })
}

export const canCreateStaff = (staff) => {
    return hasPermission(staff, {
        staffPermissions: 'admin',
    })
}

export const canDeleteStaff = (staff) => {
    return hasPermission(staff, {
        staffPermissions: 'admin',
    })
}

export const canViewRoles = (staff) => {
    return hasPermission(staff, {
        rolePermissions: 'view',
    })
}

export const canEditRoles = (staff) => {
    return hasPermission(staff, {
        rolePermissions: 'edit',
    })
}

export const canCreateRoles = (staff) => {
    return hasPermission(staff, {
        rolePermissions: 'admin',
    })
}

export const canDeleteRoles = (staff) => {
    return hasPermission(staff, {
        rolePermissions: 'admin',
    })
}

export const canViewSettings = (staff) => {
    return hasPermission(staff, {
        organisationSettings: 'view',
    })
}

export const canViewInvoiceSettings = (staff) => {
    return hasPermission(staff, {
        invoiceSettings: 'view',
    })
}

export const canEditInvoiceSettings = (staff) => {
    return hasPermission(staff, {
        invoiceSettings: 'edit',
    })
}

export const canEnterTimeAgainstProject = (staff, project) => {
    return (
        hasProjectPermission(staff, project, {
            projectTimesheets: true,
        }) && canEnterTimeAgainstPhase(staff, project)
    )
}

export const canViewOverheadExpenses = (staff) => {
    return hasPermission(staff, {
        operationalExpenses: 'view',
    })
}

export const canEditOverheadExpenses = (staff) => {
    return hasPermission(staff, {
        operationalExpenses: 'edit',
    })
}

export const canViewHolidays = (staff) => {
    return hasPermission(staff, {
        holidays: 'view',
    })
}

export const canEditHolidays = (staff) => {
    return hasPermission(staff, {
        holidays: 'edit',
    })
}

export const canViewRevenueForecast = (staff) => {
    return hasPermission(staff, {
        canViewRevenueForecast: true,
    })
}

export const canViewRevenueForecastStaffExpenses = (staff) => {
    return hasPermission(staff, {
        canViewStaffExpenses: true,
    })
}

export const canViewRevenueForecastOperationalExpenses = (staff) => {
    return hasPermission(staff, {
        canViewOperationalExpenses: true,
    })
}

export const canViewResourceSchedule = (staff) => {
    return hasPermission(staff, {
        canViewResourceSchedule: true,
    })
}

export const canViewWeeklyPlanner = (staff) => {
    return hasPermission(staff, {
        canViewWeeklyPlanner: true,
    })
}

export const getQueryPermissionFilters = (
    collection,
    staff,
    strict = false
) => {
    switch (collection) {
        case 'budgetedHours':
            return visibleProjectFilters(
                staff,
                strict
                    ? { projectPermissions: 'view' }
                    : { projectTimesheets: true },
                'project.'
            )
        case 'changeLogItems':
            return visibleProjectFilters(
                staff,
                { projectPermissions: 'view', projectInvoices: 'view' },
                'project.'
            )
        case 'contacts':
            return hasPermission(staff, {
                contacts: 'view',
            })
                ? []
                : [`id in ${qf([])}`]
        case 'costCentres':
            return []
        case 'dailyAllocations':
            return visibleProjectFilters(
                staff,
                strict
                    ? {
                          projectStaffBudgets: 'view',
                          projectForecastedHours: 'view',
                      }
                    : { projectTimesheets: true },
                'project.'
            )
        case 'allocations':
            return visibleProjectFilters(
                staff,
                strict
                    ? { projectPermissions: 'view' }
                    : { projectTimesheets: true },
                'project.'
            )
        case 'invoices':
            return visibleProjectFilters(
                staff,
                { projectPermissions: 'view', projectInvoices: 'view' },
                'project.'
            )
        case 'invoiceDescriptionTemplates':
            return hasAnyProjectPermission(staff, {
                projectInvoices: 'view',
            })
                ? []
                : [`id in ${qf([])}`]
        case 'invoiceLineItems':
            return visibleProjectFilters(
                staff,
                { projectPermissions: 'view', projectInvoices: 'view' },
                'project.'
            )
        case 'organisation':
            return []
        case 'organisationHolidays':
            return hasPermission(staff, {
                holidays: 'view',
            })
                ? []
                : [`id in ${qf([])}`]
        case 'organisationSubscriptions':
            return []
        case 'overheadExpenses':
            return hasPermission(staff, {
                operationalExpenses: 'view',
            })
                ? []
                : [`id in ${qf([])}`]
        case 'phases':
            return visibleProjectFilters(
                staff,
                strict
                    ? { projectPermissions: 'view' }
                    : { projectTimesheets: true },
                'project.'
            )
        case 'projects':
            return visibleProjectFilters(
                staff,
                strict
                    ? { projectPermissions: 'view' }
                    : { projectTimesheets: true }
            )
        case 'projectExpenseAllocations':
            return visibleProjectFilters(
                staff,
                {
                    projectPermissions: 'view',
                    projectExpenses: 'view',
                },
                'project.'
            )
        case 'projectExpenses':
            return visibleProjectFilters(
                staff,
                {
                    projectPermissions: 'view',
                    projectExpenses: 'view',
                },
                'project.'
            )
        case 'projectNotes':
            return visibleProjectFilters(
                staff,
                {
                    projectPermissions: 'view',
                    projectNotes: 'view',
                },
                'project.'
            )
        case 'projectRates':
            return visibleProjectFilters(
                staff,
                { projectPermissions: 'view', projectRates: 'view' },
                'project.'
            )
        case 'reports':
            return []
        case 'resourceScheduleReports':
            return hasPermission(staff, {
                canViewResourceSchedule: true,
            })
                ? []
                : [`id in ${qf([])}`]
        case 'revenueForecastReports':
            return hasPermission(staff, {
                canViewRevenueForecast: true,
            })
                ? []
                : [`id in ${qf([])}`]
        case 'revenueTargets':
            return visibleProjectFilters(
                staff,
                {
                    projectPermissions: 'view',
                    projectForecastedRevenue: 'view',
                },
                'project.'
            )
        case 'roles':
            return hasPermission(staff, {
                rolePermissions: 'view',
            })
                ? []
                : [`id in ${qf([])}`]
        case 'roleRates':
            return hasPermission(staff, {
                payRate: 'view',
            }) ||
                hasPermission(staff, {
                    overtimeRate: 'view',
                }) ||
                hasPermission(staff, {
                    costRate: 'view',
                }) ||
                hasPermission(staff, {
                    chargeOutRate: 'view',
                })
                ? []
                : [`id in ${qf([])}`]
        case 'staff':
            return []
        case 'staffRates':
            return hasPermission(staff, {
                payRate: 'view',
            }) ||
                hasPermission(staff, {
                    overtimeRate: 'view',
                }) ||
                hasPermission(staff, {
                    costRate: 'view',
                }) ||
                hasPermission(staff, {
                    chargeOutRate: 'view',
                })
                ? []
                : [`id in ${qf([])}`]
        case 'tasks':
            return visibleProjectFilters(
                staff,
                strict
                    ? { projectPermissions: 'view' }
                    : { projectTimesheets: true },
                'project.'
            )
        case 'timeEntries':
            return [
                ...visibleProjectFilters(
                    staff,
                    // strict
                    //     ? { projectPermissions: 'view' }
                    { projectTimesheets: true },
                    'project.',
                    { projectHoursBudgets: 'view' }
                ),
                ...(strict && !canViewProjectStaffBudgets(staff)
                    ? [`staffId == ${qf(staff.id)}`]
                    : []),
            ]
        case 'revenueRows':
            return visibleProjectFilters(
                staff,
                { projectForecastedRevenue: 'view', projectInvoices: 'view' },
                'project.'
            )
        case 'monthlyRevenueCells':
            return visibleProjectFilters(
                staff,
                { projectForecastedRevenue: 'view', projectInvoices: 'view' },
                'project.'
            )
        case 'resourceRows':
            return visibleProjectFilters(
                staff,
                { projectStaffBudgets: 'view', projectForecastedHours: 'view' },
                'project.'
            )
        case 'monthlyResourceCells':
            return visibleProjectFilters(
                staff,
                { projectStaffBudgets: 'view', projectForecastedHours: 'view' },
                'project.'
            )
        default:
            return []
    }
}

export const visibleProjectFilters = (
    staff,
    permissions = { projectPermissions: 'view' },
    prefix = '',
    staffPermissions = null
) => {
    const viewAllProjects = hasPermission(staff, permissions)
    const viewCostCentreIdsSelf = []
    const viewCostCentreIdsOtherStaff = []
    const hiddenCostCentreIds = []
    const viewProjectIdsSelf = []
    const viewProjectIdsOtherStaff = []
    const hiddenProjectIds = []
    staff.permissions.projects.forEach((p) => {
        if (p.filter.type === 'costCentre') {
            if (
                Object.entries(permissions).every(
                    ([key, value]) =>
                        p.permissions[key] === value ||
                        permissionLevels.indexOf(p.permissions[key]) >=
                            permissionLevels.indexOf(value)
                )
            ) {
                if (
                    !staffPermissions ||
                    Object.entries(staffPermissions).every(
                        ([key, value]) =>
                            p.permissions[key] === value ||
                            permissionLevels.indexOf(p.permissions[key]) >=
                                permissionLevels.indexOf(value)
                    )
                ) {
                    viewCostCentreIdsOtherStaff.push(...p.filter.values)
                } else {
                    viewCostCentreIdsSelf.push(...p.filter.values)
                }
            } else {
                hiddenCostCentreIds.push(...p.filter.values)
            }
        } else if (p.filter.type === 'project') {
            if (
                Object.entries(permissions).every(
                    ([key, value]) =>
                        p.permissions[key] === value ||
                        permissionLevels.indexOf(p.permissions[key]) >=
                            permissionLevels.indexOf(value)
                )
            ) {
                if (
                    !staffPermissions ||
                    Object.entries(staffPermissions).every(
                        ([key, value]) =>
                            p.permissions[key] === value ||
                            permissionLevels.indexOf(p.permissions[key]) >=
                                permissionLevels.indexOf(value)
                    )
                ) {
                    viewProjectIdsOtherStaff.push(...p.filter.values)
                } else {
                    viewProjectIdsSelf.push(...p.filter.values)
                }
            } else {
                hiddenProjectIds.push(...p.filter.values)
            }
        }
    })
    let filters = []
    if (viewAllProjects) {
        // Basic case: Exclude hidden projects
        if (hiddenProjectIds.length) {
            filters.push(`not(${prefix}id in (${qf(hiddenProjectIds)}))`)
        }

        // More complex case: Exclude hidden cost centres, but include any explicit project IDs
        if (hiddenCostCentreIds.length) {
            if (viewProjectIdsOtherStaff.length) {
                filters.push(
                    `(not(${prefix}costCentreId in (${qf(
                        hiddenCostCentreIds
                    )})) or ${prefix}id in (${qf(viewProjectIdsOtherStaff)}))`
                )
            } else if (viewProjectIdsSelf.length) {
                filters.push(
                    `(not(${prefix}costCentreId in (${qf(
                        hiddenCostCentreIds
                    )})) or (${prefix}id in (${qf(
                        viewProjectIdsOtherStaff
                    )}) and staffId == ${qf(staff.id)})`
                )
            } else {
                filters.push(
                    `not(${prefix}costCentreId in (${qf(hiddenCostCentreIds)}))`
                )
            }
        } else if (viewProjectIdsOtherStaff.length && filters.length) {
            filters.push(`${prefix}id in (${qf(viewProjectIdsOtherStaff)})`)
        } else if (viewProjectIdsSelf.length && filters.length) {
            filters.push(
                `(${prefix}id in (${qf(
                    viewProjectIdsSelf
                )}) and staffId == ${qf(staff.id)})`
            )
        }
    } else {
        if (
            viewCostCentreIdsSelf.length ||
            viewCostCentreIdsOtherStaff.length ||
            viewProjectIdsSelf.length ||
            viewProjectIdsOtherStaff.length
        ) {
            // Only include projects/cost centres explicitly allowed
            const explicitViewFilters = []
            if (viewCostCentreIdsOtherStaff.length) {
                explicitViewFilters.push(
                    `${prefix}costCentreId IN (${qf(
                        viewCostCentreIdsOtherStaff
                    )})`
                )
            }
            if (viewProjectIdsOtherStaff.length) {
                explicitViewFilters.push(
                    `${prefix}id IN (${qf(viewProjectIdsOtherStaff)})`
                )
            }
            if (viewCostCentreIdsSelf.length) {
                explicitViewFilters.push(
                    `(${prefix}costCentreId IN (${qf(
                        viewCostCentreIdsSelf
                    )}) and staffId == ${qf(staff.id)})`
                )
            }
            if (viewProjectIdsSelf.length) {
                explicitViewFilters.push(
                    `(${prefix}id IN (${qf(
                        viewProjectIdsSelf
                    )}) and staffId == ${qf(staff.id)})`
                )
            }
            filters.push(`(${explicitViewFilters.join(' OR ')})`)
        } else {
            // If there are no explicitly allowed projects or cost centers, the user shouldn't be able to see any project.
            filters.push(`${prefix}id in ${qf([])}`) // This is a false condition in SQL, which will result in no rows being returned.
        }
    }

    return filters
}

export const hasPermissionsForAllProjects = (staff) => {
    return staff.permissions.projects.some((p) => p.filter.type === 'all')
}

export const getAllPermissionProjectIds = (staff) => {
    return staff.permissions.projects
        .filter((p) => p.filter.type === 'project')
        .flatMap((p) => p.filter.values)
}

export const getAllPermissionCostCentreIds = (staff) => {
    return staff.permissions.projects
        .filter((p) => p.filter.type === 'costCentre')
        .flatMap((p) => p.filter.values)
}

export const addProjectPermission = (staff) => {
    staff.permissions.projects.push({
        filter: !hasPermissionsForAllProjects(staff)
            ? { type: 'all' }
            : { type: 'project', values: [] },
        id: cuid(),
        permissions: {
            ...permissionPresets.timesheet.projects[0].permissions,
        },
    })
    staff.update({
        permissions: { ...staff.permissions },
    })
}

export const updateProjectPermission = (staff, id, permissions) => {
    const projectPermission = staff.permissions.projects.find(
        (p) => p.id === id
    )
    if (projectPermission) {
        projectPermission.permissions = {
            ...projectPermission.permissions,
            ...permissions,
        }
    }
    staff.update({
        permissions: { ...staff.permissions },
    })
}

export const updateProjectPermissionFilter = (staff, id, filter) => {
    const projectPermission = staff.permissions.projects.find(
        (p) => p.id === id
    )
    if (projectPermission) {
        projectPermission.filter = filter ?? projectPermission.filter
    }
    staff.update({
        permissions: { ...staff.permissions },
    })
}

export const deleteProjectPermission = (staff, id) => {
    staff.permissions.projects = staff.permissions.projects.filter(
        (p) => p.id !== id
    )
    staff.update({
        permissions: { ...staff.permissions },
    })
}

export const updateStaffPermission = (staff, permissions) => {
    staff.update({
        permissions: {
            ...staff.permissions,
            ...permissions,
        },
    })
}

export const canSeeFeesAndRevenue = (staff) => {
    return (
        canViewProjectFees(staff) ||
        canViewRevenueTargets(staff) ||
        canViewProjectInvoices(staff) ||
        canViewStaffChargeOutRate(staff)
    )
}

export const canSeeExpenses = (staff) => {
    return (
        canViewProjectExpenses(staff) ||
        canViewProjectExpenseBudgets(staff) ||
        canViewStaffCostRate(staff)
    )
}

export const canSeeSalaries = (staff) => {
    return canViewStaffPayRate(staff) || canViewStaffOvertimeRate(staff)
}

export const canSeeOperationalExpenses = (staff) => {
    return canViewOverheadExpenses(staff)
}

export const canLogTimeAgainstNoPhase = () => {
    return SessionStore.settings?.allowNoPhase
}

export const canLogTimeAgainstTasks = () => {
    return SessionStore.settings?.useTasks
}

export const hasPhaseDatePermissions = (phase) => {
    return (
        SessionStore.settings?.allowAfterPhaseEnd ||
        !phase.endDate ||
        phase.endDate >= new Date()
    )
}

export const hasPhaseStatusPermissions = (phase) => {
    return SessionStore.settings?.timeEntryStatus.includes(phase.status)
}

export const canUseTimeEntryFlag = (flag) => {
    if (['flexi', 'remote'].includes(flag)) {
        return (
            canViewFlexiRemote() &&
            SessionStore.settings?.timeEntryFlags.includes(flag)
        )
    }
    return SessionStore.settings?.timeEntryFlags.includes(flag)
}

const phaseHasStaffBudgetedHours = (staff, phase) => {
    return (phase.budgetedStaffIds || []).includes(staff.id)
}

const phaseHasStaffAllocatedHours = (staff, phase) => {
    return (phase.monthlyAllocatedStaffIds || []).includes(staff.id)
}

export const hasPhaseBudgetPermissions = (staff, phase) => {
    return (
        SessionStore.settings?.timeEntryAllocations.includes('noAllocations') ||
        (SessionStore.settings?.timeEntryAllocations.includes('budgets') &&
            phaseHasStaffBudgetedHours(staff, phase)) ||
        (SessionStore.settings?.timeEntryAllocations.includes('allocations') &&
            phaseHasStaffAllocatedHours(staff, phase))
    )
}

export const canEnterTimeAgainstPhase = (staff, phase) => {
    return (
        isMasterAdmin(staff) ||
        (!phase?.isRootPhase &&
            hasPhaseDatePermissions(phase) &&
            hasPhaseStatusPermissions(phase) &&
            hasPhaseBudgetPermissions(staff, phase)) ||
        (canLogTimeAgainstNoPhase() && phase?.isRootPhase)
    )
}

export const isMasterAdmin = (staff) => {
    return (
        canSeeSalaries(staff) &&
        canSeeOperationalExpenses(staff) &&
        canViewBilling(staff) &&
        canViewSettings(staff)
    )
}

export const autoPopulatePhaseInTimesheets = (staff, phase) => {
    return (
        (SessionStore.settings?.autoPopulate.includes('budgets') &&
            phaseHasStaffBudgetedHours(staff, phase)) ||
        (SessionStore.settings?.autoPopulate.includes('allocations') &&
            phaseHasStaffAllocatedHours(staff, phase))
    )
}

export const shouldAutoPopulatePhasesInTimesheets = () => {
    return (
        SessionStore.settings?.autoPopulate.includes('budgets') ||
        SessionStore.settings?.autoPopulate.includes('allocations')
    )
}

export const shouldUpdateHoursFromRevenue = () => {
    return SessionStore.settings?.updateHoursFromRevenue
}

export const shouldUpdateRevenueFromHours = () => {
    return SessionStore.settings?.updateRevenueFromHours
}

export const shouldOpenUpdateForecastModal = () => {
    return (
        SessionStore.settings.autoUpdateRevenue.action === 'ask' ||
        SessionStore.settings.autoUpdateHours.action === 'ask'
    )
}

export const shouldOpenUpdateForecastModalForRevenue = () => {
    return SessionStore.settings.autoUpdateRevenue.action === 'ask'
}

export const shouldOpenUpdateForecastModalForResources = () => {
    return SessionStore.settings.autoUpdateHours.action === 'ask'
}

export const neverAdjustForecasts = () => {
    return neverUpdateHours() && neverUpdateRevenue()
}

export const alwaysUpdateRevenue = () => {
    return SessionStore.settings.autoUpdateRevenue.action === 'automatic'
}

export const alwaysUpdateHours = () => {
    return SessionStore.settings.autoUpdateHours.action === 'automatic'
}

export const neverUpdateRevenue = () => {
    return SessionStore.settings.autoUpdateRevenue.action === 'never'
}

export const neverUpdateHours = () => {
    return SessionStore.settings.autoUpdateHours.action === 'never'
}

export const autoUpdateRevenueForecast = () => {
    return SessionStore.settings.autoUpdateRevenue.adjustOnLoad === true
}

export const autoUpdateResourceSchedule = () => {
    return SessionStore.settings.autoUpdateHours.adjustOnLoad === true
}

export const openInvoiceTimeEntryModal = () => {
    return !SessionStore.settings.savingInvoices.includes('automatic')
}

export const canViewExpenseTracking = () => {
    return [
        'f6fb558a-31eb-42cf-82fd-bd44d39207eb',
        '6ec3e97e-8778-4cfc-866e-710f7bd497ad',
        'b3a487aa-de6d-4d7d-b065-f8042ade67e8',
        '886a20ba-bfd9-4479-b7d7-04b73bcb7314',
        '6f24b71a-6ec5-47d5-a2cc-818a6811c1b3',
    ].includes(SessionStore.organisation?.id)
}

export const canViewSuppliers = () => {
    return true
}

export const canEditSuppliers = () => {
    return true
}

export const canEditStaffExpenses = () => {
    return true
}

export const canTrackExpenseAgainstProject = () => {
    return true
}

export const canTrackExpenseAgainstPhase = () => {
    return true
}

export const canTrackExpenseAgainstGeneral = () => {
    return true
}

export const getDefaultTasks = () => {
    if (
        SessionStore.organisation.id === '7d729342-8401-4c32-82c8-d6b156469fdc'
    ) {
        return [
            {
                name: 'Additional services',
                isBillable: true,
                isVariation: true,
            },
            {
                name: 'Non-billable',
                isBillable: false,
                isVariation: false,
            },
            {
                name: 'STUDIO Review',
                isBillable: false,
                isVariation: false,
            },
        ]
    } else {
        return []
    }
}

export const canViewPrimaryContact = () => {
    return [
        'f6fb558a-31eb-42cf-82fd-bd44d39207eb',
        '97a4070d-0bc0-4a1a-b907-b4e3f612a70c',
        '9d7b8c8f-ada1-4ae7-a3ed-d79d538077b4',
    ].includes(SessionStore.organisation.id)
}

export const canViewFlexiRemote = () => {
    return [
        'f6fb558a-31eb-42cf-82fd-bd44d39207eb',
        '7d9cd6d4-d57c-4210-a9a0-3a9c37263ff2',
    ].includes(SessionStore.organisation.id)
}

export const isAccountLocked = () => {
    return SessionStore.subscription?.isAccountLocked
}

export const migratedToV2 = () => {
    return SessionStore.migratedToV2
}
