import { makeRequest } from './makeRequest'
import { format } from 'date-fns'

export const getPreviousWeekTime = async (staffId, beforeDate = new Date()) => {
    let r = await makeRequest({
        path: `api/v1.5/previous-time/weekly`,
        method: 'post',
        data: {
            staffId,
            beforeDate: format(beforeDate, 'yyyy-MM-dd'),
        },
    })
    return r.data?.timeEntries || []
}
export const getPreviousDayTime = async (staffId, beforeDate = new Date()) => {
    let r = await makeRequest({
        path: `api/v1.5/previous-time/daily`,
        method: 'post',
        data: {
            staffId,
            beforeDate: format(beforeDate, 'yyyy-MM-dd'),
        },
    })
    return r.data?.timeEntries || []
}
