import React from 'react'

export default ({ text }) => {
    return (
        <div className="flex flex-align-items-center flex-justify-content-center">
            <div
                className="flex-0-0-auto"
                style={{
                    backgroundColor: 'white',
                    textAlign: 'center',
                    alignSelf: 'center',
                    padding: '4em 6.5em',
                    opacity: 0.9,
                }}
            >
                <div>
                    <i
                        className="fa fa-circle-o-notch fa-spin fa-3x fa-fw coincraft-gold"
                        style={{ marginRight: 0 }}
                    />
                    {text ? (
                        <h5
                            style={{
                                marginTop: '0.75em',
                                marginBottom: 0,
                                color: '#333',
                            }}
                        >
                            {text}
                        </h5>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
