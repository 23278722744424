import { observable, computed, action, makeObservable } from 'mobx'
import React from 'react'
import _ from 'lodash'
import cuid from 'cuid'
import { computedFn } from 'mobx-utils'
import Filters from './Filters'
import is from '@sindresorhus/is'

class TableCellStore {
    id = cuid()
    table = {}
    row = {}
    column = {}

    constructor({ table, row, column }) {
        makeObservable(this)
        this.table = table ?? this.table
        this.row = row ?? this.row
        this.column = column ?? this.column
    }
    @computed
    get stores() {
        return {
            cell: this,
            row: this.row,
            column: this.column,
            table: this.table,
        }
    }
    @computed
    get rowObject() {
        return this.row.rowObject
    }
    @computed
    get group() {
        return this.row.group
    }
    @computed
    get type() {
        return this.column.type
    }
    @computed
    get data() {
        return this.column.data
    }
    @computed
    get editable() {
        return (
            this.rowObject && this.column.editable(this.rowObject, this.stores)
        )
    }
    @computed
    get onChange() {
        return this.column.onChange?.(this.rowObject, this.stores)
    }
    @computed
    get onClick() {
        return this.column.onClick?.(this.rowObject, this.stores)
    }
    @computed
    get onFocus() {
        return this.column.onFocus?.(this.rowObject, this.stores)
    }
    @computed
    get onBlur() {
        return this.column.onBlur?.(this.rowObject, this.stores)
    }
    @computed
    get value() {
        if (!this.hasPermission) return null
        const getBestValue = (values) => {
            let sortValue = null
            let bestValue = null
            for (let v of values) {
                if (v) {
                    sortValue = this.column.sortComparator(
                        v?.[0] || v,
                        this.stores
                    )
                    bestValue = v
                    break
                }
            }
            if (
                !sortValue ||
                (typeof sortValue === 'number' && !isFinite(sortValue))
            ) {
                for (let v of values) {
                    let sv = this.column.sortComparator(
                        v?.[0] || v,
                        this.stores
                    )
                    if (sv !== null && sv !== undefined && isFinite(sv)) {
                        sortValue = sv
                        bestValue = v
                        break
                    }
                }
            }

            return bestValue
        }
        const value = getBestValue([
            tryOrNull(() => this.column.value(this.rowObject, this.stores)),
            this.rowObject[this.column.id],
            this.row.childRows.length
                ? this.column.aggregator(
                      this.row.descendants.map(
                          (d) => d.cells[this.column.id].value
                      ),
                      this.stores
                  )
                : null,
        ])
        return is.array(value) && value.length === 1
            ? value[0]
            : is.array(value) && value.length === 0
            ? null
            : value
    }
    @computed
    get formattedValue() {
        if (is.array(this.value)) {
            return this.column.groupFormatter(this.value, this.stores)
        } else {
            return this.column.formatter(this.value, this.stores)
        }
    }
    @computed
    get formattedCSVValue() {
        if (is.array(this.value)) {
            return this.column.csvGroupFormatter(this.value, this.stores)
        } else {
            return this.column.csvFormatter(this.value, this.stores)
        }
    }
    @computed
    get equalityComparatorValue() {
        let value = is.array(this.value) ? this.value[0] : this.value
        return this.column.equalityComparator(value, this.stores)
    }
    @computed
    get sortComparatorValue() {
        let value = is.array(this.value) ? this.value[0] : this.value
        return this.column.sortComparator(value, this.stores)
    }
    @computed
    get component() {
        return this.column.cellComponent?.({
            value: this.value,
            group: this.group,
            formattedValue: this.formattedValue,
            formatter: this.column.formatter,
            editable: this.editable,
            onChange: this.onChange,
            onClick: this.onClick,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            stores: this.stores,
        })
    }
    @computed
    get readOnlyComponent() {
        return this.column.cellComponent({
            value: this.value,
            group: this.group,
            formattedValue: this.formattedValue,
            formatter: this.column.formatter,
            editable: false,
            stores: this.stores,
        })
    }
    @computed
    get style() {
        return this.column.style(this.rowObject, this.stores)
    }
    @computed
    get className() {
        return this.column.className(this.rowObject, this.stores)
    }
    @computed
    get selected() {
        return this.column.selected(this.rowObject, this.stores)
    }
    @computed
    get hasPermission() {
        return this.column.permissions(this.rowObject, this.stores)
    }
}

export default TableCellStore

const tryOrNull = (func) => {
    try {
        return func?.()
    } catch (e) {
        // console.warn(e)
        return null
    }
}
