import { observable, computed, action, makeObservable, reaction } from 'mobx'
import { makeRequest } from '../Queries/makeRequest'

class DateRangeDataStore {
    @observable dateRangeData = {}
    @observable savingDateRangeData = {}
    @observable saveState = 'idle' // idle, saving, error
    // {id: {
    //     collection
    //     dateRange
    //     field
    //     value
    //     filters
    // }}
    constructor(props) {
        makeObservable(this)
    }
    @computed
    get needsSaving() {
        return Object.values(this.dateRangeData).length > 0
    }
    @action
    clearData() {
        this.dateRangeData = {}
    }
    @action
    addData(id, data) {
        this.dateRangeData[id] = data
    }
    @action
    async startSave() {
        if (
            this.needsSaving &&
            this.saveState !== 'saving' &&
            this.saveState !== 'error'
        ) {
            try {
                this.saveState = 'saving'
                this.savingDateRangeData = this.dateRangeData
                this.dateRangeData = {}
                await makeRequest({
                    path: `api/v1.5/save-date-range`,
                    method: 'post',
                    data: Object.values(this.savingDateRangeData),
                })
                this.saveState = 'success'
                if (this.needsSaving) {
                    this.startSave()
                } else {
                    setTimeout(() => {
                        if (this.saveState === 'success')
                            this.saveState = 'idle'
                    }, 1000)
                }
            } catch (e) {
                console.error(e.message)
                this.saveState = 'error'
                this.dateRangeData = {
                    ...this.dateRangeData,
                    ...this.savingDateRangeData,
                }
                throw e
            }
        }
        return
    }
}

export default new DateRangeDataStore()
