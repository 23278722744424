import React from 'react'
import ContactCollection from '../State/Collections/ContactCollection'
import CostCentreCollection from '../State/Collections/CostCentreCollection'
import StaffCollection from '../State/Collections/StaffCollection'
import _ from 'lodash'
import PhaseCollection from '../State/Collections/PhaseCollection'
import RoleCollection from '../State/Collections/RoleCollection'
import { capitalCase } from 'change-case'
import ProjectCollection from '../State/Collections/ProjectCollection'
import ProjectExpenseCollection from '../State/Collections/ProjectExpenseCollection'
import SortableMultiselect from './Widgets/SortableMultiselect'
import TaskCollection from '../State/Collections/TaskCollection'

export const MultiSelect = ({
    selectedOptions = [],
    options = [],
    onChange = () => null,
    searchMatcher,
    optionLabel,
    optionValue,
    groupBy,
    ...props
}) => {
    return (
        <SortableMultiselect
            selectedOptions={[...selectedOptions]}
            options={[...options]}
            getOptionLabel={optionLabel}
            getOptionValue={optionValue}
            onChange={onChange}
            filter={searchMatcher}
            groupBy={groupBy}
            {...props}
        />
    )
}

export const ContactMultiselect = ({
    selectedContacts,
    contactOptions,
    onChange,
    ...props
}) => {
    let options = contactOptions || ContactCollection.contacts
    return (
        <MultiSelect
            selectedOptions={selectedContacts}
            onChange={onChange}
            options={_.orderBy(options, ['label'], ['asc'])}
            optionLabel={(c) => c?.label}
            optionValue={(sm) => sm?.id}
            searchMatcher={(c, text) =>
                c?.label?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const CostCentreMultiselect = ({
    selectedCostCentres,
    costCentreOptions,
    onChange,
    ...props
}) => {
    let options = costCentreOptions || CostCentreCollection.costCentres
    return (
        <MultiSelect
            selectedOptions={selectedCostCentres}
            onChange={onChange}
            options={[..._.orderBy(options, ['name'], ['asc'])]}
            optionLabel={(cc) => cc?.name}
            optionValue={(sm) => sm?.id}
            searchMatcher={(cc, text) =>
                cc?.name?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const StaffMultiselect = ({
    selectedStaffMembers,
    staffOptions,
    onChange,
    ...props
}) => {
    let options = staffOptions || StaffCollection.staffs
    return (
        <MultiSelect
            selectedOptions={selectedStaffMembers}
            onChange={onChange}
            options={_.orderBy(options, ['fullName'], ['asc'])}
            optionLabel={(sm) => sm?.fullName}
            optionValue={(sm) => sm?.id}
            searchMatcher={(sm, text) =>
                sm?.fullName?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const RoleMultiselect = ({
    selectedRoles,
    roleOptions,
    onChange,
    ...props
}) => {
    let options = roleOptions || RoleCollection.roles
    return (
        <MultiSelect
            selectedOptions={selectedRoles}
            onChange={onChange}
            options={_.orderBy(options, ['name'], ['asc'])}
            optionLabel={(sm) => sm?.name}
            optionValue={(sm) => sm?.id}
            searchMatcher={(sm, text) =>
                sm?.name?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const ProjectMultiselect = ({
    selectedProjects,
    projectOptions,
    onChange,
    ...props
}) => {
    let options = projectOptions || ProjectCollection.projects
    return (
        <MultiSelect
            selectedOptions={selectedProjects}
            onChange={onChange}
            options={_.orderBy(options, ['title'], ['asc'])}
            optionLabel={(sm) => sm?.title}
            optionValue={(sm) => sm?.id}
            searchMatcher={(sm, text) => {
                return sm?.title?.toLowerCase?.()?.match?.(text.toLowerCase())
            }}
            {...props}
        />
    )
}

export const PhaseMultiselect = ({
    selectedPhases,
    phaseOptions,
    onChange,
    ...props
}) => {
    let options = phaseOptions || PhaseCollection.phases
    return (
        <MultiSelect
            selectedOptions={selectedPhases}
            onChange={onChange}
            options={_.orderBy(options, ['title'], ['asc'])}
            optionLabel={(sm) => sm?.title}
            optionValue={(sm) => sm?.id}
            searchMatcher={(sm, text) =>
                sm.title.toLowerCase().match(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const StatusMultiselect = ({
    selectedStatuses,
    onChange,
    placeholderLabel,
    ...props
}) => {
    return (
        <MultiSelect
            selectedOptions={selectedStatuses.map((s) => ({ value: s }))}
            onChange={(options) => onChange(options.map((o) => o.value))}
            options={[
                { value: 'active' },
                { value: 'onHold' },
                { value: 'prospective' },
                { value: 'archived' },
            ]}
            // sort={(c) => c.label}
            optionLabel={(o) => capitalCase(o.value)}
            optionValue={(o) => o.value}
            // searchMatcher={(c, text) =>
            //     c.label.toLowerCase().match(text.toLowerCase())
            // }
            {...props}
        />
    )
}

export const ExpenseMultiselect = ({
    selectedExpenses,
    onChange,
    placeholderLabel,
    ...props
}) => {
    return (
        <MultiSelect
            selectedOptions={selectedExpenses.map((e) => ({ value: e }))}
            onChange={(options) => onChange(options.map((o) => o.value))}
            options={[
                ...new Set(
                    ProjectExpenseCollection.projectExpenses.map((e) => e.name)
                ),
            ].map((e) => ({ value: e }))}
            // sort={(c) => c.label}
            optionLabel={(s) => capitalCase(s.value)}
            optionValue={(o) => o.value}
            // searchMatcher={(c, text) =>
            //     c.label.toLowerCase().match(text.toLowerCase())
            // }
            {...props}
        />
    )
}

export const TaskMultiselect = ({
    selectedTasks,
    taskOptions,
    onChange,
    ...props
}) => {
    let options = taskOptions || TaskCollection.tasks
    return (
        <MultiSelect
            selectedOptions={selectedTasks}
            onChange={onChange}
            options={_.orderBy(options, ['title'], ['asc'])}
            optionLabel={(sm) => sm?.title}
            optionValue={(sm) => sm?.id}
            searchMatcher={(sm, text) =>
                sm.name.toLowerCase().match(text.toLowerCase())
            }
            {...props}
        />
    )
}

export default {
    status: StatusMultiselect,
}
