import React from 'react'
import ContactCollection from '../State/Collections/ContactCollection'
import CostCentreCollection from '../State/Collections/CostCentreCollection'
import StaffCollection from '../State/Collections/StaffCollection'
import _ from 'lodash'
import PhaseCollection from '../State/Collections/PhaseCollection'
import RoleCollection from '../State/Collections/RoleCollection'
import { capitalCase } from 'change-case'
import ProjectCollection from '../State/Collections/ProjectCollection'
import { dateStringLabels } from './Filters'
import TaskCollection from '../State/Collections/TaskCollection'
import Inputs from './Inputs'

export const Selector = ({
    selectedOption,
    options,
    onChange,
    disableOption,
    searchMatcher,
    optionLabel,
    groupBy,
    sort,
    ...props
}) => {
    return (
        <Selector
            {...props}
            value={selectedOption}
            options={sort ? _.sortBy(options, sort) : options}
            onChange={onChange}
            disableOption={disableOption}
            searchMatcher={searchMatcher}
            optionLabel={optionLabel}
            groupBy={groupBy}
        />
    )
}

export const BooleanSelector = ({
    selectedOption,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = [true, false]
    if (nullable) {
        options = [null, ...options]
    }
    return (
        <Selector
            selectedOption={selectedOption}
            onChange={onChange}
            options={options}
            sort={(c) => c}
            optionLabel={(c) =>
                c === null ? "Doesn't matter" : c ? 'Yes' : 'No'
            }
            searchMatcher={(c, text) =>
                (c === null ? "doesn't matter" : c ? 'yes' : 'no').match?.(
                    text.toLowerCase()
                )
            }
            {...props}
        />
    )
}

export const ContactSelector = ({
    selectedContact,
    contactOptions,
    disabledContacts,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = contactOptions || ContactCollection.contacts
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (contact) =>
        disabledContacts ? disabledContacts.includes(contact) : false
    return (
        <Selector
            selectedOption={selectedContact}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(c) => c?.label || -Infinity}
            optionLabel={(c) =>
                c?.label || placeholderLabel || 'Select Contact...'
            }
            searchMatcher={(c, text) =>
                c?.label?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const CostCentreSelector = ({
    selectedCostCentre,
    costCentreOptions,
    disabledCostCentres,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = costCentreOptions || CostCentreCollection.costCentres
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (costCentre) =>
        disabledCostCentres ? disabledCostCentres.includes(costCentre) : false
    return (
        <Selector
            selectedOption={selectedCostCentre}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(cc) => cc?.name || -Infinity}
            optionLabel={(cc) =>
                cc?.name || placeholderLabel || 'Select Cost Centre...'
            }
            searchMatcher={(cc, text) =>
                cc?.name?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const StaffSelector = ({
    selectedStaffMember,
    staffOptions,
    disabledStaff,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = staffOptions || StaffCollection.staffs
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (staff) =>
        disabledStaff ? disabledStaff.includes(staff) : false
    return (
        <Selector
            selectedOption={selectedStaffMember}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(sm) => sm?.fullName || -Infinity}
            optionLabel={(sm) =>
                sm?.fullName || placeholderLabel || 'Select Staff...'
            }
            searchMatcher={(sm, text) =>
                sm?.fullName?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const RoleSelector = ({
    selectedRole,
    roleOptions,
    disabledRoles,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = roleOptions || RoleCollection.roles
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (role) =>
        disabledRoles ? disabledRoles.includes(role) : false
    return (
        <Selector
            selectedOption={selectedRole}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(sm) => sm?.name || -Infinity}
            optionLabel={(sm) =>
                sm?.name || placeholderLabel || 'Select Role...'
            }
            searchMatcher={(sm, text) =>
                sm?.name?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const ProjectSelector = ({
    selectedProject,
    projectOptions,
    disabledProjects,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = projectOptions || ProjectCollection.projects
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (project) =>
        disabledProjects ? disabledProjects.includes(project) : false
    return (
        <Selector
            selectedOption={selectedProject}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(ph) => ph?.title || -Infinity}
            optionLabel={(sm) =>
                sm?.title || placeholderLabel || 'Select Project...'
            }
            searchMatcher={(sm, text) =>
                sm?.title?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const PhaseSelector = ({
    selectedPhase,
    phaseOptions,
    disabledPhases,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = phaseOptions || PhaseCollection.phases
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (phase) =>
        disabledPhases ? disabledPhases.includes(phase) : false
    return (
        <Selector
            selectedOption={selectedPhase}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(ph) => ph?.title || -Infinity}
            optionLabel={(sm) =>
                sm?.title || placeholderLabel || 'Select Phase...'
            }
            searchMatcher={(sm, text) =>
                sm.title.toLowerCase().match(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const TaskSelector = ({
    selectedTask,
    taskOptions,
    disabledTasks,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = taskOptions || TaskCollection.tasks
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (task) =>
        disabledTasks ? disabledTasks.includes(task) : false
    return (
        <Selector
            selectedOption={selectedTask}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(ph) => ph?.name || -Infinity}
            optionLabel={(sm) =>
                sm?.name || placeholderLabel || 'Select Task...'
            }
            searchMatcher={(sm, text) =>
                sm.name.toLowerCase().match(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const StaffOrRoleSelector = ({
    selectedStaffOrRole,
    staffOrRoleOptions,
    disabledStaffOrRoles,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = staffOrRoleOptions || [
        ...RoleCollection.roles,
        ...StaffCollection.staffs,
    ]
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (sor) =>
        disabledStaffOrRoles ? disabledStaffOrRoles.includes(sor) : false
    return (
        <Selector
            selectedOption={selectedStaffOrRole}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(sor) => sor?.fullName || sor?.name || -Infinity}
            optionLabel={(sor) =>
                sor?.fullName ||
                sor?.name ||
                placeholderLabel ||
                'Select Staff or Role...'
            }
            searchMatcher={(sor, text) =>
                (sor?.fullName || sor?.name)
                    ?.toLowerCase?.()
                    ?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const CostCentreOrProjectSelector = ({
    selectedCostCentreOrProject,
    costCentreOrProjectOptions,
    disabledCostCentresOrProjects,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = costCentreOrProjectOptions || [
        ...CostCentreCollection.costCentres,
        ...ProjectCollection.projects,
    ]
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (ccop) =>
        disabledCostCentresOrProjects
            ? disabledCostCentresOrProjects.includes(ccop)
            : false
    return (
        <Selector
            selectedOption={selectedCostCentreOrProject}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(ccop) => ccop?.name || ccop?.title || -Infinity}
            optionLabel={(ccop) =>
                ccop?.name ||
                ccop?.title ||
                placeholderLabel ||
                'Select Cost Centre or Project...'
            }
            searchMatcher={(ccop, text) =>
                (ccop.name || ccop.title)
                    ?.toLowerCase?.()
                    ?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const StatusSelector = ({
    selectedStatus,
    onChange,
    placeholderLabel,
    ...props
}) => {
    return (
        <Selector
            selectedOption={selectedStatus}
            onChange={onChange}
            options={['active', 'onHold', 'prospective', 'archived']}
            // sort={(c) => c.label}
            optionLabel={(s) => capitalCase(s)}
            // searchMatcher={(c, text) =>
            //     c.label.toLowerCase().match(text.toLowerCase())
            // }
            {...props}
        />
    )
}

export const RelativeDateSelector = ({
    selectedDateRange,
    onChange,
    ...props
}) => {
    const optionsKeys = Object.keys(dateStringLabels)
    const selectedOption = optionsKeys.includes(selectedDateRange)
        ? selectedDateRange
        : 'custom'
    return (
        <div style={{ display: 'flex' }}>
            <Selector
                selectedOption={selectedOption}
                onChange={(opt) =>
                    onChange(opt === 'custom' ? [new Date(), new Date()] : opt)
                }
                options={optionsKeys}
                // sort={(c) => c.label}
                optionLabel={(s) => dateStringLabels[s]}
                // searchMatcher={(c, text) =>
                //     c.label.toLowerCase().match(text.toLowerCase())
                // }
                {...props}
            />
            {selectedOption === 'custom' ? (
                <>
                    {Inputs.date({
                        style: { width: '10em' },
                        value: selectedDateRange[0],
                        editable: true,
                        onChange: (val) =>
                            onChange([val, selectedDateRange?.[1]]),
                    })}
                    <div style={{ margin: '0 0.5em' }}>to</div>
                    {Inputs.date({
                        style: { width: '10em' },
                        value: selectedDateRange[1],
                        editable: true,
                        onChange: (val) =>
                            onChange([selectedDateRange?.[0], val]),
                    })}
                </>
            ) : null}
        </div>
    )
}
