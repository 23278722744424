import { add, formatISO, parseISO } from 'date-fns'
import localforage from 'localforage'
import _ from 'lodash'
import { observable, computed, action, makeObservable, toJS } from 'mobx'
import pluralize from 'pluralize'
import { object } from 'underscore'
import OrganisationCollection from './Collections/OrganisationCollection'
import StaffCollection from './Collections/StaffCollection'
import OrganisationSubscriptionCollection from './Collections/OrganisationSubscriptionCollection'
import { hasImpersonationPermission } from './Permissions/HasPermissions'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import { version as appVersion } from '../../package.json'
import { getVersion } from '../Queries/version'
import { makeRequest } from '../Queries/makeRequest'

class SessionStore2 {
    @observable profilePictureUrl
    @observable emailVerified
    // @observable expires = null
    @observable userId = null
    @observable userPermissions = null
    @observable userEmail = null
    @observable userFirstName = null
    @observable userLastName = null
    @observable userCreatedAt = null
    @observable organisationId = null
    @observable organisationName = null
    @observable organisationCountry = null
    @observable state = 'unauthenticated' //authenticated, checkingAuth, loggingIn
    @observable accounting = {}
    @observable initialized = false
    @observable updateAvailable = false
    @observable migratedToV2 = false

    @action.bound
    setMigratedToV2(migrated) {
        this.migratedToV2 = migrated
    }

    constructor(props) {
        makeObservable(this)
        this.initialize()
    }
    @action.bound
    async initialize() {
        await this.checkAuthentication()
        this.initialized = true
    }
    @computed
    get authenticated() {
        return (
            this.state === 'authenticated' &&
            // this.expires && //TODO: handle with workos
            this.userId //&&
            // this.expires > Date.now()
        )
    }
    @action.bound
    login(code) {
        this.state = 'loggingIn'
        const self = this
        return makeRequest({
            path: '/auth/callback',
            method: 'POST',
            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
            data: { code },
            maxRetries: 1, //only one retry is enough
        })
            .then(this.saveLoginResponse)
            .catch(function (error) {
                Sentry.captureException(error)
                console.error(error)
                self.state = 'unauthenticated'
                throw error
            })
    }
    @action.bound
    async saveLoginResponse(response) {
        //TODO for ryan: you can use WorkOs attributes to implement more logic later
        const { data } = response
        //WorkOs attributes:
        this.emailVerified = data.emailVerified
        this.profilePictureUrl = data.profilePictureUrl
        // DB attributes
        this.userId = response.data.userId
        this.userPermissions = response.data.userPermissions
        this.userEmail = response.data.userEmail
        this.userFirstName = response.data.userFirstName
        this.userLastName = response.data.userLastName
        this.userCreatedAt = response.data.userCreatedAt
        this.organisationId = response.data.organisationId
        this.organisationName = response.data.organisationName
        this.organisationCountry = response.data.organisationCountry
        // this.expires = add(new Date(), {
        //     days: 13,
        //     hours: 23,
        //     minutes: 50,
        // })
        await this.saveSession()
        this.createModels()
        return response
    }

    // @action.bound
    // impersonate(email) {
    //     //TODO: how? this is already available in workos dashboard
    //     this.state = 'loggingIn'
    //     const self = this
    //     if (!hasImpersonationPermission(this.user)) {
    //         throw new Error('You do not have permission to impersonate')
    //     }

    //     return makeRequest({
    //         path:
    //             process.env.REACT_APP_SERVER_URL + '/api/v1.5/user/impersonate',
    //         method: 'post',
    //         data: {
    //             email: email,
    //         },
    //     })
    //         .then(this.saveLoginResponse)
    //         .catch(function (error) {
    //             Sentry.captureException(error)
    //             console.error(error)
    //             self.state = 'unauthenticated'
    //             throw error
    //         })
    // }
    // @action.bound
    // register(email, password) {
    //     //TODO for ryan: Register page is not needed any more
    // }
    @action.bound
    async logout() {
        const self = this
        await makeRequest({
            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
            path: '/auth/logout',
            method: 'DELETE',
            maxRetries: 1,
        }).then(({ data }) => {
            if (!data.redirectTo) {
                throw new Error('Failed to logout')
            }

            self.deleteSession().then(() => {
                this.userId = null
                this.emailVerified = null
                this.profilePictureUrl = null
                this.userPermissions = null
                this.userEmail = null
                this.userFirstName = null
                this.userLastName = null
                this.userCreatedAt = null
                this.organisationId = null
                this.organisationName = null
                this.organisationCountry = null
                this.expires = null
                this.accounting = {}
                window.location.reload()
                //router.navigate({ to: '/login' })
            })
        })
    }
    @action.bound
    async saveSession(callback) {
        const session = {
            emailVerified: this.emailVerified,
            profilePictureUrl: this.profilePictureUrl,
            userId: this.userId,
            userPermissions: toJS(this.userPermissions),
            userEmail: this.userEmail,
            userFirstName: this.userFirstName,
            userLastName: this.userLastName,
            userCreatedAt: this.userCreatedAt,
            organisationId: this.organisationId,
            organisationName: this.organisationName,
            organisationCountry: this.organisationCountry,
            // expires: this.expires && formatISO(this.expires),
            accounting: {
                expires:
                    this.accounting.expires &&
                    formatISO(this.accounting.expires),
                system: this.accounting.system,
            },
        }
        await localforage.setItem('session', session).then(() => {
            this.state = 'authenticated'
            callback && callback(session)
            return session
        })
    }
    @action.bound
    async deleteSession() {
        await localforage.removeItem('session')
    }

    @action.bound
    async checkAuthentication(callback) {
        this.state = 'checkingAuth'
        const session = await localforage.getItem('session')
        if (!session) {
            this.state = 'unauthenticated'
        } else {
            this.state = 'authenticated'
            this.emailVerified = session.emailVerified
            this.profilePictureUrl = session.profilePictureUrl
            this.userId = session.userId
            this.emailVerified = session.emailVerified
            this.userPermissions = session.userPermissions
            this.userEmail = session.userEmail
            this.userFirstName = session.userFirstName
            this.userLastName = session.userLastName
            this.userCreatedAt = session.userCreatedAt
            this.organisationId = session.organisationId
            this.organisationName = session.organisationName
            this.organisationCountry = session.organisationCountry || 'au'
            // this.expires = session.expires && parseISO(session.expires)
            this.accounting.expires =
                session.accounting.expires &&
                parseISO(session.accounting.expires)
            this.accounting.system = session.accounting.system
            this.resetAccountingTimeout()
            this.createModels()
        }
        callback && callback()
    }
    createModels() {
        const user = this.createUserModel()
        const org = this.createOrganisationModel()
        // if (process.env.REACT_APP_ENV === 'production') {
        Sentry.setUser({
            email: user.email,
        })
        Sentry.setTag('name', user.fullName)
        Sentry.setTag('organisation', this.organisationName)
        posthog.identify(
            user.email, // Replace 'distinct_id' with your user's unique identifier
            {
                email: user.email,
                name: user.fullName,
                organisation: this.organisationName,
            } // optional: set additional user properties
        )
        posthog.group('organisation', this.organisationId, {
            name: this.organisationName,
        })
        // }
        window.Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            user_id: user.id,
            email: user.email,
            name: user.fullName,
            company: {
                name: this.organisationName,
                id: this.organisationId,
            },
            created_at: this.userCreatedAt,
        })
    }
    createUserModel() {
        const user = StaffCollection.add(
            {
                id: this.userId,
                permissions: this.userPermissions,
                email: this.userEmail,
                firstName: this.userFirstName,
                lastName: this.userLastName,
            },
            { trackUpdates: false }
        )
        return user
    }
    createOrganisationModel() {
        const organisation = OrganisationCollection.add(
            {
                id: this.organisationId,
                name: this.organisationName,
                country: this.organisationCountry,
            },
            { trackUpdates: false }
        )
        return organisation
    }
    @action.bound
    async resetAccountingTimeout() {
        if (this.accounting.expires > new Date()) {
            setTimeout(() => {
                this.accounting.expires = null
            }, this.accounting.expires - new Date())
        }
    }
    @action.bound
    async saveAccountingExpires(expires) {
        this.accounting.expires = expires
        this.accounting.system = this.organisation?.accountingSystem
        await this.saveSession()
        this.resetAccountingTimeout()
    }
    @computed
    get accountingConnected() {
        return (
            this.accounting.system === this.organisation?.accountingSystem &&
            this.accounting.expires &&
            this.accounting.expires > new Date()
        )
    }
    @computed
    get user() {
        return StaffCollection.staffsById[this.userId]
    }
    @computed
    get organisation() {
        return [...OrganisationCollection.organisations][0]
    }
    @computed
    get subscription() {
        return [...OrganisationSubscriptionCollection.models][0]
    }
    @computed
    get settings() {
        return {
            ...settingDefaults,
            ...(this.organisation?.settings || {}),
        }
    }
    @action
    async checkIfUpdateIsAvailable() {
        const version = await getVersion()
        if (version === appVersion) {
            this.updateAvailable = false
        } else {
            // wait 45 secs for client to build
            setTimeout(() => {
                this.updateAvailable = true
            }, 45000)
        }
    }
}

class SessionStore {
    @observable expires = null
    @observable userId = null
    @observable userPermissions = null
    @observable userEmail = null
    @observable userFirstName = null
    @observable userLastName = null
    @observable userCreatedAt = null
    @observable organisationId = null
    @observable organisationName = null
    @observable organisationCountry = null
    @observable state = 'unauthenticated' //authenticated, checkingAuth, loggingIn
    @observable accounting = {}
    @observable initialized = false
    @observable updateAvailable = false
    @observable migratedToV2 = false

    @action.bound
    setMigratedToV2(migrated) {
        this.migratedToV2 = migrated
    }

    constructor(props) {
        makeObservable(this)
        this.initialize()
    }
    @action.bound
    async initialize() {
        await this.checkAuthentication()
        this.initialized = true
    }
    @computed
    get authenticated() {
        return (
            this.state === 'authenticated' &&
            this.expires &&
            this.userId &&
            this.expires > Date.now()
        )
    }
    @action.bound
    login(email, password) {
        this.state = 'loggingIn'
        const self = this
        return makeRequest({
            path: process.env.REACT_APP_SERVER_URL + '/api/v1.5/user/login',
            method: 'post',
            data: {
                email: email,
                password: password,
            },
        })
            .then(this.saveLoginResponse)
            .catch(function (error) {
                Sentry.captureException(error)
                console.error(error)
                self.state = 'unauthenticated'
                throw error
            })
    }
    @action.bound
    async saveLoginResponse(response) {
        this.userId = response.data.userId
        this.userPermissions = response.data.userPermissions
        this.userEmail = response.data.userEmail
        this.userFirstName = response.data.userFirstName
        this.userLastName = response.data.userLastName
        this.userCreatedAt = response.data.userCreatedAt
        this.organisationId = response.data.organisationId
        this.organisationName = response.data.organisationName
        this.organisationCountry = response.data.organisationCountry
        this.expires = add(new Date(), {
            days: 13,
            hours: 23,
            minutes: 50,
        })
        await this.saveSession()
        this.createModels()
        return response
    }

    @action.bound
    impersonate(email) {
        this.state = 'loggingIn'
        const self = this
        if (!hasImpersonationPermission(this.user)) {
            throw new Error('You do not have permission to impersonate')
        }

        return makeRequest({
            path:
                process.env.REACT_APP_SERVER_URL + '/api/v1.5/user/impersonate',
            method: 'post',
            data: {
                email: email,
            },
        })
            .then(this.saveLoginResponse)
            .catch(function (error) {
                Sentry.captureException(error)
                console.error(error)
                self.state = 'unauthenticated'
                throw error
            })
    }
    @action.bound
    register(email, password) {
        this.state = 'loggingIn'
        const self = this
        return makeRequest({
            path: process.env.REACT_APP_SERVER_URL + '/api/v1.5/user/register',
            method: 'post',
            data: {
                email: email,
                password: password,
            },
        })
            .then(this.saveLoginResponse)
            .catch(function (error) {
                Sentry.captureException(error)
                console.error(error)
                self.state = 'unauthenticated'
                throw error
            })
    }
    @action.bound
    logout() {
        //TODO: when is login modal opened?
        this.deleteSession().then(() => {
            this.userId = null
            this.userPermissions = null
            this.userEmail = null
            this.userFirstName = null
            this.userLastName = null
            this.userCreatedAt = null
            this.organisationId = null
            this.organisationName = null
            this.organisationCountry = null
            this.expires = null
            this.accounting = {}
            window.location.reload()
        })
    }
    @action.bound
    async saveSession(callback) {
        const session = {
            userId: this.userId,
            userPermissions: toJS(this.userPermissions),
            userEmail: this.userEmail,
            userFirstName: this.userFirstName,
            userLastName: this.userLastName,
            userCreatedAt: this.userCreatedAt,
            organisationId: this.organisationId,
            organisationName: this.organisationName,
            organisationCountry: this.organisationCountry,
            expires: this.expires && formatISO(this.expires),
            accounting: {
                expires:
                    this.accounting.expires &&
                    formatISO(this.accounting.expires),
                system: this.accounting.system,
            },
        }
        await localforage.setItem('session', session).then(() => {
            this.state = 'authenticated'
            callback && callback(session)
            return session
        })
    }
    @action.bound
    async deleteSession() {
        await localforage.removeItem('session')
    }

    @action.bound
    async checkAuthentication(callback) {
        this.state = 'checkingAuth'
        const session = await localforage.getItem('session')
        if (!session) {
            this.state = 'unauthenticated'
        } else {
            this.state = 'authenticated'
            this.userId = session.userId
            this.userPermissions = session.userPermissions
            this.userEmail = session.userEmail
            this.userFirstName = session.userFirstName
            this.userLastName = session.userLastName
            this.userCreatedAt = session.userCreatedAt
            this.organisationId = session.organisationId
            this.organisationName = session.organisationName
            this.organisationCountry = session.organisationCountry || 'au'
            this.expires = session.expires && parseISO(session.expires)
            this.accounting.expires =
                session.accounting.expires &&
                parseISO(session.accounting.expires)
            this.accounting.system = session.accounting.system
            this.resetAccountingTimeout()
            this.createModels()
        }
        callback && callback()
    }
    createModels() {
        const user = this.createUserModel()
        const org = this.createOrganisationModel()
        // if (process.env.REACT_APP_ENV === 'production') {
        Sentry.setUser({
            email: user.email,
        })
        Sentry.setTag('name', user.fullName)
        Sentry.setTag('organisation', this.organisationName)
        posthog.identify(
            user.email, // Replace 'distinct_id' with your user's unique identifier
            {
                email: user.email,
                name: user.fullName,
                organisation: this.organisationName,
            } // optional: set additional user properties
        )
        posthog.group('organisation', this.organisationId, {
            name: this.organisationName,
        })
        // }
        window.Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            user_id: user.id,
            email: user.email,
            name: user.fullName,
            company: {
                name: this.organisationName,
                id: this.organisationId,
            },
            created_at: this.userCreatedAt,
        })
    }
    createUserModel() {
        const user = StaffCollection.add(
            {
                id: this.userId,
                permissions: this.userPermissions,
                email: this.userEmail,
                firstName: this.userFirstName,
                lastName: this.userLastName,
            },
            { trackUpdates: false }
        )
        return user
    }
    createOrganisationModel() {
        const organisation = OrganisationCollection.add(
            {
                id: this.organisationId,
                name: this.organisationName,
                country: this.organisationCountry,
            },
            { trackUpdates: false }
        )
        return organisation
    }
    @action.bound
    async resetAccountingTimeout() {
        if (this.accounting.expires > new Date()) {
            setTimeout(() => {
                this.accounting.expires = null
            }, this.accounting.expires - new Date())
        }
    }
    @action.bound
    async saveAccountingExpires(expires) {
        this.accounting.expires = expires
        this.accounting.system = this.organisation?.accountingSystem
        await this.saveSession()
        this.resetAccountingTimeout()
    }
    @computed
    get accountingConnected() {
        return (
            this.accounting.system === this.organisation?.accountingSystem &&
            this.accounting.expires &&
            this.accounting.expires > new Date()
        )
    }
    @computed
    get user() {
        return StaffCollection.staffsById[this.userId]
    }
    @computed
    get organisation() {
        return [...OrganisationCollection.organisations][0]
    }
    @computed
    get subscription() {
        return [...OrganisationSubscriptionCollection.models][0]
    }
    @computed
    get settings() {
        return {
            ...settingDefaults,
            ...(this.organisation?.settings || {}),
        }
    }
    @action
    async checkIfUpdateIsAvailable() {
        const version = await getVersion()
        if (version === appVersion) {
            this.updateAvailable = false
        } else {
            // wait 45 secs for client to build
            setTimeout(() => {
                this.updateAvailable = true
            }, 45000)
        }
    }
    @action
    async immediatelyCheckIfUpdateIsAvailable() {
        const version = await getVersion()
        if (version === appVersion) {
            this.updateAvailable = false
        } else {
            this.updateAvailable = true
        }
        return this.updateAvailable
    }
}

export default new SessionStore2()

export const settingDefaults = {
    sortPhasesBy: 'startDate',
    allowNoPhase: true,
    useTasks: true,
    allowAfterPhaseEnd: true,
    timeEntryAllocations: ['noAllocations', 'budgets', 'allocations'],
    timeEntryStatus: ['active', 'prospective', 'onHold', 'archived'],
    timeEntryFlags: ['billable', 'variation', 'overtime'],
    autoPopulate: [], // ["budgets", "allocations"]
    updateHoursFromRevenue: false,
    updateRevenueFromHours: false,
    autoUpdateRevenue: {
        action: 'ask', // "automatic", "never"
        adjustOnLoad: false,
        budget: 'remaining',
        start: 'now',
        end: 'endDate',
    },
    autoUpdateHours: {
        action: 'ask', // "automatic", "never"
        adjustOnLoad: false,
        budget: 'remaining',
        start: 'now',
        end: 'endDate',
    },
    reportInvoiceDateType: 'issueDate',
    savingInvoices: ['markTimeInvoiced'], // "lockTime", "automatic"
    requireMFAToLogin: false,
    emailDomains: [],
}
