import React from 'react'
import _ from 'lodash'
import cuid from 'cuid'

export default ({
    id,
    label,
    disabled = false,
    labelPosition = 'right',
    valueLink,
    value,
    indeterminate = false,
    onChange,
    onClick,
    className = '',
    style,
    ...props
}) => {
    id = id != null ? id : 'coincraft-checkbox-' + cuid()

    value = valueLink != null ? valueLink.value : value
    indeterminate = value === 'indeterminate'
    value = value === 'indeterminate' ? false : value

    let checkbox = (
        <input
            className="coincraft-checkbox__input"
            id={id}
            type="checkbox"
            checked={value}
            disabled={disabled}
            onChange={(event) => {
                if (!disabled) {
                    if (valueLink != null) {
                        valueLink.requestChange(event.target.checked)
                    } else {
                        onChange(event.target.checked, event)
                    }
                }
            }}
            onClick={onClick}
            style={{ cursor: 'pointer' }}
            tabIndex="0"
            ref={(input) => {
                if (input) {
                    input.indeterminate = indeterminate
                }
            }}
        />
    )

    label =
        label != null ? (
            <label
                htmlFor={id}
                style={
                    labelPosition === 'right'
                        ? { marginLeft: '1em' }
                        : { marginRight: '1em' }
                }
            >
                {label}
            </label>
        ) : null

    return (
        <div
            className={`coincraft-checkbox ${className}`}
            style={{ display: 'inline-block', ...style }}
        >
            {labelPosition === 'right' ? (
                <div className="inline-flex flex-align-items-center">
                    {checkbox}
                    {label}
                </div>
            ) : (
                <div className="inline-flex flex-align-items-center">
                    {label}
                    {checkbox}
                </div>
            )}
        </div>
    )
}
