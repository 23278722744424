import { observer } from 'mobx-react'
import React, { useState } from 'react'
import SelectionList from './SelectionList'
import CostCentreCollection from '../../State/Collections/CostCentreCollection'
import TimesheetAppStore from './TimesheetAppStore'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import { canEnterTimeAgainstProject } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

export default observer(() => {
    return (
        <SelectionList
            title={'Projects'}
            label={'title'}
            items={
                ProjectCollection.projectsByCostCentreId[
                    TimesheetAppStore.selectedCostCentre.id
                ].filter((p) =>
                    canEnterTimeAgainstProject(SessionStore.user, p)
                ) || []
            }
            backRoute={'selectCostCentre'}
            onChange={(project) => {
                TimesheetAppStore.selectProject(project)
                TimesheetAppStore.setRoute('selectPhase')
            }}
        />
    )
})
