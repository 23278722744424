import {
    observable,
    computed,
    action,
    makeObservable,
    entries,
    extendObservable,
    isComputedProp,
} from 'mobx'
import cuid from 'cuid'
import _ from 'lodash'
import { camelCase, capitalCase } from 'change-case'
import superjson from 'superjson'
import { format, parse, startOfDay } from 'date-fns'
import HistoryEvent from './HistoryEvent'

class Model {
    @observable id = cuid()
    @observable savedAt = null
    @observable updatedAt = new Date()
    @observable deletedAt = null
    @observable updateHistory = new Map()
    @observable initialized = false
    @observable history = []
    @observable detached = false
    @observable failedToSave = false
    collection = null
    createdAt = null
    deletedAt = null
    initiatedAt = new Date()
    constructor() {
        makeObservable(this)
    }
    @action.bound
    init(data, options = {}) {
        data = data instanceof Model ? data.serialize() : data
        this.id = data.id ?? this.id
        this.savedAt = !options.trackUpdates ? this.updatedAt : null
        this.detached = options.detached ?? false
        this.update(data, { trackUpdates: false, source: 'self', ...options })
        this.initialized = true
    }
    @computed
    get modelType() {
        return this.collection.modelType
    }
    @action.bound
    update(data, { trackUpdates = true, savedAt, source } = {}) {
        data = data instanceof Model ? data.serialize() : data
        if (savedAt) {
            this.savedAt = savedAt
            this.createdAt ||= new Date()
            //TODO this is bad
            for (const ts of this.updateHistory.keys()) {
                if (ts < savedAt) {
                    this.updateHistory.delete(ts)
                }
            }
        }
        for (const [key, value] of Object.entries(data)) {
            if (
                trackUpdates ||
                (source !== 'collection' && source !== 'self') ||
                !this.updatedProps.has(key)
            ) {
                this.changeProp(key, value, { trackUpdates })
            }
        }
        if (
            !this.detached &&
            source !== 'collection' &&
            source !== 'self' &&
            this.collection.onUpdate
        ) {
            this.collection.onUpdate(this.id)
        }
        if (!this.createdAt && this.deletedAt) {
            this.collection.delete(this.id)
        }
    }

    @action.bound
    changeProp(prop, val, { trackUpdates = true } = {}) {
        if (prop === 'history') {
            this.history = val.map((e) => new HistoryEvent(this, e))
            return
        }
        if (
            (prop.toLowerCase().includes('date') || prop.endsWith('At')) &&
            val &&
            !(val instanceof Date)
        ) {
            val = isNaN(new Date(val))
                ? parse(val, 'yyyy-MM-dd', new Date())
                : startOfDay(new Date(val))
        }

        // Check if the property is not present in the current instance and add it as an observable
        if (!this.hasOwnProperty(prop) && !isComputedProp(this, prop)) {
            extendObservable(this, { [prop]: val })
        } else {
            this[(isComputedProp(this, prop) ? '_' : '') + prop] = val
        }

        if (trackUpdates) {
            this.updatedAt = new Date()
            if (!this.updateHistory.has(this.updatedAt)) {
                this.updateHistory.set(this.updatedAt, [])
            }
            this.updateHistory.get(this.updatedAt).push(prop)
        }
    }
    @computed
    get updatedProps() {
        return new Set(
            [...this.updateHistory.values()]
                .flat()
                .filter(
                    (prop) =>
                        ![
                            'collection',
                            'detached',
                            'failedToSave',
                            'initiatedAt',
                        ].includes(prop)
                )
        )
    }
    @action.bound
    serialize() {
        let serialized = superjson.serialize(
            Object.fromEntries(
                entries(this)
                    .filter(
                        (e) =>
                            ![
                                'savedAt',
                                'updateHistory',
                                'initialized',
                            ].includes(e[0])
                    )
                    .map((e) => {
                        if (e[0].includes('Id') && e[1] == null) {
                            return [e[0], this[e[0].replace('Id', '')]?.id]
                        }
                        return e
                    })
            )
        ).json
        if (!this.createdAt) {
            serialized.createdAt = format(
                this.initiatedAt,
                'yyyy-MM-dd HH:mm:ss'
            )
        }
        return serialized
    }
    @action.bound
    serializeUpdates() {
        let serialized = superjson.serialize(
            Object.fromEntries([
                ['id', this.id],
                ...[...this.updatedProps]
                    .map((up) => [
                        up,
                        this[up] instanceof Date
                            ? format(this[up], 'yyyy-MM-dd')
                            : this[up],
                    ])
                    .map((e) => {
                        if (e[0].includes('Id') && e[1] == null) {
                            return [e[0], this[e[0].replace('Id', '')]?.id]
                        }
                        return e
                    }),
            ])
        ).json
        if (!this.createdAt) {
            serialized.createdAt = format(
                this.initiatedAt,
                'yyyy-MM-dd HH:mm:ss'
            )
        }
        return serialized
    }
    @computed
    get needsSaving() {
        return Boolean(this.updatedProps.size && this.updatedAt > this.savedAt)
    }
    @action.bound
    clone(newData, options = {}) {
        const newId = cuid()
        const data = { ...this.serialize(), ...newData, id: newId }
        if (!options.detached) {
            return this.collection.add(data, options)
        } else {
            return new this.constructor(data, options)
        }
    }
    @action.bound
    attach() {
        if (this.detached) {
            this.collection.add(this)
            this.detached = false
        }
    }
}

export default Model
