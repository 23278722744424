import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import CostCentreModel from '../Models/CostCentreModel'

class CostCentreCollection extends Collection {
    constructor() {
        super({ collection: 'costCentres', modelClass: CostCentreModel })
        this.addLookup('activeCostCentres', 'list', {
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get costCentresById() {
        return this.modelsById
    }
    get costCentres() {
        return this.models
    }
    get costCentresById() {
        return this.modelsById
    }
    @action.bound
    addCostCentre(data) {
        this.add(data)
    }
    @action.bound
    addCostCentres(data) {
        this.addMany(data)
    }
}

export default new CostCentreCollection()
export const CostCentreCollectionClass = CostCentreCollection
