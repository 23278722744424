import { useEffect, useState } from 'react'

const useVisible = () => {
    const [state, setState] = useState({
        hidden: document.hidden,
        visibilityState: document.visibilityState,
    })

    const onVisibilityChangeEvent = (event) => {
        setState({
            hidden: document.hidden,
            visibilityState: document.visibilityState,
        })
    }

    useEffect(() => {
        document.addEventListener('visibilitychange', onVisibilityChangeEvent)

        return () => {
            document.removeEventListener(
                'visibilitychange',
                onVisibilityChangeEvent
            )
        }
    }, [])

    return state
}

export default useVisible
