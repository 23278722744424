export default {
    name: 'New Report',
    type: 'timesheet',
    columns: [
        'monthIndex',
        'project',
        'projectPhase',
        'staffMember',
        'task',
        'date',
        'numMinutes',
        'labourExpense',
        'cost',
        'chargeOut',
        'notes',
    ],
    filters: [
        //     {
        //         column: 'project',
        //         operator: 'in',
        //         value: [],
        //     },
        //     {
        //         column: 'staff',
        //         operator: 'in',
        //         value: [],
        //     },
    ],
    groupBy: ['project', 'projectPhase', 'staffMember'],
    sortBy: [['label', 'desc']],
    options: {
        dateRange: 'thisMonth',
    },
}
