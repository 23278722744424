import { observer } from 'mobx-react'
import React, { useState } from 'react'
import RenderOnQueries from '../RenderOnQueries'
import SessionStore from '../../State/SessionStore'
import TimesheetAppStore from './TimesheetAppStore'
import AddEntryPage from './SelectCostCentrePage'
import TimeEntryPage from './TimeEntryPage'
import SelectCostCentrePage from './SelectCostCentrePage'
import SelectProjectPage from './SelectProjectPage'
import SelectPhasePage from './SelectPhasePage'
import SelectTaskPage from './SelectTaskPage'
import LoadingSpinner from '../../Components/LoadingSpinner'

export default observer(() => {
    const Page = TimesheetAppStore.displayedPage
    return (
        <RenderOnQueries
            loading={<LoadingSpinner />}
            queryIds={[
                {
                    collection: 'staff',
                    fields: [
                        'firstName',
                        'lastName',
                        'permissions',
                        'isArchived',
                        'email',
                    ],
                    filters: [`id == "${SessionStore.userId}"`],
                },
                {
                    collection: 'organisations',
                    fields: [
                        'name',
                        'accountingSystem',
                        'settings',
                        'defaultRevenueForecastReportId',
                        'defaultResourceScheduleReportId',
                        'defaultProjectReportId',
                        'defaultStaffReportId',
                        'defaultTimeReportId',
                        'defaultInvoiceReportId',
                        'defaultContactReportId',
                        'migratedAt'
                    ],
                },
                {
                    collection: 'organisationSubscriptions',
                    fields: [
                        'subscribedAt',
                        'endsTrialOn',
                        'cardExpDate',
                        'unpaidInvoices',
                    ],
                },
                {
                    collection: 'projects',
                    fields: [
                        'jobNumber',
                        'name',
                        'costCentreId',
                        ['status', 'phases.status'],
                    ],
                    subQueries: [
                        {
                            collection: 'phases',
                            join: `id == phases.projectId`,
                            groupBy: [`projectId`],
                            filters: ['isRootPhase != true'],
                            fields: [
                                [
                                    'status',
                                    "('active' in arr(status)) ? 'active' : ('prospective' in arr(status)) ? 'prospective' : ('onHold' in arr(status)) ? 'onHold' : 'archived'",
                                ],
                            ],
                        },
                    ],
                },
                {
                    collection: 'costCentres',
                    fields: ['name'],
                },
            ].filter((v) => v)}
        >
            <Page />
        </RenderOnQueries>
    )
})
