import { formatISO } from 'date-fns'

export function qf(value) {
    if (typeof value === 'number') {
        return String(value)
    }

    if (value instanceof Date) {
        return `'${formatISO(value, { representation: 'date' })}'`
    }

    if (typeof value === 'boolean') {
        return value ? 'true' : 'false'
    }

    if (typeof value === 'string') {
        return `"${value}"`
    }

    if (Array.isArray(value)) {
        return `[${value.map((v) => qf(v)).join(',')}]`
    }

    if (value === null || value === undefined) {
        return 'null'
    }

    return null
}

export function fNumOr0(field) {
    return `(${field} != null ? ${field} : 0)`
}

export function idf(value, modelName) {
    modelName = modelName ? `${modelName}.` : ''
    return `${modelName}id == ${qf(Number(value))}`
}
