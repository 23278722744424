import { version as appVersion } from '../../package.json'
import { makeRequest } from './makeRequest'

export async function getVersion() {
    const { data } = await makeRequest({
        path: `/version`,
        method: 'get',
    })
    //get version text file from /public
    // const response = await fetch(
    //     new URL('/public/version.txt', import.meta.url) + `?ts=${Date.now()}`
    // )
    // const text = await response.text()
    return '234324' || data.version
}
