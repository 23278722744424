import { dateStringLookup } from '../Components/Filters'
import { qf } from './queryFormatter'

export const queryFilters = {
    boolean: {
        '=': (column, value) => (value ? [`${column} == (${value})`] : null),
        '!=': (column, value) => (value ? [`${column} != (${value})`] : null),
    },
    text: {
        '=': (column, value) => [`${column} == "${value || ''}"`],
        '!=': (column, value) => [`${column} != "${value || ''}"`],
        contains: (column, value) =>
            value ? [`contains(${column}, "%${value}%")`] : [],
        '!contains': (column, value) =>
            value ? [`not(contains(${column}, "%${value}%"))`] : [],
        starts: (column, value) =>
            value ? [`contains(jobNumber, "${value}%")`] : [],
        '!starts': (column, value) =>
            value ? [`not(contains(jobNumber, "${value}%"))`] : [],
        ends: (column, value) =>
            value ? [`contains(jobNumber, "%${value}")`] : [],
        '!ends': (column, value) =>
            value ? [`not(contains(jobNumber, "%${value}"))`] : [],
        in: (column, value) => (value ? [`${column} in ${qf(value)}`] : []),
        '!in': (column, value) =>
            value ? [`not(${column} in ${qf(value)})`] : [],
    },
    number: {
        '=': (column, value) => [`${column} == (${value || 0})`],
        '!=': (column, value) => [`${column} != (${value || 0})`],
        '>': (column, value) => [`${column} > (${value || 0})`],
        '>=': (column, value) => [`${column} >= (${value || 0})`],
        '<': (column, value) => [`${column} < (${value || 0})`],
        '<=': (column, value) => [`${column} <= (${value || 0})`],
        between: (column, value) =>
            value
                ? [`${column} > (${value[0]}) and ${column} < (${value[1]})`]
                : [],
        '!between': (column, value) =>
            value
                ? [
                      `not(${column} > (${value[0]}) and ${column} < (${value[1]}))`,
                  ]
                : [],
        in: (column, value) => (value ? [`${column} in ${qf(value)}`] : []),
        '!in': (column, value) =>
            value ? [`not(${column} in ${qf(value)})`] : [],
    },
    date: {
        '=': (column, value) => (value ? [`${column} == ${qf(value)}`] : []),
        '!=': (column, value) => (value ? [`${column} != ${qf(value)}`] : []),
        '>': (column, value) => (value ? [`${column} > ${qf(value)}`] : []),
        '>=': (column, value) => (value ? [`${column} >= ${qf(value)}`] : []),
        '<': (column, value) => (value ? [`${column} < ${qf(value)}`] : []),
        '<=': (column, value) => (value ? [`${column} <= ${qf(value)}`] : []),
        between: (column, value) => {
            value = dateStringLookup[value]?.() || value
            return value
                ? [
                      `${column} > ${qf(value[0])} and ${column} < ${qf(
                          value[1]
                      )}`,
                  ]
                : []
        },
        '!between': (column, value) => {
            value = dateStringLookup[value]?.() || value
            return value
                ? [
                      `not(${column} > ${qf(value[0])} and ${column} < ${qf(
                          value[1]
                      )})`,
                  ]
                : []
        },
    },
    singleId: {
        '=': (column, value) => [`${column} == ${qf(value)}`],
        '!=': (column, value) => [`${column} != ${qf(value)}`],
        in: (column, value) =>
            value?.length ? [`${column} in ${qf(value)}`] : [],
        '!in': (column, value) =>
            value?.length ? [`not(${column} in ${qf(value)})`] : [],
    },
    manyIds: {
        in: (column, value) =>
            value?.length
                ? [value.map((v) => [`${v} in ${column}`]).join(' or ')]
                : [],
        '!in': (column, value) =>
            value?.length
                ? [value.map((v) => [`not(${v} in ${column}})`]).join(' and ')]
                : [],
    },
}
