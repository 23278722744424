import React, { useEffect, useState } from 'react'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import TimesheetAppStore from './TimesheetAppStore'
import { observer } from 'mobx-react'
import { formatMinutes, isNumber, parseTime } from '../../utils'
import Time from '../../formulas/core/operations/time'
import time from '../../formulas/core/operations/time'
import SessionStore from '../../State/SessionStore'
import classNames from 'classnames'
import DataStore from '../../State/DataStore'
import TimerStore from './TimerStore'

export default () => {
    return (
        TimeEntryCollection.timeEntriesByDate[
            TimesheetAppStore.selectedDate.getTime()
        ] || []
    ).map((te) => (
        <TimesheetItem key={te.id} timesheetEntry={te} path={te.id} />
    ))
}

const TimesheetItem = observer(({ timesheetEntry }) => {
    const budgetHours = false
    const progressPercent = 50
    return (
        <div
            className="timesheet-entry project"
            style={{ position: 'relative' }}
        >
            <div
                className="project-sel"
                onClick={() => {
                    TimesheetAppStore.selectTimeEntry(timesheetEntry)
                    TimesheetAppStore.setDropdownState(
                        TimerStore.selectedTimeEntry === timesheetEntry
                            ? 'timer'
                            : 'select'
                    )
                }}
                style={{ position: 'relative', paddingBottom: '0.5em' }}
            >
                <div className="project-text">
                    <span className="cc-name">
                        {timesheetEntry.costCentre?.name || '(No cost centre)'}
                    </span>
                    <span className="pr-name">
                        {timesheetEntry.project?.title || '(No project)'}
                    </span>
                    <span className="ph-name">
                        {!timesheetEntry.isBillable ? (
                            <span
                                className="fa-stack"
                                style={{
                                    fontSize: '0.7em',
                                    marginRight: '0.4em',
                                    display: 'inline-block',
                                }}
                            >
                                {/* <CurrencyIcon className="fa-stack-1x" /> */}
                                <i className="fa fa-ban fa-stack-2x" />
                            </span>
                        ) : null}
                        {timesheetEntry.isVariation ? (
                            <i
                                className="fa fa-code-fork"
                                style={{
                                    fontSize: '1.3em',
                                    position: 'relative',
                                    top: '0.15em',
                                }}
                            />
                        ) : null}
                        <span
                            style={{
                                marginLeft: '0.5em',
                                display: 'inline-block',
                            }}
                        >
                            {timesheetEntry.phase?.title || '(No phase)'}
                            {timesheetEntry.task &&
                            timesheetEntry.task?.name !== '(No task)'
                                ? ': ' + timesheetEntry.task?.name
                                : null}
                            {budgetHours
                                ? ` - ${Math.round(progressPercent)}%`
                                : null}
                        </span>
                    </span>
                </div>
                <div className="project-time">
                    {TimerStore.selectedTimeEntry === timesheetEntry
                        ? TimerStore.timeDisplay
                        : formatMinutes(timesheetEntry.numMinutes)}
                </div>
                <div
                    style={{
                        height: '3px',
                        position: 'absolute',
                        bottom: '0.5em',
                        left: '1em',
                        right: '1em',
                    }}
                >
                    <div
                        style={{
                            height: '3px',
                            backgroundColor: '#ccc',
                            width: '100%',
                            position: 'absolute',
                            left: 0,
                        }}
                    />
                    <div
                        style={{
                            height: '3px',
                            backgroundColor: `${'red'}`,
                            width: `${100}%`,
                            position: 'absolute',
                            left: 0,
                        }}
                    />
                </div>
            </div>
            {TimesheetAppStore.selectedTimeEntry === timesheetEntry ? (
                <TimesheetDropdown timesheetEntry={timesheetEntry} />
            ) : null}
        </div>
    )
})

export const TimesheetDropdown = observer(({ timesheetEntry }) => {
    return {
        select: <TimesheetDropdownSelect timesheetEntry={timesheetEntry} />,
        timer: <TimesheetDropdownTimer timesheetEntry={timesheetEntry} />,
        manualEntry: (
            <TimesheetDropdownManualEntry timesheetEntry={timesheetEntry} />
        ),
        notes: <TimesheetDropdownNotes timesheetEntry={timesheetEntry} />,
        options: <TimesheetDropdownOptions timesheetEntry={timesheetEntry} />,
        locked: <TimesheetDropdownLocked timesheetEntry={timesheetEntry} />,
    }[
        timesheetEntry.isLocked
            ? 'locked'
            : TimesheetAppStore.dropdownState || 'select'
    ]
})

export const TimesheetDropdownSelect = observer(({ timesheetEntry }) => {
    return (
        <div className="project-dropdown">
            <div className="project-options">
                <div
                    className="pr-opt"
                    onClick={() => TimesheetAppStore.setDropdownState('timer')}
                >
                    <span className="pr-opt-icon timer">
                        <i className="fa fa-play"> </i>
                    </span>
                    <span className="pr-opt-label">Start Timer</span>
                </div>
                <div
                    className="enter-time-button pr-opt"
                    onClick={() =>
                        TimesheetAppStore.setDropdownState('manualEntry')
                    }
                >
                    <span className="pr-opt-icon input">
                        <i className="fa fa-clock-o"> </i>
                    </span>
                    <span className="pr-opt-label">Enter Time</span>
                </div>
                <div
                    className="pr-opt"
                    onClick={() => TimesheetAppStore.setDropdownState('notes')}
                >
                    <span className="pr-opt-icon notes">
                        <i className="fa fa-sticky-note"> </i>
                    </span>
                    <span className="pr-opt-label">Add Note</span>
                </div>
                {(true || {}.timeEntryFlags.length) > 0 && (
                    <div
                        className="pr-opt"
                        onClick={() =>
                            TimesheetAppStore.setDropdownState('options')
                        }
                    >
                        <span className="pr-opt-icon time-options">
                            <i className="fa fa-code-fork"> </i>
                        </span>
                        <span className="pr-opt-label">Time Options</span>
                    </div>
                )}
                <div
                    className="pr-opt"
                    onClick={() => {
                        TimesheetAppStore.deleteTimeEntry(timesheetEntry)
                        DataStore.saveModel(timesheetEntry)
                    }}
                >
                    <span className="pr-opt-icon delete">
                        <i className="fa fa-times"> </i>
                    </span>
                    <span className="pr-opt-label">Delete Entry</span>
                </div>
            </div>
        </div>
    )
})

const TimesheetDropdownTimer = observer(({ timesheetEntry }) => {
    useEffect(() => {
        if (!TimerStore.selectedTimeEntry) {
            TimerStore.setSelectedTimeEntry(timesheetEntry)
            TimerStore.startTimer()
        }
    }, [timesheetEntry])
    if (TimerStore.selectedTimeEntry !== timesheetEntry) {
        return (
            <div className="project-dropdown" style={{ padding: '1em' }}>
                Another Timer is Running. Please save or cancel it before
                starting a new one.
            </div>
        )
    }
    return (
        <div className="project-dropdown two-btn">
            <div className="project-timer">
                <span>{TimerStore.timeDisplay}</span>
            </div>
            <div className="project-options">
                <div
                    className="pr-opt"
                    onClick={() => {
                        TimerStore.pauseTimer()
                        timesheetEntry.update({
                            numMinutes: Math.round(TimerStore.seconds / 60),
                        })
                        DataStore.saveModel(timesheetEntry)
                        TimerStore.setSelectedTimeEntry(null)
                        TimerStore.resetTimer()
                        TimesheetAppStore.setDropdownState('select')
                    }}
                >
                    <span className="pr-opt-icon notes">
                        <i className="fa fa-download"> </i>
                    </span>
                    <span className="pr-opt-label">Save</span>
                </div>
                {TimerStore.timerState === 'running' ? (
                    <div
                        className="pr-opt"
                        onClick={() => TimerStore.pauseTimer()}
                    >
                        <span className="pr-opt-icon timer">
                            <i className="fa fa-pause"> </i>
                        </span>
                        <span className="pr-opt-label">Pause</span>
                    </div>
                ) : (
                    <div
                        className="pr-opt"
                        onClick={() => TimerStore.startTimer()}
                    >
                        <span className="pr-opt-icon timer">
                            <i className="fa fa-play"> </i>
                        </span>
                        <span className="pr-opt-label">Start</span>
                    </div>
                )}
                <div
                    className="pr-opt"
                    onClick={() => {
                        TimerStore.resetTimer()
                        TimerStore.setSelectedTimeEntry(null)
                        TimesheetAppStore.setDropdownState('select')
                    }}
                >
                    <span className="pr-opt-icon delete">
                        <i className="fa fa-times"> </i>
                    </span>
                    <span className="pr-opt-label">Cancel</span>
                </div>
            </div>
        </div>
    )
})

const TimesheetDropdownManualEntry = observer(({ timesheetEntry }) => {
    const [numMinutes, setNumMinutes] = useState(timesheetEntry.numMinutes)
    return (
        <div className="project-dropdown two-btn">
            <div className="project-input">
                <input
                    className="entry-time-input__input"
                    type="text"
                    maxLength={5}
                    value={
                        isNumber(numMinutes)
                            ? formatMinutes(numMinutes)
                            : numMinutes
                    }
                    onChange={(e) => setNumMinutes(e.target.value)}
                    onBlur={(e) => setNumMinutes(parseTime(e.target.value))}
                />
            </div>
            <SaveCancelPanel
                onSave={() => {
                    timesheetEntry.update({ numMinutes })
                    DataStore.saveModel(timesheetEntry)
                    TimesheetAppStore.setDropdownState('select')
                }}
                onCancel={() => TimesheetAppStore.setDropdownState('select')}
            />
        </div>
    )
})

const TimesheetDropdownNotes = observer(({ timesheetEntry }) => {
    const [notes, setNotes] = useState(timesheetEntry.notes || '')
    return (
        <div className="project-dropdown two-btn">
            <div className="project-note">
                <textarea
                    rows="3"
                    placeholder="Text goes here..."
                    onChange={(e) => setNotes(e.target.value)}
                >
                    {notes}
                </textarea>
            </div>
            <SaveCancelPanel
                onSave={() => {
                    timesheetEntry.update({ notes })
                    DataStore.saveModel(timesheetEntry)
                    TimesheetAppStore.setDropdownState('select')
                }}
                onCancel={() => TimesheetAppStore.setDropdownState('select')}
            />
        </div>
    )
})

const TimesheetDropdownOptions = observer(({ timesheetEntry }) => {
    const [isBillable, setIsBillable] = useState(timesheetEntry.isBillable)
    const [isVariation, setIsVariation] = useState(timesheetEntry.isVariation)
    const [isOvertime, setIsOvertime] = useState(timesheetEntry.isOvertime)
    return (
        <div className="project-dropdown two-btn">
            <div className="time-entry-switches">
                {SessionStore.organisation.settings.timeEntryFlags.includes(
                    'billable'
                ) && (
                    <Checkbox
                        value={isBillable}
                        label="Billable"
                        onChange={(v) => setIsBillable(v)}
                    />
                )}
                {SessionStore.organisation.settings.timeEntryFlags.includes(
                    'variation'
                ) && (
                    <Checkbox
                        value={isVariation}
                        label="Variation"
                        onChange={(v) => setIsVariation(v)}
                    />
                )}
                {SessionStore.organisation.settings.timeEntryFlags.includes(
                    'overtime'
                ) && (
                    <Checkbox
                        value={isOvertime}
                        label="Overtime"
                        onChange={(v) => setIsOvertime(v)}
                    />
                )}
            </div>
            <SaveCancelPanel
                onSave={() => {
                    timesheetEntry.update({
                        isBillable,
                        isVariation,
                        isOvertime,
                    })
                    DataStore.saveModel(timesheetEntry)
                    TimesheetAppStore.setDropdownState('select')
                }}
                onCancel={() => TimesheetAppStore.setDropdownState('select')}
            />
        </div>
    )
})

const TimesheetDropdownLocked = observer(({ timesheetEntry }) => {
    return (
        <div className="project-dropdown two-btn">
            <div className="project-locked">
                <i className="fa fa-lock" style={{ marginRight: '1em' }} />
                <span>Time Entry Locked</span>
            </div>
        </div>
    )
})

function pad2(n) {
    return (n < 10 ? '0' : '') + n.toString()
}

function formatSeconds(seconds) {
    let hours = Math.floor(seconds / 3600)
    let minutes = Math.floor((seconds - hours * 3600) / 60)
    let s = seconds - hours * 3600 - minutes * 60
    return `${pad2(hours)}:${pad2(minutes)}:${pad2(s)}`
}

const SaveCancelPanel = observer(({ onSave, onCancel }) => {
    return (
        <div className="project-options">
            <div
                className="entry-time-input__save-button pr-opt"
                onClick={onSave}
            >
                <span className="pr-opt-icon notes">
                    <i className="fa fa-download" />
                </span>
                <span className="pr-opt-label">Save</span>
            </div>
            <div className="pr-opt" onClick={onCancel}>
                <span className="pr-opt-icon delete">
                    <i className="fa fa-times" />
                </span>
                <span className="pr-opt-label">Cancel</span>
            </div>
        </div>
    )
})

const Checkbox = ({ value, onChange, label }) => {
    return (
        <div className="time-entry-switch" onClick={() => onChange(!value)}>
            <i
                className={classNames(
                    'fa fa-fw',
                    value ? 'fa-check-square' : 'fa-square-o'
                )}
            />
            {label}
        </div>
    )
}
