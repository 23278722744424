import React from 'react'
import _ from 'lodash'
import { mergeProps } from '../../utils'
import { DateTimePicker } from 'react-widgets'
import { format } from 'date-fns'
import SessionStore from '../../State/SessionStore'
import { FormatDate } from '../../Utils/Localisation/DateFormatter'
import { ParseDate } from '../../Utils/Localisation/DateParser'

// This seems to be required for `DateTimePicker`.
import Globalize from 'globalize'
import globalizeLocalizer from 'react-widgets/lib/localizers/globalize'
globalizeLocalizer(Globalize)

export default ({
    isEditable = true,
    value,
    onChange,
    dropLeft = false,
    min,
    max,
    actionArgs,
    isValid = true,
    ...props
}) => {
    if (isEditable) {
        if (dropLeft) {
            props = mergeProps({ className: 'rw-dropleft' }, props)
        }

        return (
            <DateTimePicker
                className={!isValid ? 'invalid-date-picker' : ''}
                time={false}
                format={(d) =>
                    FormatDate(d, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                }
                value={value}
                onChange={(newDate) => {
                    var val = newDate != null ? newDate : null
                    onChange(val)
                }}
                parse={ParseDate}
                // react-widgets DateTimePicker doesn't work properly if you pass `null`
                // here (esp. `max`).
                min={min || new Date(1970, 0, 1)}
                max={max || new Date(2069, 11, 30)}
                {...props}
            />
        )
    } else {
        // Yes, moment.js format strings are different to the format strings
        // accepted by `DateTimePicker`.
        return (
            <span>
                {value != null
                    ? FormatDate(value, {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                      })
                    : '(None)'}
            </span>
        )
    }
}
