import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import SessionStore from '../State/SessionStore'
import LoadingSpinner from '../Components/LoadingSpinner'
import LoginPage from './LoginPage'
import TimesheetAppPage from './TimesheetApp/TimesheetAppPage'

export default observer(() => {
    if (!SessionStore.initialized) {
        return <LoadingSpinner />
    } else if (SessionStore.initialized && !SessionStore.authenticated) {
        return <LoginPage />
    } else {
        return <TimesheetAppPage />
    }
})
