import { data } from 'jquery'
import { observable, computed, action, makeObservable } from 'mobx'
import _ from 'lodash'
import date from '../../formulas/core/operations/date'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { FormatNumber } from '../../Utils/Localisation/NumberFormatter'
import { FormatPercent } from '../../Utils/Localisation/PercentFormatter'
import ContactCollection from '../Collections/ContactCollection'
import InvoiceCollection from '../Collections/InvoiceCollection'
import InvoiceLineItemCollection from '../Collections/InvoiceLineItemCollection'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import ProjectExpenseCollection from '../Collections/ProjectExpenseCollection'
import StaffCollection from '../Collections/StaffCollection'
import TimeEntryCollection from '../Collections/TimeEntryCollection'
import Model from './Model'
import { format } from 'date-fns'

class InvoiceLineItemModel extends Model {
    @observable projectId = null
    @observable contactId = null
    @observable invoiceId = null
    @observable phaseId = null

    @observable billingType = null
    @observable lineItemType = null

    @observable description = ''
    @observable unitCost = 0
    @observable unitQuantity = 0
    @observable isTaxed = true

    @observable staffIds = null
    @observable timesheetIds = null
    @observable expenseId = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = InvoiceLineItemCollection
        this.init(data, options)
    }

    @computed
    get invoice() {
        return InvoiceCollection.invoicesById[this.invoiceId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get contact() {
        return ContactCollection.contactsById[this.contactId]
    }

    @computed
    get expense() {
        return ProjectExpenseCollection.projectExpensesById[this.expenseId]
    }

    @computed
    get timeEntries() {
        return (this.timesheetIds || []).map(
            (tsi) => TimeEntryCollection.timeEntriesById[tsi]
        )
    }

    @computed
    get staff() {
        return (this.staffIds || []).map((si) => StaffCollection.staffsById[si])
    }

    @computed
    get taxRate() {
        return (this.invoice?.taxRatePercent || 0) / 100
    }

    @computed
    get amount() {
        return this.unitQuantity * this.unitCost || 0
    }

    @computed
    get tax() {
        return this.isTaxed ? this.amount * this.taxRate : 0
    }

    @computed
    get issueDate() {
        return this.invoice?.issueDate
    }

    @computed
    get dueDate() {
        return this.invoice?.dueDate
    }

    @computed
    get startDate() {
        return this.invoice?.startDate
    }

    @computed
    get endDate() {
        return this.invoice?.endDate
    }

    @computed
    get timeEntryStartDate() {
        return this.timeEntries.length
            ? _.min(this.timeEntries.map((te) => te?.date))
            : -Infinity
    }

    @computed
    get formattedDescription() {
        let description = this.description || ''
        Object.keys(getDescriptionCodeText).forEach((code) => {
            description = description.replaceAll(
                `[${code}]`,
                getDescriptionCodeText[code]?.(this) || ''
            )
        })
        return description
    }
}

export default InvoiceLineItemModel

export const getDescriptionCodeText = {
    project: (li) => getDescriptionCodeValue.project(li),
    projectcode: (li) => getDescriptionCodeValue.projectcode(li),
    phase: (li) => getDescriptionCodeValue.phase(li),
    phasecode: (li) => getDescriptionCodeValue.phasecode(li),
    phasefee: (li) => FormatCurrency(getDescriptionCodeValue.phasefee(li)),
    phaseprogress: (li) =>
        FormatPercent(getDescriptionCodeValue.phaseprogress(li)),
    remainingprogress: (li) =>
        FormatPercent(getDescriptionCodeValue.remainingprogress(li)),
    previousprogress: (li) =>
        FormatPercent(getDescriptionCodeValue.previousprogress(li)),
    currentbilled: (li) =>
        FormatCurrency(getDescriptionCodeValue.currentbilled(li)),
    previousbilled: (li) =>
        FormatCurrency(getDescriptionCodeValue.previousbilled(li)),
    todatebilled: (li) =>
        FormatCurrency(getDescriptionCodeValue.todatebilled(li)),
    remainingbilled: (li) =>
        FormatCurrency(getDescriptionCodeValue.remainingbilled(li)),
    quantity: (li) =>
        li.lineItemType.toLowerCase().includes('progress')
            ? FormatPercent(getDescriptionCodeValue.quantity(li))
            : FormatNumber(getDescriptionCodeValue.quantity(li)),
    hours: (li) => FormatNumber(getDescriptionCodeValue.hours(li)),
    staff: (li) => getDescriptionCodeValue.staff(li),
    // date: (li) => format(getDescriptionCodeValue.date(li), 'dd/MM/yyyy'),
    expensename: (li) => getDescriptionCodeValue.expensename(li),
    expensetotal: (li) =>
        FormatCurrency(getDescriptionCodeValue.expensetotal(li)),
    expenseprevious: (li) =>
        FormatCurrency(getDescriptionCodeValue.expenseprevious(li)),
    expensecurrent: (li) =>
        FormatCurrency(getDescriptionCodeValue.expensecurrent(li)),
    expensetodate: (li) =>
        FormatCurrency(getDescriptionCodeValue.expensetodate(li)),
    expenseremaining: (li) =>
        FormatCurrency(getDescriptionCodeValue.expenseremaining(li)),
    expenseprogress: (li) =>
        FormatPercent(getDescriptionCodeValue.expenseprogress(li)),
    expensepreviousprogress: (li) =>
        FormatPercent(getDescriptionCodeValue.expensepreviousprogress(li)),
    expenseremainingprogress: (li) =>
        FormatPercent(getDescriptionCodeValue.expenseremainingprogress(li)),
}

export const getDescriptionCodeValue = {
    project: (li) => li?.invoice?.cachedData?.project?.name,
    projectcode: (li) => li?.invoice?.cachedData?.project?.jobNumber,
    phase: (li) => li?.invoice?.cachedData?.phases[li.phaseId]?.name,
    phasecode: (li) => li?.invoice?.cachedData?.phases[li.phaseId]?.jobNumber,
    phasefee: (li) => li?.invoice?.cachedData?.phases[li.phaseId]?.fee || 0,
    phaseprogress: (li) =>
        getDescriptionCodeValue.todatebilled(li) /
            getDescriptionCodeValue.phasefee(li) || 0,
    remainingprogress: (li) =>
        1 - getDescriptionCodeValue.phaseprogress(li) || 0,
    previousprogress: (li) =>
        getDescriptionCodeValue.previousbilled(li) /
            getDescriptionCodeValue.phasefee(li) || 0,
    currentbilled: (li) =>
        _.sum(
            (li.invoice?.lineItems || [])
                .filter(
                    (li2) =>
                        li2.billingType === 'agreedFee' &&
                        li2.phase === li.phase
                )
                .map((li2) => li2.amount)
        ) || 0,
    previousbilled: (li) =>
        li?.invoice?.cachedData?.phases[li.phaseId]?.previousBilled || 0,
    todatebilled: (li) =>
        getDescriptionCodeValue.previousbilled(li) +
        getDescriptionCodeValue.currentbilled(li),
    remainingbilled: (li) =>
        getDescriptionCodeValue.phasefee(li) -
        getDescriptionCodeValue.todatebilled(li),
    quantity: (li) => li.unitQuantity,
    hours: (li) => li.unitQuantity,
    staff: function (li) {
        return li.staff.map((st) => st.fullName).join(', ')
    },
    // date: function (li) {
    //     return li.timeEntries[0].date
    // },
    expensename: (li) => li?.invoice?.cachedData?.expenses[li.expenseId]?.name,
    expensetotal: (li) =>
        li?.invoice?.cachedData?.expenses[li.expenseId]?.cost || 0,
    expenseprevious: (li) =>
        li?.invoice?.cachedData?.expenses[li.expenseId]?.previousBilled || 0,
    expensecurrent: (li) => (li) =>
        _.sum(
            (li.invoice?.lineItems || [])
                .filter(
                    (li2) =>
                        li2.billingType === 'agreedFee' &&
                        li2.expense === li.expense
                )
                .map((li2) => li2.amount)
        ) || 0,
    expensetodate: (li) =>
        getDescriptionCodeValue.expenseprevious(li) +
        getDescriptionCodeValue.expensecurrent(li),
    expenseremaining: (li) =>
        getDescriptionCodeValue.expensetotal(li) -
        getDescriptionCodeValue.expensetodate(li),
    expenseprogress: (li) =>
        getDescriptionCodeValue.expensetodate(li) /
        getDescriptionCodeValue.expensetotal(li),
    expensepreviousprogress: (li) =>
        getDescriptionCodeValue.expenseprevious(li) /
        getDescriptionCodeValue.expensetotal(li),
    expenseremainingprogress: (li) =>
        1 - getDescriptionCodeValue.expenseprogress(li),
}
