import _ from 'lodash'
import React from 'react'
import Formatter from '../../Components/Formatter'
import { qf } from '../../Queries/queryFormatter'
import pluralize from 'pluralize'
import { queryFilters } from '../../Queries/queryFilters'
import { capitalCase } from 'change-case'
import cuid from 'cuid'
import {
    canViewProjectDates,
    canViewProjectExpenseBudgets,
    canViewProjectStaffCost,
    canViewProjectFees,
    canViewProjectInvoices,
    canViewProjectExpenses,
    canViewStaffAllocations,
    canViewProjectStaffPay,
    canViewProjectStaffChargeOut,
    canViewCostCentres,
    canViewContacts,
    canViewProjectNotes,
    canViewRevenueTargets,
    canViewPrimaryContact,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

const getPhaseFilters = (report) => {
    return [
        ...report.filters
            .filter((f) => f.group === 'phase')
            .map((f) => report.columnOptionsById[f.column]?.queryFilters?.(f))
            .flat(),
    ]
}

const mergePhaseFilter = (report, query, id = 'id') => {
    query.subQueries ??= []
    query.filters ??= []
    const label = (query.label || query.collection) + 'Phases'
    return {
        ...query,
        subQueries: [
            ...query.subQueries,
            {
                label,
                collection: 'phases',
                join: `phase${capitalCase(id)} == ${label}.${id}`,
                groupBy: [`${id}`],
                fields: [['numPhases', 'count(id)']],
                filters: getPhaseFilters(report),
            },
        ],
        filters: [label + '.numPhases > 0', ...query.filters],
    }
}

export const ProjectReportColumns = (report) => ({
    name: {
        id: 'name',
        label: 'Name',
        type: 'text',
        width: 25,
        value: (row, stores) => {
            return stores.row.group
                ? stores.row.cells[stores.row.group].formattedValue
                : row.title
        },
        queryFilters: ({ operator, value }) =>
            queryFilters.text[operator]('name', value),
        component: ({ value, group, stores }) => {
            const { row, table } = stores
            if (row.group === 'totals') return 'Total'
            return (
                <div style={{ paddingLeft: `${1 * row.groupLevel}rem` }}>
                    {row.childRows.length ? (
                        <i
                            className={`fa fa-${`caret-${
                                row.expanded ? 'down' : 'right'
                            }`} fa-fw`}
                            onClick={() => table.toggleRowExpand(row)}
                        />
                    ) : null}
                    {row.rowObject.modelType === 'project' ? value : value}
                </div>
            )
        },
    },
    project: {
        id: 'project',
        label: 'Project',
        type: 'project',
        width: 25,
        value: (row) =>
            row.project || (row.modelType === 'project' ? row : null),
        queryFilters: ({ operator, value, group }) =>
            queryFilters.singleId[operator](
                group === 'project' ? 'id' : 'projectId',
                value
            ),
    },
    jobCode: {
        id: 'jobCode',
        label: 'Job code',
        type: 'text',
        width: 10,
        value: (row) => row.jobNumber,
        queryFilters: ({ operator, value }) =>
            queryFilters.text[operator]('jobNumber', value),
        aggregate: 'none',
    },
    startDate: {
        id: 'startDate',
        label: 'Start date',
        type: 'date',
        width: 10,
        value: (row) => row.startDate,
        aggregate: 'min',
        permissions: (row) =>
            canViewProjectDates(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) =>
            queryFilters.date[operator]('startDate', value),
        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: [['startDate', 'phases.startDate']],
                      subQueries: [
                          {
                              collection: 'phases',
                              join: `id == phases.projectId`,
                              groupBy: [`projectId`],
                              fields: [['startDate', 'min(startDate)']],
                          },
                      ],
                  }
                : {
                      collection: 'phases',
                      fields: ['startDate'],
                  },
        ],
    },
    endDate: {
        id: 'endDate',
        label: 'End date',
        type: 'date',
        width: 10,
        value: (row) => row.endDate,
        aggregate: 'max',
        permissions: (row) =>
            canViewProjectDates(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) =>
            queryFilters.date[operator]('endDate', value),
        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: [['endDate', 'phases.endDate']],
                      subQueries: [
                          {
                              collection: 'phases',
                              join: `id == phases.projectId`,
                              groupBy: [`projectId`],
                              fields: [['endDate', 'max(endDate)']],
                          },
                      ],
                  }
                : {
                      collection: 'phases',
                      fields: ['endDate'],
                  },
        ],
    },
    fee: {
        id: 'fee',
        label: 'Fee',
        type: 'currency',
        width: 10,
        value: (row) => row.fee,
        permissions: (row) =>
            canViewProjectFees(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('fee', value)
        },
        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: [['fee', 'phases.fee']],
                      subQueries: [
                          {
                              collection: 'phases',
                              join: `id == phases.projectId`,
                              groupBy: [`projectId`],
                              fields: [['fee', 'sum(fee)']],
                              filters: getPhaseFilters(report),
                          },
                      ],
                  }
                : {
                      collection: 'phases',
                      fields: ['fee'],
                  },
        ],
    },
    expenseBudget: {
        id: 'expenseBudget',
        label: 'Expense Budget',
        type: 'currency',
        width: 10,
        value: (row) => row.expenseBudget,
        permissions: (row) =>
            canViewProjectExpenseBudgets(
                SessionStore.user,
                row?.project || row
            ),
        queryFilters: ({ operator, value }) =>
            queryFilters.number[operator]('expenseBudget', value),
        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: [['expenseBudget', 'phases.expenseBudget']],
                      subQueries: [
                          {
                              collection: 'phases',
                              join: `id == phases.projectId`,
                              groupBy: [`projectId`],
                              fields: [['expenseBudget', 'sum(expenseBudget)']],
                              filters: getPhaseFilters(report),
                          },
                      ],
                  }
                : {
                      collection: 'phases',
                      fields: ['expenseBudget'],
                  },
        ],
    },
    costCentre: {
        id: 'costCentre',
        label: 'Cost centre',
        type: 'costCentre',
        width: 10,
        value: (row) => row.costCentre,
        permissions: (row) => canViewCostCentres(SessionStore.user),
        queryFilters: ({ operator, value }) =>
            queryFilters.singleId[operator]('costCentreId', value),
        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: ['costCentreId'],
                      chain: [
                          {
                              collection: 'costCentres',
                              join: {
                                  projects: 'costCentreId',
                                  costCentres: 'id',
                              },
                              fields: ['name'],
                          },
                      ],
                  }
                : {
                      collection: 'phases',
                      fields: [['costCentreId', 'project.costCentreId']],
                      chain: [
                          {
                              collection: 'costCentres',
                              join: {
                                  phases: 'costCentreId',
                                  costCentres: 'id',
                              },
                              fields: ['name'],
                          },
                      ],
                  },
        ],
    },
    contact: {
        id: 'contact',
        label: 'Contact',
        type: 'contact',
        width: 10,
        value: (row) => row.contact,
        permissions: (row) => canViewContacts(SessionStore.user),
        queryFilters: ({ operator, value }) =>
            queryFilters.singleId[operator]('contactId', value),
        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: ['contactId'],
                      chain: [
                          {
                              collection: 'contacts',
                              join: {
                                  projects: 'contactId',
                                  contacts: 'id',
                              },
                              fields: [
                                  'firstName',
                                  'lastName',
                                  'organisationName',
                              ],
                          },
                      ],
                  }
                : {
                      collection: 'phases',
                      fields: [['contactId', 'project.contactId']],
                      chain: [
                          {
                              collection: 'contacts',
                              join: {
                                  phases: 'contactId',
                                  contacts: 'id',
                              },
                              fields: [
                                  'firstName',
                                  'lastName',
                                  'organisationName',
                              ],
                          },
                      ],
                  },
        ],
    },
    invoiceContact: {
        id: 'invoiceContact',
        label: 'Primary Contact',
        type: 'contact',
        width: 10,
        value: (row) => row.primaryContact,
        permissions: (row) =>
            canViewContacts(SessionStore.user) && canViewPrimaryContact(),
        queryFilters: ({ operator, value }) =>
            queryFilters.singleId[operator]('primaryContactId', value),
        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: ['primaryContactId'],
                      chain: [
                          {
                              collection: 'contacts',
                              join: {
                                  projects: 'primaryContactId',
                                  contacts: 'id',
                              },
                              fields: [
                                  'firstName',
                                  'lastName',
                                  'organisationName',
                              ],
                          },
                      ],
                  }
                : {
                      collection: 'phases',
                      fields: [
                          ['primaryContactId', 'project.primaryContactId'],
                      ],
                      chain: [
                          {
                              collection: 'contacts',
                              join: {
                                  phases: 'primaryContactId',
                                  contacts: 'id',
                              },
                              fields: [
                                  'firstName',
                                  'lastName',
                                  'organisationName',
                              ],
                          },
                      ],
                  },
        ],
    },
    owner: {
        id: 'owner',
        label: 'Project Owner',
        type: 'staffMember',
        width: 15,
        value: (row) => row.owner,
        queryFilters: ({ operator, value }) =>
            queryFilters.singleId[operator]('ownerId', value),
        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: ['ownerId'],
                      chain: [
                          {
                              collection: 'staff',
                              join: {
                                  projects: 'ownerId',
                                  staff: 'id',
                              },
                              fields: ['firstName', 'lastName'],
                          },
                      ],
                  }
                : {
                      collection: 'phases',
                      fields: [['ownerId', 'project.ownerId']],
                      chain: [
                          {
                              collection: 'staff',
                              join: {
                                  phases: 'ownerId',
                                  staff: 'id',
                              },
                              fields: ['firstName', 'lastName'],
                          },
                      ],
                  },
        ],
    },
    status: {
        id: 'status',
        label: 'Status',
        type: 'status',
        width: 10,
        value: (row) => {
            return row.status || 'active'
        },
        queryFilters: ({ operator, value }) =>
            queryFilters.text[operator]('status', value),
        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: [['status', 'phases.status']],
                      subQueries: [
                          {
                              collection: 'phases',
                              join: `id == phases.projectId`,
                              groupBy: [`projectId`],
                              fields: [
                                  [
                                      'status',
                                      "('active' in arr(status)) ? 'active' : ('prospective' in arr(status)) ? 'prospective' : ('onHold' in arr(status)) ? 'onHold' : 'archived'",
                                  ],
                              ],
                              filters: [
                                  ...getPhaseFilters(report),
                                  'isRootPhase != true',
                              ],
                          },
                      ],
                  }
                : {
                      collection: 'phases',
                      fields: ['status'],
                  },
        ],
    },
    remainingFee: {
        id: 'remainingFee',
        label: 'Remaining fee',
        type: 'currency',
        width: 10,
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
        queryFilters: ({ operator, value }) =>
            queryFilters.number[operator]('(fee - revenue)', value),
        value: (row) =>
            ProjectReportColumns(report).fee.value(row) -
            ProjectReportColumns(report).revenue.value(row),
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).fee.queries(modelType, report),
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
        ],
    },
    remainingExpenseProjectBudget: {
        id: 'remainingExpenseProjectBudget',
        label: 'Remaining Expense Budget',
        type: 'currency',
        width: 10,
        queryFilters: ({ operator, value }) =>
            queryFilters.number[operator](
                '(expenseBudget - expenses - projectExpenses)',
                value
            ),
        value: (row) =>
            ProjectReportColumns(report).expenseBudget.value(row) -
            ProjectReportColumns(report).expenses.value(row) -
            ProjectReportColumns(report).projectExpenses.value(row),
        permissions: (row) =>
            ProjectReportColumns(report).expenseBudget.permissions(row) &&
            ProjectReportColumns(report).expenses.permissions(row) &&
            ProjectReportColumns(report).projectExpenses.permissions(row),
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expenseBudget.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).expenses.queries(modelType, report),
            ...ProjectReportColumns(report).projectExpenses.queries(
                modelType,
                report
            ),
        ],
    },
    latestEvent: {
        id: 'latestEvent',
        label: 'Latest Event',
        type: 'date',
        width: 10,
        value: (row) =>
            row.history.length
                ? _.max(
                      row.history
                          .filter((h) =>
                              row.modelType === 'phase'
                                  ? h.phaseId === row.id
                                  : true
                          )
                          .map((h) => new Date(h.time))
                  )
                : null,

        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: ['history'],
                  }
                : {},
        ],
    },
    latestNote: {
        id: 'latestNote',
        label: 'Latest Note',
        type: 'text',
        width: 35,
        value: (row) => {
            const notes = row.notes || []
            return notes.length
                ? _.orderBy(notes, ['date'], ['desc'])[0].description
                : null
        },
        permissions: (row) =>
            canViewProjectNotes(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) =>
            queryFilters.text[operator]('latestNoteDescription', value),
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [
                    [
                        'latestNoteDescription',
                        'join(latestNote.descriptions, "")',
                    ],
                ],
                subQueries: [
                    {
                        label: 'latestNote',
                        collection: 'projectNotes',
                        join: `id == projectNotes.${modelType}Id`,
                        groupBy: [`${modelType}Id`, 'date'],
                        fields: [['descriptions', 'arr(description)']],
                        sortBy: [['date', 'desc']],
                        limit: 1,
                    },
                ],
            },
        ],
    },
    currentPhases: {
        id: 'currentPhases',
        label: 'Current Phases',
        type: 'text',
        width: 10,
        value: (row) => row.currentPhaseTitles,
        queryFilters: ({ operator, value }) =>
            queryFilters.text[operator]('currentPhaseTitles', value),
        queries: (modelType = 'project', report) =>
            modelType === 'project'
                ? [
                      {
                          collection: 'projects',
                          fields: [
                              [
                                  'currentPhaseTitles',
                                  'join(currentPhases.titles, ", ")',
                              ],
                          ],
                          subQueries: [
                              {
                                  label: 'currentPhases',
                                  collection: 'phases',
                                  join: 'id == currentPhases.projectId',
                                  groupBy: [`projectId`],
                                  fields: [
                                      [
                                          'titles',
                                          'arr(jobNumber + ": " + name)',
                                      ],
                                  ],
                                  filter: [
                                      `startDate >= ${new Date()}`,
                                      `end <= ${new Date()}`,
                                  ],
                              },
                          ],
                      },
                  ]
                : [],
    },
    activePhases: {
        id: 'activePhases',
        label: 'Active Phases',
        type: 'phase',
        width: 10,
        value: (row) => row.activePhaseTitles,
        queryFilters: ({ operator, value }) =>
            queryFilters.text[operator]('activePhaseTitles', value),
        queries: (modelType = 'project', report) =>
            modelType === 'project'
                ? [
                      {
                          collection: 'projects',
                          fields: [
                              [
                                  'activePhaseTitles',
                                  'join(activePhases.titles, ", ")',
                              ],
                          ],
                          subQueries: [
                              {
                                  label: 'activePhases',
                                  collection: 'phases',
                                  join: 'id == activePhases.projectId',
                                  groupBy: [`projectId`],
                                  fields: [
                                      [
                                          'titles',
                                          'arr(jobNumber + ": " + name)',
                                      ],
                                  ],
                                  filter: [`status in ["active"]`],
                              },
                          ],
                      },
                  ]
                : [],
    },
    prospectivePhases: {
        id: 'prospectivePhases',
        label: 'Prospective Phases',
        type: 'phase',
        width: 10,
        value: (row) => row.prospectivePhaseTitles,
        queryFilters: ({ operator, value }) =>
            queryFilters.text[operator]('prospectivePhaseTitles', value),
        queries: (modelType = 'project', report) =>
            modelType === 'project'
                ? [
                      {
                          collection: 'projects',
                          fields: [
                              [
                                  'prospectivePhaseTitles',
                                  'join(prospectivePhases.titles, ", ")',
                              ],
                          ],
                          subQueries: [
                              {
                                  label: 'prospectivePhases',
                                  collection: 'phases',
                                  join: 'id == prospectivePhases.projectId',
                                  groupBy: [`projectId`],
                                  fields: [
                                      [
                                          'titles',
                                          'arr(jobNumber + ": " + name)',
                                      ],
                                  ],
                                  filter: [`status in ["prospective"]`],
                              },
                          ],
                      },
                  ]
                : [],
    },
    staffMembers: {
        id: 'staffMembers',
        label: 'Staff members assigned',
        type: 'staffs',
        width: 10,
        value: (row) => row.budgets.map((b) => b.staff),
        queryFilters: ({ operator, value }) =>
            queryFilters.manyIds[operator]('staffMemberIds', value),
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['staffMemberIds', 'budgetedHours.staffIds']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'budgetedHours',
                        join: `id == budgetedHours.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [['staffIds', 'arr(staffId)']],
                    }),
                ],
                chain: [
                    mergePhaseFilter(report, {
                        collection: 'budgetedHours',
                        join: {
                            [pluralize(modelType)]: 'id',
                            budgetedHours: modelType + 'Id',
                        },
                        fields: ['projectId', 'phaseId', 'staffId'],
                        chain: [
                            {
                                collection: 'staff',
                                join: {
                                    budgetedHours: 'staffId',
                                    staff: 'id',
                                },
                                fields: ['firstName', 'lastName'],
                            },
                        ],
                    }),
                ],
            },
        ],
    },
    progress: {
        id: 'progress',
        label: 'Progress',
        type: 'percent',
        width: 10,
        value: (row) =>
            ProjectReportColumns(report).revenue.value(row) /
            ProjectReportColumns(report).fee.value(row),
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(fee > 0 and fee != null ? revenue / fee : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).fee.queries(modelType, report),
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
        ],
    },
    revenue: {
        id: 'revenue',
        label: 'Revenue',
        type: 'currency',
        width: 10,
        value: (row) => row.revenue,
        permissions: (row) =>
            canViewProjectInvoices(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('revenue', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['revenue', 'invoiceLineItems.revenue']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'invoiceLineItems',
                        join: `id == invoiceLineItems.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            [
                                'revenue',
                                'sum(billingType == "agreedFee" ? amount : 0)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `invoice.issueDate >= ${qf(
                                          report.dateRange[0]
                                      )} AND invoice.issueDate <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    revenueWithVariations: {
        id: 'revenueWithVariations',
        label: 'Revenue (Variations)',
        type: 'currency',
        width: 10,
        value: (row) => row.revenueWithVariations,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('revenueWithVariations', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [
                    [
                        'revenueWithVariations',
                        'invoiceLineItems.revenueWithVariations',
                    ],
                ],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'invoiceLineItems',
                        join: `id == invoiceLineItems.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            [
                                'revenueWithVariations',
                                'sum(billingType in ["agreedFee", "variation"] ? amount : 0)',
                            ],
                        ],
                    }),
                ],
                filters: [
                    ...(isFinite(report.dateRange?.[0])
                        ? [
                              `invoice.issueDate >= ${qf(
                                  report.dateRange[0]
                              )} AND invoice.issueDate <= ${qf(
                                  report.dateRange[1]
                              )}`,
                          ]
                        : []),
                ],
            },
        ],
    },
    revenueWithVariationsReimbursements: {
        id: 'revenueWithVariationsReimbursements',
        label: 'Revenue (Variations + Reimbursements)',
        type: 'currency',
        width: 10,
        value: (row) => row.revenueWithVariationsReimbursements,
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'revenueWithVariationsReimbursements',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [
                    [
                        'revenueWithVariationsReimbursements',
                        'invoiceLineItems.revenueWithVariationsReimbursements',
                    ],
                ],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'invoiceLineItems',
                        join: `id == invoiceLineItems.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            [
                                'revenueWithVariationsReimbursements',
                                'sum(amount)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `invoice.issueDate >= ${qf(
                                          report.dateRange[0]
                                      )} AND invoice.issueDate <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    recordedHours: {
        id: 'recordedHours',
        label: 'Recorded Hours',
        type: 'number',
        width: 10,
        value: (row) => row.recordedHours,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('recordedHours', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['recordedHours', 'timeEntries.recordedHours']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'timeEntries',
                        join: `id == timeEntries.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            [
                                'recordedHours',
                                'sum(not(isVariation) ? numMinutes::numeric / 60 : 0)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    recordedHoursVariation: {
        id: 'recordedHoursVariation',
        label: 'Recorded Hours (Variation)',
        type: 'number',
        width: 10,
        value: (row) => row.recordedHoursVariation,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'recordedHoursVariation',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [
                    [
                        'recordedHoursVariation',
                        'timeEntries.recordedHoursVariation',
                    ],
                ],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'timeEntries',
                        join: `id == timeEntries.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            [
                                'recordedHoursVariation',
                                'sum(numMinutes::numeric / 60)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    hoursBudgeted: {
        id: 'hoursBudgeted',
        label: 'Hours budgeted',
        type: 'number',
        width: 10,
        value: (row) => row.hoursBudget,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('hoursBudget', value)
        },
        queries: (modelType = 'project', report) => [
            modelType === 'project'
                ? {
                      collection: 'projects',
                      fields: [['hoursBudget', 'phases.hoursBudget']],
                      subQueries: [
                          {
                              collection: 'phases',
                              join: `id == phases.projectId`,
                              groupBy: [`projectId`],
                              fields: [['hoursBudget', 'sum(hoursBudget)']],
                              filters: getPhaseFilters(report),
                          },
                      ],
                  }
                : {
                      collection: 'phases',
                      fields: ['hoursBudget'],
                  },
        ],
    },
    hoursAllocated: {
        id: 'hoursAllocated',
        label: 'Hours allocated',
        type: 'number',
        width: 10,
        value: (row) => row.hoursAllocated,
        permissions: (row) =>
            canViewStaffAllocations(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('hoursAllocated', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['hoursAllocated', 'budgetedHours.hoursAllocated']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'budgetedHours',
                        join: `id == budgetedHours.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [['hoursAllocated', 'sum(hours)']],
                    }),
                ],
            },
        ],
    },
    expenses: {
        id: 'expenses',
        label: 'Expenses (cost)',
        type: 'currency',
        width: 10,
        value: (row) => row._expenses,
        permissions: (row) =>
            canViewProjectStaffCost(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('expenses', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['expenses', 'timeEntries.expenses']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'timeEntries',
                        join: `id == timeEntries.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            [
                                'expenses',
                                'sum(not(isVariation) ? cost::numeric/100 : 0)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    projectExpenses: {
        id: 'projectExpenses',
        label: 'Project Expenses',
        type: 'currency',
        width: 10,
        value: (row) => row.projectExpenses,
        permissions: (row) =>
            canViewProjectExpenses(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('projectExpenses', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['projectExpenses', 'projectExpenses.cost']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'projectExpenses',
                        join: `id == projectExpenses.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [['cost', 'sum(cost)']],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `endDate >= ${qf(
                                          report.dateRange[0]
                                      )} AND startDate <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    expensesVariation: {
        id: 'expensesVariation',
        label: 'Expenses (cost) + Variation Cost',
        type: 'currency',
        width: 10,
        value: (row) => row.expensesVariation,
        permissions: (row) =>
            ProjectReportColumns(report).expenses.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('expensesVariation', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [
                    ['expensesVariation', 'timeEntries.expensesVariation'],
                ],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'timeEntries',
                        join: `id == timeEntries.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            ['expensesVariation', 'sum(cost::numeric/100)'],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    expensesVariationProject: {
        id: 'expensesVariationProject',
        label: 'Expenses (cost) + Variation Cost + Project Expenses',
        type: 'currency',
        width: 10,
        value: (row) =>
            ProjectReportColumns(report).expensesVariation.value(row) +
            ProjectReportColumns(report).projectExpenses.value(row),
        permissions: (row) =>
            ProjectReportColumns(report).expenses.permissions(row) &&
            ProjectReportColumns(report).projectExpenses.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'expensesVariation + projectExpenses',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expensesVariation.queries(
                modelType
            ),
            ...ProjectReportColumns(report).projectExpenses.queries(
                modelType,
                report
            ),
        ],
    },
    expensesProject: {
        id: 'expensesProject',
        label: 'Expenses (cost) + Project Expenses',
        type: 'currency',
        width: 10,
        value: (row) =>
            ProjectReportColumns(report).expenses.value(row) +
            ProjectReportColumns(report).projectExpenses.value(row),
        permissions: (row) =>
            ProjectReportColumns(report).expenses.permissions(row) &&
            ProjectReportColumns(report).projectExpenses.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'expenses + projectExpenses',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expenses.queries(modelType, report),
            ...ProjectReportColumns(report).projectExpenses.queries(
                modelType,
                report
            ),
        ],
    },
    labourExpense: {
        id: 'labourExpense',
        label: 'Labour expense',
        type: 'currency',
        width: 10,
        value: (row) => row.labourExpense,
        permissions: (row) =>
            canViewProjectStaffPay(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('labourExpense', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['labourExpense', 'timeEntries.labourExpense']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'timeEntries',
                        join: `id == timeEntries.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            [
                                'labourExpense',
                                'sum(not(isVariation) ? pay::numeric/100 : 0)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    chargeOut: {
        id: 'chargeOut',
        label: 'Charge-out',
        type: 'currency',
        width: 10,
        value: (row) => row.chargeOut,
        permissions: (row) =>
            canViewProjectStaffChargeOut(
                SessionStore.user,
                row?.project || row
            ),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('chargeOut', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['chargeOut', 'timeEntries.chargeOut']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'timeEntries',
                        join: `id == timeEntries.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            [
                                'chargeOut',
                                'sum(not(isVariation) ? chargeOut::numeric/100 : 0)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    chargeOutVariation: {
        id: 'chargeOutVariation',
        label: 'Charge-out (Variations)',
        type: 'currency',
        width: 10,
        value: (row) => row.chargeOutVariation,
        permissions: (row) =>
            ProjectReportColumns(report).chargeOut.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('chargeOutVariation', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [
                    ['chargeOutVariation', 'timeEntries.chargeOutVariation'],
                ],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'timeEntries',
                        join: `id == timeEntries.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            [
                                'chargeOutVariation',
                                'sum(chargeOut::numeric/100)',
                            ],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    actualVsBudgetedHours: {
        id: 'actualVsBudgetedHours',
        label: 'Actual / Budgeted Hours',
        type: 'progressBar',
        width: 20,
        value: (row) => {
            return {
                numerator:
                    ProjectReportColumns(report).recordedHours.value(row),
                denominator:
                    ProjectReportColumns(report).hoursBudgeted.value(row),
            }
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(hoursBudget > 0 and hoursBudget != null ? recordedHours / hoursBudget : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).recordedHours.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).hoursBudgeted.queries(
                modelType,
                report
            ),
        ],
    },
    actualVsBudgetedHoursVariation: {
        id: 'actualVsBudgetedHoursVariation',
        label: 'Actual (Variation) / Budgeted Hours',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator:
                ProjectReportColumns(report).recordedHoursVariation.value(row),
            denominator: ProjectReportColumns(report).hoursBudgeted.value(row),
        }),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(hoursBudget > 0 and hoursBudget != null ? recordedHoursVariation / hoursBudget : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).recordedHoursVariation.queries(
                modelType
            ),
            ...ProjectReportColumns(report).hoursBudgeted.queries(
                modelType,
                report
            ),
        ],
    },
    actualProjectVsBudgetedExpenses: {
        id: 'actualProjectVsBudgetedExpenses',
        label: 'Actual (Cost + Project) / Budgeted Expenses',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator: ProjectReportColumns(report).expensesProject.value(row),
            denominator: ProjectReportColumns(report).expenseBudget.value(row),
        }),
        permissions: (row) =>
            ProjectReportColumns(report).expensesProject.permissions(row) &&
            ProjectReportColumns(report).expenseBudget.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(expenseBudget > 0 and expenseBudget != null ? (expenses + projectExpenses) / expenseBudget : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expensesProject.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).expenseBudget.queries(
                modelType,
                report
            ),
        ],
    },
    actualVariationProjectVsBudgetedExpenses: {
        id: 'actualVariationProjectVsBudgetedExpenses',
        label: 'Actual (Cost + Project + Variation) / Budgeted Expenses',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator:
                ProjectReportColumns(report).expensesVariationProject.value(
                    row
                ),
            denominator: ProjectReportColumns(report).expenseBudget.value(row),
        }),
        permissions: (row) =>
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ) && ProjectReportColumns(report).expenseBudget.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(expenseBudget > 0 and expenseBudget != null ? (expensesVariation + projectExpenses) / expenseBudget : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expensesVariationProject.queries(
                modelType
            ),
            ...ProjectReportColumns(report).expenseBudget.queries(
                modelType,
                report
            ),
        ],
    },
    actualVariationVsBudgetedExpenses: {
        id: 'actualVariationVsBudgetedExpenses',
        label: 'Actual (Cost) / Budgeted Expenses',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator:
                ProjectReportColumns(report).expensesVariation.value(row),
            denominator: ProjectReportColumns(report).expenseBudget.value(row),
        }),
        permissions: (row) =>
            ProjectReportColumns(report).expensesVariation.permissions(row) &&
            ProjectReportColumns(report).expenseBudget.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(expenseBudget > 0 and expenseBudget != null ? expensesVariation / expenseBudget : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expensesVariation.queries(
                modelType
            ),
            ...ProjectReportColumns(report).expenseBudget.queries(
                modelType,
                report
            ),
        ],
    },
    actualVsBudgetedExpenses: {
        id: 'actualVsBudgetedExpenses',
        label: 'Actual (Cost) / Budgeted Expenses',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator: ProjectReportColumns(report).expenses.value(row),
            denominator: ProjectReportColumns(report).expenseBudget.value(row),
        }),
        permissions: (row) =>
            ProjectReportColumns(report).expenses.permissions(row) &&
            ProjectReportColumns(report).expenseBudget.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(expenseBudget > 0 and expenseBudget != null ? expenses / expenseBudget : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expenses.queries(modelType, report),
            ...ProjectReportColumns(report).expenseBudget.queries(
                modelType,
                report
            ),
        ],
    },
    revenueVsFee: {
        id: 'revenueVsFee',
        label: 'Revenue / Fee',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator: ProjectReportColumns(report).revenue.value(row),
            denominator: ProjectReportColumns(report).fee.value(row),
        }),
        format: Formatter.currency,
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(fee > 0 and fee != null ? revenue / fee : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
        ],
    },
    percentRevenueVsFee: {
        id: 'percentRevenueVsFee',
        label: 'Percent Revenue / Fee',
        type: 'percent',
        width: 10,
        value: (row) =>
            ProjectReportColumns(report).fee.value(row)
                ? ProjectReportColumns(report).revenue.value(row) /
                  ProjectReportColumns(report).fee.value(row)
                : 0,
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(fee > 0 and fee != null ? revenue / fee : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
        ],
    },
    costProjectVsFee: {
        id: 'costProjectVsFee',
        label: 'Expenses (Cost + Project) / Fee',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator: ProjectReportColumns(report).expensesProject.value(row),
            denominator: ProjectReportColumns(report).fee.value(row),
        }),
        permissions: (row) =>
            ProjectReportColumns(report).expensesProject.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(fee > 0 and fee != null ? (expenses + projectExpenses) / fee : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expensesProject.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
        ],
        format: Formatter.currency,
    },
    costVsFee: {
        id: 'costVsFee',
        label: 'Expense (cost) / Fee',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator: ProjectReportColumns(report).expenses.value(row),
            denominator: ProjectReportColumns(report).fee.value(row),
        }),
        permissions: (row) =>
            ProjectReportColumns(report).expenses.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(fee > 0 and fee != null ? expenses / fee : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expenses.queries(modelType, report),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
        ],
        format: Formatter.currency,
    },
    payVsFee: {
        id: 'payVsFee',
        label: 'Expense (labour) / Fee',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator: ProjectReportColumns(report).labourExpense.value(row),
            denominator: ProjectReportColumns(report).fee.value(row),
        }),
        permissions: (row) =>
            ProjectReportColumns(report).labourExpense.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(fee > 0 and fee != null ? labourExpense / fee : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).labourExpense.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
        ],
        format: Formatter.currency,
    },
    chargeOutVsFee: {
        id: 'chargeOutVsFee',
        label: 'Charge Out / Fee',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator: ProjectReportColumns(report).chargeOut.value(row),
            denominator: ProjectReportColumns(report).fee.value(row),
        }),
        permissions: (row) =>
            ProjectReportColumns(report).chargeOut.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(fee > 0 and fee != null ? chargeOut / fee : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).chargeOut.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
        ],
        format: Formatter.currency,
    },
    chargeOutVariationVsFee: {
        id: 'chargeOutVariationVsFee',
        label: 'Charge Out (Variations) / Fee',
        type: 'progressBar',
        width: 20,
        value: (row) => ({
            numerator:
                ProjectReportColumns(report).chargeOutVariation.value(row),
            denominator: ProjectReportColumns(report).fee.value(row),
        }),
        permissions: (row) =>
            ProjectReportColumns(report).chargeOutVariation.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(fee > 0 and fee != null ? chargeOutVariation / fee : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).chargeOutVariation.queries(
                modelType
            ),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
        ],
        format: Formatter.currency,
    },
    percentOfTotalHours: {
        id: 'percentOfTotalHours',
        label: 'Percentage of total hours',
        type: 'percent',
        width: 10,
        value: (row, stores) => {
            return (
                ProjectReportColumns(report).recordedHours.value(row) /
                stores.table.totalsRow.cells['recordedHours'].value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).recordedHours.queries(
                modelType,
                report
            ),
        ],
    },
    percentOfTotalRevenue: {
        id: 'percentOfTotalRevenue',
        label: 'Percentage of total revenue',
        type: 'percent',
        width: 10,
        value: (row, stores) => {
            return (
                ProjectReportColumns(report).revenue.value(row) /
                stores.table.totalsRow.cells['revenue'].value
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row),
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
        ],
    },
    percentOfTotalExpenses: {
        id: 'percentOfTotalExpenses',
        label: 'Percentage of total expenses (cost)',
        type: 'percent',
        width: 10,
        value: (row, stores) => {
            return (
                ProjectReportColumns(report).expensesProject.value(row) /
                stores.table.totalsRow.cells['expensesProject'].value
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).expensesProject.permissions(row),
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expensesProject.queries(
                modelType,
                report
            ),
        ],
    },
    percentOfTotalExpensesLabour: {
        id: 'percentOfTotalExpensesLabour',
        label: 'Percentage of total expenses (labour)',
        type: 'percent',
        width: 10,
        value: (row, stores) => {
            return (
                ProjectReportColumns(report).labourExpense.value(row) /
                stores.table.totalsRow.cells['labourExpense'].value
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).labourExpense.permissions(row),
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).labourExpense.queries(
                modelType,
                report
            ),
        ],
    },
    profit: {
        id: 'profit',
        label: 'Profit (cost + project expenses)',
        type: 'currency',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).revenue.value(row) -
                ProjectReportColumns(report).expensesProject.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).expensesProject.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'revenue - (expenses + projectExpenses)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
            ...ProjectReportColumns(report).expensesProject.queries(
                modelType,
                report
            ),
        ],
    },
    profitCost: {
        id: 'profitCost',
        label: 'Profit (cost)',
        type: 'currency',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).revenue.value(row) -
                ProjectReportColumns(report).expenses.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).expenses.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('revenue - expenses', value)
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
            ...ProjectReportColumns(report).expenses.queries(modelType, report),
        ],
    },
    profitLabour: {
        id: 'profitLabour',
        label: 'Profit (labour)',
        type: 'currency',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).revenue.value(row) -
                ProjectReportColumns(report).labourExpense.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).labourExpense.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'revenue - labourExpense',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
            ...ProjectReportColumns(report).labourExpense.queries(
                modelType,
                report
            ),
        ],
    },
    profitFee: {
        id: 'profitFee',
        label: 'Profit (fee)',
        type: 'currency',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).fee.value(row) -
                ProjectReportColumns(report).expensesProject.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).expensesProject.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'fee - (expenses + projectExpenses)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expensesProject.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
        ],
    },
    profitFeeVariation: {
        id: 'profitFeeVariation',
        label: 'Profit (fee, variation & project expenses)',
        type: 'currency',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).fee.value(row) -
                ProjectReportColumns(report).expensesVariationProject.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'fee - (expensesVariation + projectExpenses)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).expensesVariationProject.queries(
                modelType
            ),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
        ],
    },
    profitMargin: {
        id: 'profitMargin',
        label: 'Profit margin (cost + project expenses)',
        type: 'percent',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).profit.value(row) /
                ProjectReportColumns(report).revenue.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).profit.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(revenue > 0 and revenue != null ? (revenue - (expenses + projectExpenses)) / revenue : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
            ...ProjectReportColumns(report).profit.queries(modelType, report),
        ],
    },
    profitMarginCost: {
        id: 'profitMarginCost',
        label: 'Profit margin (cost)',
        type: 'percent',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).profitCost.value(row) /
                ProjectReportColumns(report).revenue.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).profitCost.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(revenue > 0 and revenue != null ? (revenue - expenses) / revenue : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).profitCost.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
        ],
    },
    profitMarginLabour: {
        id: 'profitMarginLabour',
        label: 'Profit margin (labour)',
        type: 'percent',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).profitLabour.value(row) /
                ProjectReportColumns(report).revenue.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).profitLabour.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(revenue > 0 and revenue != null ? (revenue - labourExpense) / revenue : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).profitLabour.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
        ],
    },
    profitMarginFee: {
        id: 'profitMarginFee',
        label: 'Profit margin (fee)',
        type: 'percent',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).profitFee.value(row) /
                ProjectReportColumns(report).fee.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).profitFee.permissions(row) &&
            ProjectReportColumns(report).fee.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(fee > 0 and fee != null ? (fee - (expenses + projectExpenses)) / fee : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).profitFee.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
        ],
    },
    markup: {
        id: 'markup',
        label: 'Markup (cost + project expenses)',
        type: 'percent',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).profit.value(row) /
                ProjectReportColumns(report).expensesProject.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).profit.permissions(row) &&
            ProjectReportColumns(report).expensesProject.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '((expenses + projectExpenses) > 0 and (expenses + projectExpenses) != null ? (revenue - (expenses + projectExpenses)) / (expenses + projectExpenses) : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).profit.queries(modelType, report),
            ...ProjectReportColumns(report).expensesProject.queries(
                modelType,
                report
            ),
        ],
    },
    markupCost: {
        id: 'markupCost',
        label: 'Markup (cost)',
        type: 'percent',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).profitCost.value(row) /
                ProjectReportColumns(report).expenses.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).profitCost.permissions(row) &&
            ProjectReportColumns(report).expenses.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(expenses > 0 and expenses != null ? (revenue - expenses) / expenses : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).profitCost.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).expenses.queries(modelType, report),
        ],
    },
    markupLabour: {
        id: 'markupLabour',
        label: 'Markup (labour)',
        type: 'percent',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).profitLabour.value(row) /
                ProjectReportColumns(report).labourExpense.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).profitLabour.permissions(row) &&
            ProjectReportColumns(report).labourExpense.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(labourExpense > 0 and labourExpense != null ? (revenue - labourExpense) / labourExpense : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).profitLabour.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).labourExpense.queries(
                modelType,
                report
            ),
        ],
    },
    revenuePerHour: {
        id: 'revenuePerHour',
        label: 'Revenue per hour',
        type: 'currency',
        width: 10,
        value: (row) => {
            return ProjectReportColumns(report).recordedHours.value(row)
                ? ProjectReportColumns(report).revenue.value(row) /
                      ProjectReportColumns(report).recordedHours.value(row)
                : 0
        },
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(recordedHours > 0 and recordedHours != null ? revenue / recordedHours : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
            ...ProjectReportColumns(report).recordedHours.queries(
                modelType,
                report
            ),
        ],
    },
    revenueVariationPerHour: {
        id: 'revenueVariationPerHour',
        label: 'Revenue per hour (variations)',
        type: 'currency',
        width: 10,
        value: (row) => {
            return ProjectReportColumns(report).recordedHoursVariation.value(
                row
            )
                ? ProjectReportColumns(report).revenueWithVariations.value(
                      row
                  ) /
                      ProjectReportColumns(report).recordedHoursVariation.value(
                          row
                      )
                : 0
        },
        permissions: (row) =>
            ProjectReportColumns(report).revenueWithVariations.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(recordedHoursVariation > 0 and recordedHoursVariation != null ? revenueWithVariations / recordedHoursVariation : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenueWithVariations.queries(
                modelType
            ),
            ...ProjectReportColumns(report).recordedHoursVariation.queries(
                modelType
            ),
        ],
    },
    revenueVariationReimbursementPerHour: {
        id: 'revenueVariationReimbursementPerHour',
        label: 'Revenue per hour (variations + reimbursements)',
        type: 'currency',
        width: 10,
        value: (row) => {
            return ProjectReportColumns(report).recordedHoursVariation.value(
                row
            )
                ? ProjectReportColumns(
                      report
                  ).revenueWithVariationsReimbursements.value(row) /
                      ProjectReportColumns(report).recordedHoursVariation.value(
                          row
                      )
                : 0
        },
        permissions: (row) =>
            ProjectReportColumns(
                report
            ).revenueWithVariationsReimbursements.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(recordedHoursVariation > 0 and recordedHoursVariation != null ? revenueWithVariationsReimbursements / recordedHoursVariation : 0)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(
                report
            ).revenueWithVariationsReimbursements.queries(modelType, report),
            ...ProjectReportColumns(report).recordedHoursVariation.queries(
                modelType
            ),
        ],
    },
    completionDate: {
        id: 'completionDate',
        label: 'Completion date',
        type: 'date',
        width: 10,
        value: (row) => {
            return ProjectReportColumns(report).fee.value(row) &&
                ProjectReportColumns(report).revenue.value(row) /
                    ProjectReportColumns(report).fee.value(row) >=
                    1
                ? ProjectReportColumns(report).latestRevenueDate.value(row)
                : null
        },
        permissions: (row) =>
            ProjectReportColumns(report).fee.permissions(row) &&
            ProjectReportColumns(report).revenue.permissions(row),
        queryFilters: ({ operator, value }) =>
            queryFilters.date[operator](
                'fee > 0 and (revenue / fee) > 1 ? latestInvoiceDate : null',
                value
            ),
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
            ...ProjectReportColumns(report).fee.queries(modelType, report),
            ...ProjectReportColumns(report).latestRevenueDate.queries(
                modelType
            ),
        ],
    },
    latestInvoicedPhases: {
        id: 'latestInvoicedPhases',
        label: 'Latest Invoiced Phases',
        type: 'text',
        width: 15,
        value: (row) => {
            return row.latestInvoicedPhases
        },
        permissions: (row) =>
            canViewProjectInvoices(SessionStore.sessionUser, row),
        queryFilters: ({ operator, value }) =>
            queryFilters.text[operator]('latestInvoicedPhaseNames', value),
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [
                    // [
                    //     'latestInvoicedPhases',
                    //     'latestInvoicedLineItems.phaseIds',
                    // ],
                    [
                        'latestInvoicedPhaseNames',
                        'join(latestInvoicedLineItems.phaseNames, ", ")',
                    ],
                ],
                subQueries: [
                    {
                        collection: 'invoiceLineItems',
                        label: 'latestInvoicedLineItems',
                        join: `id == latestInvoicedLineItems.${modelType}Id`,
                        groupBy: [`${modelType}Id`, 'invoice.issueDate'],
                        fields: [
                            // ['phaseIds', 'arr(phaseId)'],
                            [
                                'phaseNames',
                                'arr(phase.jobNumber + ": " + phase.name)',
                            ],
                        ],
                        sortBy: [['invoice.issueDate', 'desc']],
                        limit: 1,
                    },
                ],
            },
        ],
    },
    latestRevenueDate: {
        id: 'latestRevenueDate',
        label: 'Latest Revenue Date',
        type: 'date',
        width: 10,
        value: (row) => {
            return row.latestInvoiceDate
        },
        permissions: (row) =>
            canViewProjectInvoices(SessionStore.sessionUser, row),
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [
                    ['latestInvoiceDate', 'invoiceLineItems.latestInvoiceDate'],
                ],
                subQueries: [
                    {
                        collection: 'invoiceLineItems',
                        join: `id == invoiceLineItems.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            ['latestInvoiceDate', 'max(invoice.issueDate)'],
                        ],
                    },
                ],
            },
        ],
    },
    projectedHours: {
        id: 'projectedHours',
        label: 'Projected Hours',
        type: 'number',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).recordedHours.value(row) +
                ProjectReportColumns(report).futureHours.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).futureHours.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'recordedHours + futureHours',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).recordedHours.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).futureHours.queries(
                modelType,
                report
            ),
        ],
    },
    futureHours: {
        id: 'futureHours',
        label: 'Future Hours',
        type: 'number',
        width: 10,
        value: (row) => {
            return row.futureHours
        },
        permissions: (row) =>
            canViewStaffAllocations(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('futureHours', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['futureHours', 'allocations.futureHours']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'allocations',
                        join: `id == allocations.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            ['futureHours', 'sum(numMinutes::numeric/60)'],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    futureLabour: {
        id: 'futureLabour',
        label: 'Future Labour Expense',
        type: 'number',
        width: 10,
        value: (row) => {
            return row.futureLabour
        },
        permissions: (row) =>
            canViewStaffAllocations(SessionStore.user, row?.project || row) &&
            canViewProjectStaffPay(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('futureLabour', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['futureLabour', 'allocations.futureLabour']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'allocations',
                        join: `id == allocations.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [['futureLabour', 'sum(pay::numeric/100)']],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    futureCost: {
        id: 'futureCost',
        label: 'Future Expense',
        type: 'number',
        width: 10,
        value: (row) => {
            return row.futureCost
        },
        permissions: (row) =>
            canViewStaffAllocations(SessionStore.user, row?.project || row) &&
            canViewProjectStaffCost(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('futureCost', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['futureCost', 'allocations.futureCost']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'allocations',
                        join: `id == allocations.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [['futureCost', 'sum(cost::numeric/100)']],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    futureChargeOut: {
        id: 'futureChargeOut',
        label: 'Future Charge Out',
        type: 'number',
        width: 10,
        value: (row) => {
            return row.futureChargeOut
        },
        permissions: (row) =>
            canViewStaffAllocations(SessionStore.user, row?.project || row) &&
            canViewProjectStaffChargeOut(
                SessionStore.user,
                row?.project || row
            ),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('futureChargeOut', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['futureChargeOut', 'allocations.futureChargeOut']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'allocations',
                        join: `id == allocations.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [
                            ['futureChargeOut', 'sum(chargeOut::numeric/100)'],
                        ],
                        filters: [
                            ...(isFinite(report.dateRange?.[0])
                                ? [
                                      `date >= ${qf(
                                          report.dateRange[0]
                                      )} AND date <= ${qf(
                                          report.dateRange[1]
                                      )}`,
                                  ]
                                : []),
                        ],
                    }),
                ],
            },
        ],
    },
    futureRevenue: {
        id: 'futureRevenue',
        label: 'Future Revenue',
        type: 'currency',
        width: 10,
        value: (row) => {
            return row.futureRevenue
        },
        permissions: (row) =>
            canViewRevenueTargets(SessionStore.user, row?.project || row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('futureRevenue', value)
        },
        queries: (modelType = 'project', report) => [
            {
                collection: pluralize(modelType),
                fields: [['futureRevenue', 'revenueTargets.futureRevenue']],
                subQueries: [
                    mergePhaseFilter(report, {
                        collection: 'revenueTargets',
                        join: `id == revenueTargets.${modelType}Id`,
                        groupBy: [`${modelType}Id`],
                        fields: [['futureRevenue', 'sum(amount)']],
                    }),
                ],
                filters: [
                    ...(isFinite(report.dateRange?.[0])
                        ? [
                              `date >= ${qf(
                                  report.dateRange[0]
                              )} AND date <= ${qf(report.dateRange[1])}`,
                          ]
                        : []),
                ],
            },
        ],
    },
    projectedRevenue: {
        id: 'projectedRevenue',
        label: 'Projected Revenue',
        type: 'currency',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).revenue.value(row) +
                ProjectReportColumns(report).futureRevenue.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).revenue.permissions(row) &&
            ProjectReportColumns(report).futureRevenue.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'revenue + futureRevenue',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenue.queries(modelType, report),
            ...ProjectReportColumns(report).futureRevenue.queries(
                modelType,
                report
            ),
        ],
    },
    projectedExpense: {
        id: 'projectedExpense',
        label: 'Projected Expense (Cost)',
        type: 'currency',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).futureCost.value(row) +
                ProjectReportColumns(report).expenses.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).futureCost.permissions(row) &&
            ProjectReportColumns(report).expenses.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('futureCost + expenses', value)
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).futureCost.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).expenses.queries(modelType, report),
        ],
    },
    projectedLabourExpense: {
        id: 'projectedLabourExpense',
        label: 'Projected Labour Expense',
        type: 'currency',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).futureLabour.value(row) +
                ProjectReportColumns(report).labourExpense.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).futureLabour.permissions(row) &&
            ProjectReportColumns(report).labourExpense.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'futureLabour + labourExpense',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).futureLabour.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).labourExpense.queries(
                modelType,
                report
            ),
        ],
    },
    projectedChargeOut: {
        id: 'projectedChargeOut',
        label: 'Projected Charge Out',
        type: 'currency',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).futureChargeOut.value(row) +
                ProjectReportColumns(report).chargeOut.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).futureChargeOut.permissions(row) &&
            ProjectReportColumns(report).chargeOut.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'futureChargeOut + chargeOut',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).futureChargeOut.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).chargeOut.queries(
                modelType,
                report
            ),
        ],
    },
    projectedProfit: {
        id: 'projectedProfit',
        label: 'Projected Profit',
        type: 'currency',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).projectedRevenue.value(row) -
                ProjectReportColumns(report).projectedExpense.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).projectedRevenue.permissions(row) &&
            ProjectReportColumns(report).projectedExpense.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(revenue + futureRevenue) - (futureCost + expenses)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).projectedRevenue.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).projectedExpense.queries(
                modelType,
                report
            ),
        ],
    },
    projectedProfitMargin: {
        id: 'projectedProfitMargin',
        label: 'Projected Profit Margin',
        type: 'percent',
        width: 10,
        value: (row) => {
            return (
                ProjectReportColumns(report).projectedProfit.value(row) /
                ProjectReportColumns(report).projectedRevenue.value(row)
            )
        },
        permissions: (row) =>
            ProjectReportColumns(report).projectedProfit.permissions(row) &&
            ProjectReportColumns(report).projectedRevenue.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(revenue + futureRevenue) > 0 ? ((revenue + futureRevenue) - (futureCost + expenses)) / (revenue + futureRevenue) : 0',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).projectedRevenue.queries(
                modelType,
                report
            ),
            ...ProjectReportColumns(report).projectedProfit.queries(
                modelType,
                report
            ),
        ],
    },
    profitVariationProject: {
        id: 'profitVariationProject',
        label: 'Profit (Cost + Variation + Project)',
        type: 'currency',
        width: 10,
        value: (row) =>
            ProjectReportColumns(report).revenueWithVariations.value(row) -
            ProjectReportColumns(report).expensesVariationProject.value(row),
        permissions: (row) =>
            ProjectReportColumns(report).revenueWithVariations.permissions(
                row
            ) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'revenueWithVariations - (expensesVariation + projectExpenses)',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenueWithVariations.queries(
                modelType
            ),
            ...ProjectReportColumns(report).expensesVariationProject.queries(
                modelType
            ),
        ],
    },
    profitMarginVariationProject: {
        id: 'profitMarginVariationProject',
        label: 'Profit Margin (Cost + Variation + Project)',
        type: 'percent',
        width: 10,
        value: (row) =>
            ProjectReportColumns(report).profitVariationProject.value(row) /
            ProjectReportColumns(report).revenueWithVariations.value(row),
        permissions: (row) =>
            ProjectReportColumns(report).profitVariationProject.permissions(
                row
            ) &&
            ProjectReportColumns(report).revenueWithVariations.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'revenueWithVariations > 0 ? (revenueWithVariations - (expensesVariation + projectExpenses)) / revenueWithVariations : 0',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).profitVariationProject.queries(
                modelType
            ),
            ...ProjectReportColumns(report).revenueWithVariations.queries(
                modelType
            ),
        ],
    },
    markupVariationProject: {
        id: 'markupVariationProject',
        label: 'Markup (Cost + Variation + Project)',
        type: 'percent',
        width: 10,
        value: (row) =>
            ProjectReportColumns(report).profitVariationProject.value(row) /
            ProjectReportColumns(report).expensesVariationProject.value(row),
        permissions: (row) =>
            ProjectReportColumns(report).profitVariationProject.permissions(
                row
            ) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(expensesVariation + projectExpenses) > 0 ? (revenueWithVariations - (expensesVariation + projectExpenses)) / (expensesVariation + projectExpenses) : 0',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).profitVariationProject.queries(
                modelType
            ),
            ...ProjectReportColumns(report).expensesVariationProject.queries(
                modelType
            ),
        ],
    },
    expensesVsRevenueVariationProject: {
        id: 'expensesVsRevenueVariationProject',
        label: 'Expenses / Revenue (Cost + Variation + Project)',
        type: 'progressBar',
        width: 20,
        value: (row) => {
            return {
                numerator:
                    ProjectReportColumns(report).expensesVariationProject.value(
                        row
                    ),
                denominator:
                    ProjectReportColumns(report).revenueWithVariations.value(
                        row
                    ),
            }
        },
        permissions: (row) =>
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ) &&
            ProjectReportColumns(report).revenueWithVariations.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'revenueWithVariations > 0 ? (expensesVariation + projectExpenses) / revenueWithVariations : 0',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenueWithVariations.queries(
                modelType
            ),
            ...ProjectReportColumns(report).expensesVariationProject.queries(
                modelType
            ),
        ],
    },
    revenueVsExpensesVariationProject: {
        id: 'revenueVsExpensesVariationProject',
        label: 'Revenue / Expenses (Cost + Variation + Project)',
        type: 'progressBar',
        width: 20,
        value: (row) => {
            return {
                numerator:
                    ProjectReportColumns(report).revenueWithVariations.value(
                        row
                    ),
                denominator:
                    ProjectReportColumns(report).expensesVariationProject.value(
                        row
                    ),
            }
        },
        permissions: (row) =>
            ProjectReportColumns(report).revenueWithVariations.permissions(
                row
            ) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(expensesVariation + projectExpenses) > 0 ? revenueWithVariations / (expensesVariation + projectExpenses) : 0',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenueWithVariations.queries(
                modelType
            ),
            ...ProjectReportColumns(report).expensesVariationProject.queries(
                modelType
            ),
        ],
    },
    profitVsRevenueVariationProject: {
        id: 'profitVsRevenueVariationProject',
        label: 'Profit / Revenue (Cost + Variation + Project)',
        type: 'progressBar',
        width: 20,
        value: (row) => {
            return {
                numerator:
                    ProjectReportColumns(report).profitVariationProject.value(
                        row
                    ),
                denominator:
                    ProjectReportColumns(report).revenueWithVariations.value(
                        row
                    ),
            }
        },
        permissions: (row) =>
            ProjectReportColumns(report).profitVariationProject.permissions(
                row
            ) &&
            ProjectReportColumns(report).revenueWithVariations.permissions(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'revenueWithVariations > 0 ? (revenueWithVariations - (expensesVariation + projectExpenses)) / revenueWithVariations : 0',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).revenueWithVariations.queries(
                modelType
            ),
            ...ProjectReportColumns(report).profitVariationProject.queries(
                modelType
            ),
        ],
    },
    profitVsExpensesVariationProject: {
        id: 'profitVsExpensesVariationProject',
        label: 'Profit / Expenses (Cost + Variation + Project)',
        type: 'progressBar',
        width: 20,
        value: (row) => {
            return {
                numerator:
                    ProjectReportColumns(report).profitVariationProject.value(
                        row
                    ),
                denominator:
                    ProjectReportColumns(report).expensesVariationProject.value(
                        row
                    ),
            }
        },
        permissions: (row) =>
            ProjectReportColumns(report).profitVariationProject.permissions(
                row
            ) &&
            ProjectReportColumns(report).expensesVariationProject.permissions(
                row
            ),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                '(expensesVariation + projectExpenses) > 0 ? (revenueWithVariations - (expensesVariation + projectExpenses)) / (expensesVariation + projectExpenses) : 0',
                value
            )
        },
        queries: (modelType = 'project', report) => [
            ...ProjectReportColumns(report).profitVariationProject.queries(
                modelType
            ),
            ...ProjectReportColumns(report).expensesVariationProject.queries(
                modelType
            ),
        ],
    },
})
