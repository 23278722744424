import {
    add,
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    endOfYear,
    getWeek,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
    sub,
} from 'date-fns'
import pluralize from 'pluralize'
import _ from 'lodash'
import { duration } from 'moment'
import { durationToUnit } from '@ryki/datemath'
import EqualityComparator from './EqualityComparator'

const transformForEquality =
    (type, func) =>
    (...params) => {
        const newParams = []
        params.forEach((p) => {
            if (type === 'date' && typeof p === 'string') {
                p = dateStringLookup[p]()
            }
            if (p instanceof Array) {
                p = p.map((pi) => EqualityComparator[type](pi))
            } else {
                p = EqualityComparator[type](p)
            }
            newParams.push(p)
        })
        return func(...newParams)
    }

export default {
    boolean: {
        '=': transformForEquality('boolean', (a, b) => a === b),
    },
    text: {
        '=': transformForEquality('text', (a, b) => a === b),
        contains: transformForEquality('text', (a, b) => a.includes(b)),
        starts: transformForEquality('text', (a, b) => a.startsWith(b)),
        ends: transformForEquality('text', (a, b) => a.endsWith(b)),
        in: transformForEquality('text', (a, b) =>
            new Set(b.map((bi) => bi)).has(a)
        ),
    },
    number: {
        '=': transformForEquality('number', (a, b) => a === b),
        '>': transformForEquality('number', (a, b) => a > b),
        '>=': transformForEquality('number', (a, b) => a >= b),
        '<': transformForEquality('number', (a, b) => a < b),
        '<=': transformForEquality('number', (a, b) => a <= b),
        between: transformForEquality(
            'number',
            (a, b) => a >= b[0] && a <= b[1]
        ),
        in: transformForEquality('number', (a, b) => new Set(b).has(a)),
    },
    currency: {
        '=': transformForEquality('currency', (a, b) => a === b),
        '>': transformForEquality('currency', (a, b) => a > b),
        '>=': transformForEquality('currency', (a, b) => a >= b),
        '<': transformForEquality('currency', (a, b) => a < b),
        '<=': transformForEquality('currency', (a, b) => a <= b),
        between: transformForEquality(
            'currency',
            (a, b) => a >= b[0] && a <= b[1]
        ),
        in: transformForEquality('currency', (a, b) => new Set(b).has(a)),
    },
    percent: {
        '=': transformForEquality('percent', (a, b) => a === b),
        '>': transformForEquality('percent', (a, b) => a > b),
        '>=': transformForEquality('percent', (a, b) => a >= b),
        '<': transformForEquality('percent', (a, b) => a < b),
        '<=': transformForEquality('percent', (a, b) => a <= b),
        between: transformForEquality(
            'percent',
            (a, b) => a >= b[0] && a <= b[1]
        ),
        in: transformForEquality('percent', (a, b) => new Set(b).has(a)),
    },
    durationMinutes: {
        '=': transformForEquality('number', (a, b) => a === b),
        '>': transformForEquality('number', (a, b) => a > b),
        '>=': transformForEquality('number', (a, b) => a >= b),
        '<': transformForEquality('number', (a, b) => a < b),
        '<=': transformForEquality('number', (a, b) => a <= b),
        between: transformForEquality(
            'number',
            (a, b) => a >= b[0] && a <= b[1]
        ),
        in: transformForEquality('number', (a, b) => new Set(b).has(a)),
    },
    date: {
        '=': transformForEquality('date', (a, b) => {
            if (b instanceof Array) {
                b = b[0]
            }
            return a === b
        }),
        '>': transformForEquality('date', (a, b) => {
            if (b instanceof Array) {
                b = b[1]
            }
            return a > b
        }),
        '>=': transformForEquality('date', (a, b) => {
            if (b instanceof Array) {
                b = b[1]
            }
            return a >= b
        }),
        '<': transformForEquality('date', (a, b) => {
            if (b instanceof Array) {
                b = b[0]
            }
            return a < b
        }),
        '<=': transformForEquality('date', (a, b) => {
            if (b instanceof Array) {
                b = b[0]
            }
            return a <= b
        }),
        between: transformForEquality('date', (a, b) => {
            return a >= b[0] && a <= b[1]
        }),
        in: transformForEquality('date', (a, b) => new Set(b).has(a)),
    },
    duration: {
        '=': transformForEquality('duration', (a, b) => a === b),
        '>': transformForEquality('duration', (a, b) => a > b),
        '>=': transformForEquality('duration', (a, b) => a >= b),
        '<': transformForEquality('duration', (a, b) => a < b),
        '<=': transformForEquality('duration', (a, b) => a <= b),
        between: transformForEquality(
            'duration',
            (a, b) => a >= b[0] && a <= b[1]
        ),
        in: transformForEquality('duration', (a, b) => new Set(b).has(a)),
    },
    progress: {
        '=': transformForEquality('progress', (a, b) => a === b),
        '>': transformForEquality('progress', (a, b) => a > b),
        '>=': transformForEquality('progress', (a, b) => a >= b),
        '<': transformForEquality('progress', (a, b) => a < b),
        '<=': transformForEquality('progress', (a, b) => a <= b),
        between: transformForEquality(
            'progress',
            (a, b) => a >= b[0] && a <= b[1]
        ),
        in: transformForEquality('progress', (a, b) => new Set(b).has(a)),
    },
    project: {
        in: transformForEquality('project', (a, b) => new Set(b).has(a)),
    },
    phase: {
        in: transformForEquality('phase', (a, b) => new Set(b).has(a)),
    },
    costCentre: {
        in: transformForEquality('costCentre', (a, b) => new Set(b).has(a)),
    },
    contact: {
        in: transformForEquality('contact', (a, b) => new Set(b).has(a)),
    },
    staff: {
        in: transformForEquality('staff', (a, b) => new Set(b).has(a)),
    },
    staffMember: {
        in: transformForEquality('staffMember', (a, b) => new Set(b).has(a)),
    },
    role: {
        in: transformForEquality('role', (a, b) => new Set(b).has(a)),
    },
    status: {
        in: transformForEquality('status', (a, b) => new Set(b).has(a)),
    },
}

export const dateStringLookup = {
    allTime: () => [-Infinity, Infinity],
    today: () => [startOfDay(new Date()), endOfDay(new Date())],
    thisWeek: () => [startOfWeek(new Date()), endOfWeek(new Date())],
    thisFortnight: () =>
        getWeek(new Date()) % 2
            ? [
                  startOfWeek(sub(new Date(), { weeks: 1 })),
                  endOfWeek(new Date()),
              ]
            : [
                  startOfWeek(new Date()),
                  endOfWeek(add(new Date(), { weeks: 1 })),
              ],
    thisMonth: () => [startOfMonth(new Date()), endOfMonth(new Date())],
    thisQuarter: () => [startOfQuarter(new Date()), endOfQuarter(new Date())],
    thisYear: () => [startOfYear(new Date()), endOfYear(new Date())],
    yesterday: () => [
        startOfDay(sub(new Date(), { days: 1 })),
        endOfDay(sub(new Date(), { days: 1 })),
    ],
    lastWeek: () => [
        startOfWeek(sub(new Date(), { weeks: 1 })),
        endOfWeek(sub(new Date(), { weeks: 1 })),
    ],
    lastFortnight: () =>
        getWeek(sub(new Date(), { weeks: 2 })) % 2
            ? [
                  startOfWeek(sub(new Date(), { weeks: 3 })),
                  endOfWeek(sub(new Date(), { weeks: 2 })),
              ]
            : [
                  startOfWeek(sub(new Date(), { weeks: 2 })),
                  endOfWeek(sub(new Date(), { weeks: 1 })),
              ],
    lastMonth: () => [
        startOfMonth(sub(new Date(), { months: 1 })),
        endOfMonth(sub(new Date(), { months: 1 })),
    ],
    lastQuarter: () => [
        startOfQuarter(sub(new Date(), { months: 3 })),
        endOfQuarter(sub(new Date(), { months: 3 })),
    ],
    lastYear: () => [
        startOfYear(sub(new Date(), { years: 1 })),
        endOfYear(sub(new Date(), { years: 1 })),
    ],
    tomorrow: () => [
        startOfDay(add(new Date(), { days: 1 })),
        endOfDay(add(new Date(), { days: 1 })),
    ],
    nextWeek: () => [
        startOfWeek(add(new Date(), { weeks: 1 })),
        endOfWeek(add(new Date(), { weeks: 1 })),
    ],
    nextFortnight: () =>
        getWeek(add(new Date(), { weeks: 2 })) % 2
            ? [
                  startOfWeek(add(new Date(), { weeks: 1 })),
                  endOfWeek(add(new Date(), { weeks: 2 })),
              ]
            : [
                  startOfWeek(add(new Date(), { weeks: 2 })),
                  endOfWeek(add(new Date(), { weeks: 3 })),
              ],
    nextMonth: () => [
        startOfMonth(add(new Date(), { months: 1 })),
        endOfMonth(add(new Date(), { months: 1 })),
    ],
    nextQuarter: () => [
        startOfQuarter(add(new Date(), { months: 3 })),
        endOfQuarter(add(new Date(), { months: 3 })),
    ],
    nextYear: () => [
        startOfYear(add(new Date(), { years: 1 })),
        endOfYear(add(new Date(), { years: 1 })),
    ],
    inLastWeek: () => [sub(new Date(), { weeks: 1 }), new Date()],
    inLastMonth: () => [sub(new Date(), { months: 1 }), new Date()],
    inLastQuarter: () => [sub(new Date(), { months: 3 }), new Date()],
    inLastYear: () => [sub(new Date(), { years: 1 }), new Date()],
    inNextWeek: () => [new Date(), add(new Date(), { weeks: 1 })],
    inNextMonth: () => [new Date(), add(new Date(), { months: 1 })],
    inNextQuarter: () => [new Date(), add(new Date(), { months: 3 })],
    inNextYear: () => [new Date(), add(new Date(), { years: 1 })],
}

export const dateStringLabels = {
    custom: 'custom',
    allTime: 'all time',
    today: 'today',
    thisWeek: 'this week',
    thisFortnight: 'this fortnight',
    thisMonth: 'this month',
    thisQuarter: 'this quarter',
    thisYear: 'this year',
    yesterday: 'yesterday',
    lastWeek: 'last week',
    lastFortnight: 'last fortnight',
    lastMonth: 'last month',
    lastQuarter: 'last quarter',
    lastYear: 'last year',
    tomorrow: 'tomorrow',
    nextWeek: 'next week',
    nextMonth: 'next month',
    nextQuarter: 'next quarter',
    nextYear: 'next year',
    inLastWeek: 'the week prior to now',
    inLastMonth: 'the month prior to now',
    inLastQuarter: 'the quarter prior to now',
    inLastYear: 'the year prior to now',
    inNextWeek: 'one week from now',
    inNextMonth: 'one month from now',
    inNextQuarter: 'one quarter from now',
    inNextYear: 'one year from now',
}

export const operatorLabels = {
    boolean: {
        '=': 'is equal to',
    },
    text: {
        '=': 'is equal to',
        '!=': "isn't equal to",
        contains: 'contains',
        '!contains': "doesn't contain",
        starts: 'starts with',
        '!starts': "doesn't start with",
        ends: 'ends with',
        '!ends': "doesn't end with",
        // in: "is any of",
    },
    number: {
        '=': 'is equal to',
        '!=': "isn't equal to",
        '>': 'is greater than',
        // '>=': 'is greater than or equal to',
        '<': 'is less than',
        // '<=': 'is less than or equal to',
        between: 'is between',
        '!between': "isn't between",
        // in: "is any of",
    },
    currency: {
        '=': 'is equal to',
        '!=': "isn't equal to",
        '>': 'is greater than',
        '<': 'is less than',
        between: 'is between',
        '!between': "isn't between",
    },
    percent: {
        '=': 'is equal to',
        '!=': "isn't equal to",
        '>': 'is greater than',
        '<': 'is less than',
        between: 'is between',
    },
    date: {
        '=': 'occurs on',
        '!=': "didn't occur on",
        '>': 'occurs after',
        '<': 'occurs before',
        between: 'occurred during',
        '!between': "didn't occur during",
        // in: 'is any of',
    },
    duration: {
        '=': 'is equal to',
        '!=': "isn't equal to",
        '>': 'is greater than',
        '<': 'is less than',
        between: 'is between',
        '!between': "isn't between",
    },
    progress: {
        '=': 'is equal to',
        '!=': "isn't equal to",
        '>': 'is greater than',
        '<': 'is less than',
        between: 'is between',
        '!between': "isn't between",
    },
    progressBar: {
        '=': 'is equal to',
        '!=': "isn't equal to",
        '>': 'is greater than',
        '<': 'is less than',
        between: 'is between',
        '!between': "isn't between",
    },
    project: {
        in: 'is any of',
        '!in': "isn't any of",
    },
    phase: {
        in: 'is any of',
        '!in': "isn't any of",
    },
    costCentre: {
        in: 'is any of',
        '!in': "isn't any of",
    },
    contact: {
        in: 'is any of',
        '!in': "isn't any of",
    },
    staff: {
        in: 'is any of',
        '!in': "isn't any of",
    },
    staffMember: {
        in: 'is any of',
        '!in': "isn't any of",
    },
    staffs: {
        in: 'is any of',
        '!in': "isn't any of",
    },
    staffMembers: {
        in: 'is any of',
        '!in': "isn't any of",
    },
    role: {
        in: 'is any of',
        '!in': "isn't any of",
    },
    status: {
        in: 'is any of',
        '!in': "isn't any of",
    },
}

export const operatorDefaults = {
    boolean: '=',
    text: 'contains',
    number: 'between',
    currency: 'between',
    percent: 'between',
    date: 'between',
    duration: 'between',
    progress: 'between',
    progressBar: 'between',
    project: 'in',
    phase: 'in',
    costCentre: 'in',
    contact: 'in',
    staff: 'in',
    staffMember: 'in',
    staffs: 'in',
    staffMembers: 'in',
    role: 'in',
    status: 'in',
}

export const singleValueDefaults = {
    boolean: true,
    text: '',
    number: 0,
    currency: 0,
    percent: 0,
    date: new Date(),
    duration: 0,
    progress: 0,
    progressBar: 0,
    project: [],
    phase: [],
    costCentre: [],
    contact: [],
    staff: [],
    staffMember: [],
    staffs: [],
    staffMembers: [],
    role: [],
    status: [],
}

export const rangeValueDefaults = {
    number: [0, 0],
    currency: [0, 0],
    percent: [0, 0],
    date: 'thisMonth',
    duration: [0, 0],
    progress: [0, 0],
    progressBar: [0, 0],
}
