import React from 'react'
import { addDays, addWeeks, endOfWeek, startOfDay, startOfWeek } from 'date-fns'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import _ from 'lodash'
import SelectCostCentrePage from './SelectCostCentrePage'
import SelectProjectPage from './SelectProjectPage'
import SelectPhasePage from './SelectPhasePage'
import SelectTaskPage from './SelectTaskPage'
import TimeEntryPage from './TimeEntryPage'
import SessionStore from '../../State/SessionStore'

const { makeObservable, observable, computed, action } = require('mobx')

class TimesheetAppStore {
    @observable selectedDate = startOfDay(new Date())
    @observable route = 'listEntries'
    @observable dropdownState = 'select'
    @observable selectedCostCentre = null
    @observable selectedProject = null
    @observable selectedPhase = null
    @observable selectedTask = null
    @observable selectedTimeEntry = null
    constructor(props) {
        makeObservable(this)
    }

    @computed
    get displayedPage() {
        const routes = {
            selectCostCentre: SelectCostCentrePage,
            selectProject: SelectProjectPage,
            selectPhase: SelectPhasePage,
            selectTask: SelectTaskPage,
            listEntries: TimeEntryPage,
        }
        return routes[this.route] || TimeEntryPage
    }

    @computed
    get startOfWeek() {
        return startOfWeek(this.selectedDate, { weekStartsOn: 1 })
    }

    @computed
    get endOfWeek() {
        return endOfWeek(this.selectedDate, { weekStartsOn: 1 })
    }

    @action.bound
    shiftWeek(shift) {
        this.selectedDate = addWeeks(this.selectedDate, shift)
    }

    @action.bound
    selectDate(date) {
        this.selectedDate = startOfDay(date)
    }

    @computed
    get daysOfWeek() {
        return [0, 1, 2, 3, 4, 5, 6].map((i) => {
            return addDays(this.startOfWeek, i)
        })
    }

    @computed
    get dailyTotals() {
        return this.daysOfWeek.map((date) => {
            return _.sum(
                TimeEntryCollection.timeEntriesByDate[date.getTime()].map(
                    (te) => te.hours
                )
            )
        })
    }

    @action.bound
    resetSelection() {
        this.selectedCostCentre = null
        this.selectedProject = null
        this.selectedPhase = null
        this.selectedTask = null
    }

    @action.bound
    setRoute(route) {
        this.route = route
    }

    @action.bound
    selectCostCentre(costCentre) {
        this.selectedCostCentre = costCentre
    }

    @action.bound
    selectProject(project) {
        this.selectedProject = project
    }

    @action.bound
    selectPhase(phase) {
        this.selectedPhase = phase
    }

    @action.bound
    selectTask(task) {
        this.selectedTask = task
    }

    @action.bound
    selectTimeEntry(timeEntry) {
        this.selectedTimeEntry = timeEntry
    }

    @action.bound
    createTimeEntry() {
        TimeEntryCollection.add(
            {
                staffId: SessionStore.userId,
                costCentreId: this.selectedCostCentre.id,
                projectId: this.selectedProject.id,
                phaseId: this.selectedPhase.id,
                taskId: this.selectedTask.id,
                date: this.selectedDate,
                numMinutes: 0,
            },
            { trackUpdates: true }
        )
    }

    @action.bound
    setDropdownState(state) {
        this.dropdownState = state
    }

    @action.bound
    deleteTimeEntry(timeEntry) {
        timeEntry.update({ deletedAt: new Date() })
    }
}

export default new TimesheetAppStore()
