import { observable, computed, action, makeObservable } from 'mobx'
import cuid from 'cuid'
import _ from 'lodash'
import { camelCase, capitalCase } from 'change-case'
import superjson from 'superjson'
import { format } from 'date-fns'
import pluralize from 'pluralize'
import Model from '../Models/Model'

const globalModelLookup = {}

class Collection {
    @observable modelsById = {}
    @observable models = []
    collection = null
    modelClass = null
    getModelId = (modelData) => modelData?.id
    modelCreator = (m, options) =>
        m instanceof Model
            ? m
            : new this.modelClass(m, { ...options, source: 'collection' })
    modelUpdater = (model, newData, options = {}) =>
        model.update(newData, {
            ...options,
            source: 'collection',
        })
    lookups = new Set()
    cacheTimestamp = null
    @observable saveState = null
    constructor({
        collection,
        modelClass,
        getModelId,
        modelCreator,
        modelUpdater,
    }) {
        makeObservable(this)
        globalModelLookup[collection] ??= {}
        this.collection = collection
        this.modelClass = modelClass
        this.getModelId = getModelId ?? this.getModelId
        this.modelCreator = modelCreator ?? this.modelCreator
        this.modelUpdater = modelUpdater ?? this.modelUpdater
        this.addLookup('modelsToSave', 'list', {
            filter: (m) => {
                return m?.needsSaving
            },
        })
    }
    @computed
    get modelType() {
        return pluralize(this.collection, 1)
    }
    @computed
    get needsSaving() {
        return this.modelsToSave?.length > 0
    }
    @action.bound
    addMany(modelDataArray, options) {
        return modelDataArray.map((md) => this.add(md, options))
    }
    @action.bound
    add(modelData, options = {}) {
        const modelId = this.getModelId(modelData)
        if (!modelId || !this.modelsById[modelId]) {
            return this.create(modelId, modelData, options)
        } else {
            return this.update(modelId, modelData, options)
        }
    }
    @action.bound
    create(modelId, modelData, options) {
        modelData.id ??= cuid()
        modelId ??= modelData.id
        let model
        if (globalModelLookup[this.collection][modelId]) {
            model = globalModelLookup[this.collection][modelId]
            this.modelUpdater(model, modelData, options)
        } else {
            model = this.modelCreator(modelData, options)
            globalModelLookup[this.collection][modelId] = model
        }
        this.modelsById[modelId] = model
        this.models.push(model)
        // this.modelsById = { ...this.modelsById }
        // this.models = [...this.models]
        this.onCreate(modelId)
        return this.modelsById[modelId]
    }
    // update everything here
    @action.bound
    update(modelId, modelData, options = {}) {
        this.modelUpdater(this.modelsById[modelId], modelData, options)
        // this.modelsById = { ...this.modelsById }
        // this.models = [...this.models]
        this.onUpdate(modelId)
        return this.modelsById[modelId]
    }
    @action.bound
    delete(modelId) {
        const model = this.modelsById[modelId]
        if (model) {
            this.onDelete(modelId)
            delete this.modelsById[modelId]
            this.models = this.models.filter((m) => m.id !== modelId)
            // this.modelsById = { ...this.modelsById }
            // this.models = [...this.models]
        }
        return model
    }
    @action.bound
    onCreate(modelId) {
        const model = this.modelsById[modelId]
        ;[...this.lookups].forEach((propName) => {
            this[propName].add(model)
        })
    }
    @action.bound
    onUpdate(modelId) {
        const model = this.modelsById[modelId]
        ;[...this.lookups].forEach((propName) => {
            this[propName].update(model)
        })
    }
    @action.bound
    onDelete(modelId) {
        const model = this.modelsById[modelId]
        ;[...this.lookups].forEach((propName) => {
            this[propName].delete(model)
        })
    }
    @action.bound
    addLookup(propName, type, { map, filter, key, keys }) {
        const lookupTypes = {
            manyByMany: ManyModelsByManyKeys,
            manyByKey: ManyModelsByKey,
            uniqueByKey: UniqueModelsByKey,
            list: ModelList,
        }
        const modelsByKey = new lookupTypes[type]({ filter, map, key, keys })
        Object.defineProperty(this, `_${propName}`, {
            value: modelsByKey,
            writable: false,
        })
        Object.defineProperty(this, propName, {
            get() {
                return this[`_${propName}`].lookup
            },
        })
        this.lookups.add(`_${propName}`)
    }
}

class ManyModelsByManyKeys {
    filter = (m) => true
    map = (m) => m
    keys = (m) => [m?.id]
    getId = (m) => m?.id
    @observable cachedData = {}
    @observable modelsByKey = new Map()
    constructor({ filter, map, keys, getId }) {
        makeObservable(this)
        this.filter = filter ?? this.filter
        this.map = map ?? this.map
        this.keys = keys ?? this.keys
        this.getId = getId ?? this.getId
    }
    add(model) {
        const modelId = this.getId(model)
        if (this.cachedData[modelId]) {
            this.update(model)
        } else if (this.filter(model)) {
            const keys = this.keys(model)
            const mappedModel = this.map(model)
            this.cachedData[modelId] = {
                modelKeys: keys,
                modelMap: mappedModel,
            }
            keys.forEach((key) => {
                this.modelsByKey.set(
                    key,
                    this.modelsByKey.get(key) ?? new Set()
                )
                this.modelsByKey.get(key).add(mappedModel)
            })
        }
    }
    update(model) {
        this.delete(model)
        this.add(model)
        // NOTE - this really kills performance, don't do it
        // this.cachedData = { ...this.cachedData }
        // this.modelsByKey = new Map(this.modelsByKey)
    }
    updateMany(models) {
        models.forEach((model) => {
            this.delete(model)
            this.add(model)
        })
        // NOTE - this really kills performance, don't do it
        // this.cachedData = { ...this.cachedData }
        // this.modelsByKey = new Map(this.modelsByKey)
    }
    delete(model) {
        const modelId = this.getId(model)
        const cachedData = this.cachedData[modelId]
        if (cachedData) {
            if (cachedData?.modelKeys) {
                cachedData.modelKeys.forEach((key) => {
                    if (this.modelsByKey.has(key)) {
                        this.modelsByKey.get(key).delete(cachedData.modelMap)
                    }
                })
            }
            delete this.cachedData[modelId]
        }
    }
    @computed
    get lookup() {
        return Object.fromEntries(
            [...this.modelsByKey].map(([k, v]) => [k, [...v]])
        )
    }
    get(key) {
        if (this.has(key)) {
            return [...this.modelsByKey.get(key)]
        } else {
            return []
        }
    }
    has(key) {
        return this.modelsByKey.has(key)
    }
}

class ManyModelsByKey {
    filter = (m) => true
    map = (m) => m
    key = (m) => m?.id
    getId = (m) => m?.id
    @observable cachedData = {}
    @observable modelsByKey = new Map()
    constructor({ filter, map, key, getId }) {
        makeObservable(this)
        this.filter = filter ?? this.filter
        this.map = map ?? this.map
        this.key = key ?? this.key
        this.getId = getId ?? this.getId
    }
    add(model) {
        const modelId = this.getId(model)
        if (this.cachedData[modelId]) {
            this.update(model)
        } else if (this.filter(model)) {
            const key = this.key(model)
            const mappedModel = this.map(model)
            this.cachedData[modelId] = { modelKey: key, modelMap: mappedModel }
            this.modelsByKey.set(key, this.modelsByKey.get(key) ?? new Set())
            this.modelsByKey.get(key).add(mappedModel)
        }
    }
    update(model) {
        this.delete(model)
        this.add(model)
        // NOTE - this really kills performance, don't do it
        // this.cachedData = { ...this.cachedData }
        // this.modelsByKey = new Map(this.modelsByKey)
    }
    updateMany(models) {
        models.forEach((model) => {
            this.delete(model)
            this.add(model)
        })
        // NOTE - this really kills performance, don't do it
        // this.cachedData = { ...this.cachedData }
        // this.modelsByKey = new Map(this.modelsByKey)
    }
    delete(model) {
        const modelId = this.getId(model)
        const cachedData = this.cachedData[modelId]
        if (cachedData) {
            if (this.modelsByKey.has(cachedData.modelKey)) {
                this.modelsByKey
                    .get(cachedData.modelKey)
                    .delete(cachedData.modelMap)
            }
            delete this.cachedData[modelId]
        }
    }
    @computed
    get lookup() {
        return Object.fromEntries(
            [...this.modelsByKey].map(([k, v]) => [k, [...v]])
        )
    }
    get(key) {
        if (this.has(key)) {
            return [...this.modelsByKey.get(key)]
        } else {
            return []
        }
    }
    has(key) {
        return this.modelsByKey.has(key)
    }
}

class UniqueModelsByKey {
    filter = (m) => true
    map = (m) => m
    key = (m) => m?.id
    getId = (m) => m?.id
    @observable cachedData = {}
    @observable modelsByKey = new Map()
    constructor({ filter, map, key, getId }) {
        makeObservable(this)
        this.filter = filter ?? this.filter
        this.map = map ?? this.map
        this.key = key ?? this.key
        this.getId = getId ?? this.getId
    }
    add(model) {
        const modelId = this.getId(model)
        if (this.cachedData[modelId]) {
            this.update(model)
        } else if (this.filter(model)) {
            const key = this.key(model)
            const mappedModel = this.map(model)
            this.cachedData[modelId] = { modelKey: key }
            this.modelsByKey.set(key, mappedModel)
        }
    }
    update(model) {
        this.delete(model)
        this.add(model)
        // NOTE - this really kills performance, don't do it
        // this.cachedData = { ...this.cachedData }
        // this.modelsByKey = new Map(this.modelsByKey)
    }
    updateMany(models) {
        models.forEach((model) => {
            this.delete(model)
            this.add(model)
        })
        // NOTE - this really kills performance, don't do it
        // this.cachedData = { ...this.cachedData }
        // this.modelsByKey = new Map(this.modelsByKey)
    }
    delete(model) {
        const modelId = this.getId(model)
        const cachedData = this.cachedData[modelId]
        if (cachedData) {
            if (this.modelsByKey.has(cachedData.modelKey)) {
                this.modelsByKey.delete(cachedData.modelKey)
            }
            delete this.cachedData[modelId]
        }
    }
    @computed
    get lookup() {
        return Object.fromEntries(this.modelsByKey)
    }
    get(key) {
        if (this.has(key)) {
            return this.modelsByKey.get(key)
        } else {
            return undefined
        }
    }
    has(key) {
        return this.modelsByKey.has(key)
    }
}

class ModelList {
    filter = (m) => true
    map = (m) => m
    getId = (m) => m?.id
    @observable models = new Map()
    constructor({ filter, map, key, getId }) {
        makeObservable(this)
        this.filter = filter ?? this.filter
        this.map = map ?? this.map
        this.getId = getId ?? this.getId
    }
    add(model) {
        const modelId = this.getId(model)
        if (this.models.get(modelId)) {
            this.update(model)
        } else if (this.filter(model)) {
            const mappedModel = this.map(model)
            this.models.set(modelId, mappedModel)
        }
    }
    update(model) {
        this.delete(model)
        this.add(model)
        // NOTE - this really kills performance, don't do it
        // this.models = new Map(this.models)
    }
    updateMany(models) {
        models.forEach((model) => {
            this.delete(model)
            this.add(model)
        })
        // NOTE - this really kills performance, don't do it
        // this.models = new Map(this.models)
    }
    delete(model) {
        const modelId = this.getId(model)
        if (this.models.has(modelId)) {
            this.models.delete(modelId)
        }
    }
    @computed
    get lookup() {
        return [...this.models.values()]
    }
    has(model) {
        const modelId = this.getId(model)
        return this.models.has(modelId)
    }
    get(model) {
        const modelId = this.getId(model)
        return this.models.get(modelId)
    }
}

export default Collection
