import { observable, computed, action, makeObservable } from 'mobx'
import { differenceInMinutes, format, startOfDay, subSeconds } from 'date-fns'
import localforage from 'localforage'
import superjson from 'superjson'
import SessionStore from '../../State/SessionStore'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import DataStore from '../../State/DataStore'
import tuple from 'immutable-tuple'

class TimerStore {
    @observable timerState = 'idle'
    @observable saveState = 'idle'
    @observable selectedTimeEntry = null
    @observable prevSeconds = 0
    @observable startTime = null
    @observable currentTime = null
    timerInterval = null
    constructor() {
        makeObservable(this)
    }
    @action.bound
    tick() {
        this.currentTime = new Date()
    }
    @action.bound
    startTimer() {
        clearInterval(this.timerInterval)
        this.startTime = new Date()
        this.timerInterval = setInterval(this.tick, 1000)
        this.timerState = 'running'
    }
    @action.bound
    pauseTimer() {
        this.prevSeconds = this.seconds
        // this.startTime = null;
        this.currentTime = null
        clearInterval(this.timerInterval)
        this.timerState = 'idle'
    }
    @action.bound
    resetTimer() {
        this.pauseTimer()
        this.prevSeconds = 0
    }
    @computed
    get currentSeconds() {
        return this.startTime && this.currentTime
            ? (this.currentTime - this.startTime) / 1000
            : 0
    }
    @computed
    get seconds() {
        return this.prevSeconds + this.currentSeconds
    }
    @computed
    get timeDisplay() {
        return new Date(this.seconds * 1000).toISOString().substr(12, 7)
    }
    @action.bound
    setSelectedTimeEntry(timeEntry) {
        this.selectedTimeEntry = timeEntry
        this.prevSeconds = (timeEntry?.numMinutes || 0) * 60
    }
}

export default new TimerStore()
